import React, { useState, useEffect } from "react";
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { DotsVerticalIcon } from '@heroicons/react/outline'
import { CheckCircleIcon } from '@heroicons/react/solid'
import Cookies from "js-cookie";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { getBooking, getBookingOwner } from "../../auth/helper/bookingAPI";
import Pagination from "../../components/Pagination";
import Sidebar from "../../components/Sidebar/Sidebar";

const BookingHistory = () => {

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

  const [tab, setTab] = useState(false);
  const navigate = useNavigate();
  const [Data, setData] = useState([]);
  const [items, setItems] = useState(Data); 
  const [totalResults, setTotalResults] = useState(0)
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    if(!Cookies.get('access-token')) {
        navigate('/login');
    }
  }, []);

  async function Booking() {
    const { data } = await getBooking(currentPage);
    setData(data.results);
    setTotalResults(data.count);
  }
  const onPageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    Booking();
  }, [currentPage]);

  async function BookingOwner() {
    const { data } = await getBookingOwner(currentPage);
    setData(data.results);
    setTotalResults(data.count);

  }

  return (
    <div>
<div className="flex bg-gray-100 dark:bg-gray-900">
<Sidebar/>
<div className="flex-grow bg-gray-100 dark:bg-gray-900">

      <div className="p-3 pt-0">
        <div className="flex mb-7">
          <button
            className={`w-1/2 py-4 dark:text-white ${
              !tab && "text-[#68AC5D] dark:text-[#68AC5D] border-b-[#68AC5D] border-b-2"
            } hover:bg-gray-200 text-xl font-bold`}
            onClick={() => {
              setTab(false);
              Booking();
            }}
          >
            Customer
          </button>
          <button
            className={`w-1/2 py-4 dark:text-white ${
              tab && "text-[#68AC5D] dark:text-[#68AC5D] border-b-[#68AC5D] border-b-2"
            } hover:bg-gray-200 text-xl font-bold`}
            onClick={() => {
              setTab(true);
              BookingOwner();
            }}
          >
            Owner
          </button>
        </div>
      <div className="py-5 sm:py-5">
        <div className="max-w-7xl mx-auto sm:px-2 lg:px-8">
          <div className="max-w-2xl mx-auto px-4 lg:max-w-4xl lg:px-0">
            <h1 className="text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-3xl">Booking History</h1>
            <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
              Check the status of recent bookings, manage status.
            </p>
          </div>
        </div>
        {Data !== [] ? 
        <div className="mt-16">
          <h2 className="sr-only">Recent bookings</h2>
          <div className="max-w-7xl mx-auto sm:px-2 lg:px-8">
            <div className="max-w-2xl mx-auto space-y-8 sm:px-4 lg:max-w-4xl lg:px-0">
              {(Data?.length!==0)?
              Data?.map((item, i) => (
                <div
                  key={item?.booking_id}
                  className="bg-white dark:bg-gray-700 border-t border-b border-gray-200 dark:border-gray-400 shadow-sm sm:rounded-lg sm:border"
                >
                  <h3 className="sr-only">
                    Booking placed on <time dateTime={format(new Date(item?.created_at), "yyyy-MM-dd")}>{format(new Date(item?.created_at), "yyyy-MM-dd")}</time>
                  </h3>

                  <div className="flex items-center p-4 border-b border-gray-200 sm:p-6 sm:grid sm:grid-cols-4 sm:gap-x-6">
                    <dl className="flex-1 grid grid-cols-2 gap-x-6 text-sm sm:col-span-3 sm:grid-cols-3 lg:col-span-2">
                      <div>
                        <dt className="font-medium text-gray-900 dark:text-white ">Booking Id</dt>
                        <dd className="mt-1 text-gray-500 dark:text-gray-300">{item?.booking_id}</dd>
                      </div>
                      <div className="hidden sm:block">
                        <dt className="font-medium text-gray-900 dark:text-white">Date placed</dt>
                        <dd className="mt-1 text-gray-500 dark:text-gray-300">
                          <time dateTime={format(new Date(item?.created_at), "yyyy-MM-dd")}>{format(new Date(item?.created_at), "yyyy-MM-dd")}</time>
                        </dd>
                      </div>
                      {(item?.number_of_days===1)?
                      <div>
                      <dt className="font-medium text-gray-900 dark:text-white">Total amount</dt>
                      <dd className="mt-1 font-medium text-gray-900  dark:text-gray-300">Rs {item?.total_hourly_rent?.toString().replace("-", "")}</dd>
                    </div>
                        :
                        <div>
                        <dt className="font-medium text-gray-900 dark:text-white">Total amount</dt>
                        <dd className="mt-1 font-medium text-gray-900  dark:text-gray-300">Rs {item?.total_daily_rent}</dd>
                      </div>
                            }

                    </dl>

                    <Menu as="div" className="relative flex justify-end lg:hidden">
                      <div className="flex items-center">
                        <Menu.Button className="-m-2 p-2 flex items-center text-gray-400 hover:text-gray-500 dark:hover:text-gray-700">
                          <span className="sr-only">Options for bookings {items?.booking_id}</span>
                          <DotsVerticalIcon className="w-6 h-6" aria-hidden="true" />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-bottom-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                onClick={() =>
                                  navigate(`/bookingRequest/${item?.id}`)
                                }
                                  className={classNames(
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-500',
                                    'block px-4 py-2 text-sm'
                                  )}
                                >
                                  View
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href=""
                                  className={classNames(
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-500',
                                    'block px-4 py-2 text-sm'
                                  )}
                                >
                                  Invoice
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>

                    <div className="hidden lg:col-span-2 lg:flex lg:items-center lg:justify-end lg:space-x-4">
                      <a
                        href={`/bookingRequest/${item?.id}`}
                        className="flex items-center justify-center bg-white py-2 px-2.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        <span>View Order</span>
                        <span className="sr-only">{item?.booking_id}</span>
                      </a>
                      <a
                        href=""
                        className="flex items-center justify-center bg-white py-2 px-2.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        <span>View Invoice</span>
                        <span className="sr-only">for booking {item?.booking_id}</span>
                      </a>
                    </div>
                  </div>

                  {/* Products */}
                  <h4 className="sr-only">Items</h4>
                  <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
                      <li className="p-4 sm:p-6">
                        <div className="flex items-center sm:items-start">
                          <div className="flex-shrink-0 w-20 h-20 bg-gray-200 rounded-lg overflow-hidden sm:w-40 sm:h-40">
                            <img
                              src={item?.equipment?.image_1}
                              alt={item?.equipment?.title}
                              className="w-full h-full object-center object-cover"
                            />
                          </div>
                          <div className="flex-1 ml-6 text-sm">
                            <div className="font-medium text-gray-900 dark:text-gray-300 sm:flex sm:justify-between">
                              <h5>{item?.equipment?.title}</h5>
                              <p className="mt-2 sm:mt-0">Rs. {item?.equipment?.daily_rental} /Day</p>
                            </div>
                            <p className="hidden text-gray-500 dark:text-gray-300 sm:block sm:mt-2">Manufacturer:- {item?.equipment?.manufacturer?.name}</p>
                            <p className="hidden text-gray-500 dark:text-gray-300 sm:block sm:mt-2">Description:- {(item?.equipment?.description)?item?.equipment?.description:"Not Available"}</p>
                          </div>
                        </div>

                        <div className="mt-6 sm:flex sm:justify-between">
                        {(items?.status==="Completed")?
                          <div className="flex items-center">
                            <CheckCircleIcon className="w-5 h-5 text-green-500" aria-hidden="true" />
                            <p className="ml-2 text-sm font-medium text-gray-500 dark:text-gray-300">
                              Status:- Completed
                            </p>
                          </div>:
                          <div className="flex items-center">
                            <p className="ml-2 text-sm font-medium text-gray-500 dark:text-gray-300">
                            Status:- {item?.status}
                            </p>
                          </div>

}

                          <div className="mt-6 border-t border-gray-200 pt-4 flex items-center space-x-4 divide-x divide-gray-200 text-sm font-medium sm:mt-0 sm:ml-4 sm:border-none sm:pt-0">
                            <div className="flex-1 flex justify-center">
                              <a
                                href={`/equipment/${item?.equipment?.eq_id}/${item?.equipment?.title}`}
                                className="text-indigo-600 dark:text-indigo-300 whitespace-nowrap hover:text-indigo-500"
                              >
                                View product
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                  </ul>
                </div>
              )):
              <div className={`mt-4 flex p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800`} role="alert">
    <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
    <span className="sr-only">Alert</span>
    <div>
    Nothing To Show Here!!!!
    </div>
  </div>}
            </div>
          </div>
          <div className='flex justify-center my-10'>
                  <Pagination
         currentPage={currentPage}
         totalItems={totalResults}
         itemsPerPage={8}
         onPageChange={onPageChange}
       />
 </div>
        </div>: <div className="h-full">
      <h1 className="text-4xl font-bold text-[#4f4f4f] relative left-1/3 top-1/2">
        Nothing to show here... :(
      </h1>
    </div>}
      </div>


      </div>








   



        
          </div>
          </div>











    </div>
  );
};

export default BookingHistory;
