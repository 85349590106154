import React from 'react'
import { Link } from 'react-router-dom'

const CategoryCard = (props) => {
  return (
<div className="border border-gray-200 shadow relative w-80 h-80 bg-white shadow-md rounded-3xl p-2 mx-1 my-3 cursor-pointer dark:bg-gray-800 dark:border-gray-700">
      <div className="overflow-x-hidden rounded-2xl relative">
<Link to={`/category/${props.category.id}/${props.category.name}`} >
      <img className="h-40 rounded-2xl w-full object-cover" src={props.category.image} alt="Image Not Entered while creating"/>
    </Link>
      </div>
      <div className="mt-4 pl-2 mb-2 flex justify-between ">
        <div>
          <Link to={`/category/${props.category.id}/${props.category.name}`} >
          <p className="truncate h-7 w-72 text-lg font-semibold text-gray-900 mb-0 tracking-tight dark:text-white">{props.category.name}</p>
          <p className="truncate h-7 w-72 text-md font-semibold text-gray-800 tracking-tight dark:text-white mt-0">{props.category.description?.slice(0,100)}{props.category.description?.length > 100 ? "..." : ""}</p>
        </Link>
        </div>
      </div>
    </div>
  )
}

export default CategoryCard
