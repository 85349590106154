import { StarIcon } from '@heroicons/react/solid'
import React from 'react'
import { Link } from 'react-router-dom';
const EquipmentCard = (props) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  return (
<div className="border border-gray-200 shadow relative w-80 h-362px bg-white shadow-md rounded-3xl p-2 mx-1 my-3 cursor-pointer dark:bg-gray-800 dark:border-gray-700">
      <div className="overflow-x-hidden rounded-2xl relative">
<Link to={`/equipment/${props?.equipment?.eq_id}/${props?.equipment?.title}`} >
        <img className="h-40 rounded-2xl w-full object-cover" src={props?.equipment?.image_1} alt="Image Not Entered while creating"/>
        {/* <p className="absolute right-2 top-2 bg-white rounded-full p-2 cursor-pointer group">
          <Icon.Cart2 className="group-hover:opacity-50 opacity-70 bi d-block mx-auto mb-1" width="24" height="24" to="/"/>
        </p> */}
    </Link>
      </div>
      <div className="mt-4 pl-2 mb-2 flex justify-between ">
        <div>
          <Link to={`/equipment/${props?.equipment?.eq_id}/${props?.equipment?.title}`} >
          <p className="truncate h-7 w-72 text-lg font-semibold text-gray-900 mb-0 tracking-tight dark:text-white">{props?.equipment?.title}</p>
          <p className="truncate h-7 w-72 text-lg font-semibold text-gray-900 mb-0 tracking-tight dark:text-white">Manufacture: {props?.equipment?.manufacturer?.name}</p>
          </Link>
          <div class="flex items-center mt-2.5 mb-5">
          {[0, 1, 2, 3, 4].map((rating) => (
                  <StarIcon
                    key={rating}
                    className={classNames(
                      props?.equipment?.equipment_rating > rating ? 'text-yellow-400' : 'text-gray-300',
                      'flex-shrink-0 h-5 w-5'
                    )}
                    aria-hidden="true"
                  />
                ))}
            <span class="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">{props?.equipment?.equipment_rating.toFixed(1)}</span>
          </div>
          <p className="truncate w-72 text-md text-gray-800 mt-0 font-bold dark:text-white">Location: {props?.equipment?.equipment_location?.slice(0,100)}{props?.equipment?.equipment_location?.length > 100 ? "..." : ""} </p>
          <p className="text-md text-gray-800 mt-0 font-bold dark:text-white">Daily Rental: Rs. {props?.equipment?.daily_rental}</p>
          <p className="text-md text-gray-800 mt-0 font-bold dark:text-white">Hourly Rental: Rs.{props?.equipment?.hourly_rental}</p>
        </div>
        <div className="flex flex-col-reverse mb-1 mr-4 group cursor-pointer">
        </div>
      </div>
    </div>
  )
}

export default EquipmentCard
