
import React, { useState, useEffect } from 'react'
import Sidebar from '../components/Sidebar/Sidebar';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { profile, updateUser} from '../auth/helper/index';
import { City, State } from "country-state-city";
import { API } from '../backend';import {IoMdSwap} from 'react-icons/io'

const EditUserAdmin = () => {
  const params = useParams();
  const uuid =params.uuid;
  const [oldProfilePicture, setOldProfilePicture] = useState(null);
  const [oldPhoneNumber, setOldPhoneNumber] = useState(null);
  const [oldEmail, setOldEmail] = useState(null);
  const tokenState = useSelector((state) => state.tokenReducer);
  const [formData, setFormData] = useState({
    user_id:"",
    first_name: "",
    last_name: "",
    email:"",
    address: "",
    city: "", 
    state: "",
    pin_code: parseInt(""),
    profile_picture:null,
    password:"",
    phone_number:"",
    is_admin:"",
    is_staff:"",
    date_joined:"",
    updated_at:"",
    last_login:"",
  });
  const selectedCountry = { label: "India", value: "IN" };
  
  const access = Cookies.get("access-token");
  
    const handleStateChange = (selectedOption) => {
      setError({status:false})
      setFormData(
        {...formData,
          state: selectedOption.target.value,
        city:""});
    };

  const [error, setError]= useState({
    status:false,
    msg:"",
    type:""
  })

  const UserProfile = async () => {
    try {
    if (access) {
      const data  = await profile({uuid,access});
      setFormData({
        ...formData, 
        user_id:data?.data?.user_id,
        first_name: data?.data?.first_name,
      last_name: data?.data?.last_name,
      email:data?.data?.email,
      address: data?.data?.address,
      state: data?.data?.state,
      city: data?.data?.city,
      pin_code: parseInt(data?.data?.pin_code),
      phone_number:data?.data?.phone_number,
      is_admin:data?.data?.is_admin,
      is_staff:data?.data?.is_staff,
      date_joined:new Date(data?.data?.date_joined),
      updated_at:new Date(data?.data?.updated_at),
      last_login:new Date(data?.data?.last_login),
      });
      setOldProfilePicture(data?.data?.profile_picture);
      setOldPhoneNumber(data?.data?.phone_number);
      setOldEmail(data?.data?.email);
    }
      
    } catch (error) {
      // handle error here
    }
  }
  
    useEffect(() => {
      UserProfile();
    }, []);

const inputHandler = (event) => {
  setError({status:false})
  setFormData({
     ...formData,
     [event.target.name]:event.target.value
  })
};

const submitHandler =async (event) =>{
  event.preventDefault();
  const userData = new FormData();
  userData.append('first_name', formData.first_name);
  userData.append('last_name', formData.last_name);
  userData.append('address', formData.address);
  userData.append('state', formData.state);
  userData.append('city', formData.city);
  userData.append('pin_code', formData.pin_code);
  userData.append('is_admin', formData.is_admin);
  userData.append('is_staff', formData.is_staff);
  (formData?.profile_picture) && userData.append('profile_picture', formData?.profile_picture);
  if(formData?.email!==oldEmail){
    userData.append('email', formData.email);
  }
  if(formData?.password!==''){
    userData.append('password', formData.password);
  }
  if(formData?.phone_number!==oldPhoneNumber){
    userData.append('phone_number', formData.phone_number);
  }
  
    if(access){
      console.log(userData);
      console.log(formData);
    const data = await updateUser({
      formData: userData,
      accessToken: tokenState.token.accessToken,
      uuid
    });
    if (data.success) {
      setError({status:true, msg:"User Updated Successfully", type:"green"})
    
    }
    if (!data.success) {
      setError({status:true, msg:`${data.message}`, type:"red"})
   
    }
  };

};


  return (
    <div className="flex bg-gray-100 dark:bg-gray-900">
    <Sidebar/>
    <div className="flex-grow bg-gray-100 dark:bg-gray-900">
    
    
                      <form id="userEditForm" onSubmit={submitHandler}>
              <div className="shadow sm:rounded-md sm:overflow-hidden ">
                <div className="bg-white py-6 px-4 space-y-6 sm:p-6 dark:bg-gray-800">
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">Edit User</h3>
                  </div>
    
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="user-id" className="block text-sm font-medium text-gray-700 dark:text-white">
                        User Id
                      </label>
                      <input
                        name="user_id"
                        placeholder="User Id"
                        disabled={true}
                        value={formData?.user_id}
                        onChange={inputHandler}
                        type="text"
                        id="user-id"
                        className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 dark:text-white">
                        First name
                      </label>
                      <input
                        name="first_name"
                        placeholder="First Name*"
                        required={true}
                        value={formData?.first_name}
                        onChange={inputHandler}
                        type="text"
                        id="first-name"
                        className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
      
                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 dark:text-white">
                        Last name
                      </label>
                      <input
                        name="last_name"
                        placeholder="Last Name"
                        value={formData?.last_name}
                        onChange={inputHandler} 
                        type="text"
                        id="last-name"
                        autoComplete="family-name"
                        className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
         
                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="email-address" className="block text-sm font-medium text-gray-700 dark:text-white">
                        Email address
                      </label>
                      <input
                        type="email"
                        placeholder="Email*"
                            required={true} 
                            value={formData?.email}
                        name="email"
                        id="email-address"
                        onChange={inputHandler} 
                        autoComplete="email"
                        className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
      
                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="phone_number" className="block text-sm font-medium text-gray-700 dark:text-white">
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        placeholder="Phone Number*" 
                            required={true}
                            onInvalid={(e) => e.target.setCustomValidity("Please enter a valid 10 digit phone number")}
                        onChange={(e) => {
                            e.target.setCustomValidity("");
                            inputHandler(e);
                        }}
                        pattern="[6789][0-9]{9}"
                            value={formData?.phone_number}
                              name="phone_number"
                        id="phone_number"
                        autoComplete="phone-number"
                        className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
        
                    <div className="col-span-6">
                      <label htmlFor="street-address" className="block text-sm font-medium text-gray-700 dark:text-white">
                        Address
                      </label>
                      <input
                        type="text"
                        onChange={inputHandler}
                        placeholder="Address"
                        value={formData?.address}
                        name="address"
                        id="street-address"
                        autoComplete="street-address"
                        className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
    
                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label htmlFor="region" className="block text-sm font-medium text-gray-700 dark:text-white">
                        State
                      </label>
                      <select
                      required={true}
                      id="state"
                        onChange={handleStateChange}
                        name="state"
                        value={formData?.state}
                        className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:focus:outline-none dark:focus:ring-indigo-500 dark:focus:border-indigo-500 sm:text-sm"
                      >
                        <option value="" selected>Select State</option>
                        {State.getStatesOfCountry(selectedCountry.value)?.map((state) => (
                        <option key={state.isoCode} value={state.isoCode}>{state.name}</option>))}
                      </select>
                    </div>
    
                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label htmlFor="city" className="block text-sm font-medium text-gray-700 dark:text-white">
                        City
                      </label>
                      <select
                      required={true}
                      id="city"
                      isDisabled={!formData?.state}
                        onChange={inputHandler}
                        name="city"
                        value={formData?.city}
                        className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:focus:outline-none dark:focus:ring-indigo-500 dark:focus:border-indigo-500 sm:text-sm"
                      >
                        <option selected>Select City</option>
                        {formData.state? City.getCitiesOfState(selectedCountry.value, formData.state)?.map((city) => (
                          <option key={city.name} value={city.name}>{city.name}</option>)): []}
                      </select>
                    </div>
                    
                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700 dark:text-white">
                        ZIP / Postal code
                      </label>
                      <input
                      name="pin_code"
                      placeholder="Pincode*" 
                  onInvalid={(e) => e.target.setCustomValidity("Please enter a valid 6 digit pin code")}
                        onChange={(e) => {
                            e.target.setCustomValidity("");
                            inputHandler(e);
                        }}
                    required={true}
                    type="tel"
                    pattern="[1-9]{1}[0-9]{5}"
                      value={formData?.pin_code}
                        id="postal-code"
                        autoComplete="postal-code"
                        className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
    
                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label htmlFor="date_joined" className="block text-sm font-medium text-gray-700 dark:text-white">
                        Date Joined
                      </label>
                      <input
                      disabled={true}
                      name="date_joined"
                      placeholder="Date Joined" 
                      value={formData?.date_joined}
                        type="text"
                        id="date_joined"
                        className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label htmlFor="updated_at" className="block text-sm font-medium text-gray-700 dark:text-white">
                      Updated At
                      </label>
                      <input
                      disabled={true}
                      name="updated_at"
                      placeholder="Updated At" 
                      value={formData?.updated_at}
                        type="text"
                        id="updated_at"
                        className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label htmlFor="last_login" className="block text-sm font-medium text-gray-700 dark:text-white">
                      Last Login
                      </label>
                      <input
                      disabled={true}
                      name="last_login"
                      placeholder="Last Login" 
                      value={formData?.last_login}
                        type="text"
                        id="last_login"
                        className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
             
                    <div className="col-span-6">
                      <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-white">
                      Password
                      </label>
                      <input
                      type="password"
                      name="password"
                      placeholder="Password*" 
                      autoComplete="on"
                      value={formData?.password}
                      onChange={inputHandler}
                        className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-6">
                  <label className="block text-sm font-medium text-gray-700">Profile Picture</label>
                  <div className="mt-1 flex items-center">                                          
                    <input name="profile_picture"
                        id="default_size"
                      className="bg-white border border-gray-300 dark:border-gray-900 dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onChange={(e) => {
                        setFormData({ ...formData, profile_picture: e.target.files[0] });
                      }}                    
                        type="file" accept="image/*" />
                        {(oldProfilePicture)?
                  <span className="ml-5 inline-block bg-gray-100 rounded-lg overflow-hidden h-20 w-20">
                        <img
                        src={`${API}${oldProfilePicture}`}
                        alt="Preview"
                        className='h-full w-full'
                        />
                        </span>
                        :''
                        }
                        {(formData?.profile_picture!==null && formData?.profile_picture!==undefined &&formData?.profile_picture!=='')?
                        <>
                        <IoMdSwap className="ml-5 w-16 h-16" width="24" height="24"/>
                  <span className="ml-5 inline-block bg-gray-100 rounded-lg overflow-hidden h-20 w-20">
                        <img
                        src={URL.createObjectURL(formData?.profile_picture)}
                        alt="Preview"
                        className='h-full w-full'
                        />
                        </span>
                        </>
                        :''
                        }


                  </div>
                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <div className="flex items-start">
                  <input
                  name="is_admin" 
                        type="checkbox"
                        onChange={(e) => {
                          setFormData({ ...formData, is_admin: e.target.checked });
                        }}
                         checked={formData?.is_admin}
                    className="h-4 w-4 border-gray-300 flex items-center dark:border-gray-900 rounded py-2 px-3 text-indigo-600 focus:ring-indigo-500"
                  />  
                   <div className="ml-3 text-sm">
                        <label htmlFor="is_admin" className="font-medium text-gray-700 dark:text-white">
                        Is Admin
                        </label>
                        <p className="text-gray-500 dark:text-gray-400">If checked then user has admin privileges </p>
                      </div>
                </div>
                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <div className="flex items-start">
                  <input
                  name="is_staff" 
                        type="checkbox"
                        onChange={(e) => {
                          setFormData({ ...formData, is_staff: e.target.checked });
                        }}
                         checked={formData?.is_staff}
                    className="h-4 w-4 border-gray-300 flex items-center dark:border-gray-900 rounded py-2 px-3 text-indigo-600 focus:ring-indigo-500"
                  />  
                   <div className="ml-3 text-sm">
                        <label htmlFor="is_staff" className="font-medium text-gray-700 dark:text-white">
                        Is Staff
                        </label>
                        <p className="text-gray-500 dark:text-gray-400">If checked then user has staff privileges </p>
                      </div>
                </div>
                </div>

                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 dark:bg-gray-700">
                  <button
                    type="submit"
                    className="w-full bg-green-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                  >
                    Update User
                  </button>
                </div>
              </div>
              </form>
              {error?.status? <div className={`bg-${error?.type}-200 mt-4 flex p-4 mb-4 text-sm text-${error?.type}-800 border border-${error?.type}-300 rounded-lg bg-${error?.type}-50 dark:bg-gray-800 dark:text-${error?.type}-400 dark:border-${error?.type}-800`} role="alert">
      <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
      <div>
      {error?.msg}
      </div>
    </div>
     :''}
              </div>
              </div>
  )
  }
export default EditUserAdmin
