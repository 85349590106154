import { Fragment, useEffect } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import logo from "../../img/logo3.png"
import React, { useState } from 'react'
import * as Icon from 'react-bootstrap-icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { API } from "../../backend";
import Cookies from "js-cookie";
import { getLogoutAction, getRemoveTokenAction } from "../../redux/actions";


const Header = () => {
  const location = useLocation();

const navigation = [
  { name: 'Home', href: '/', current: (location.pathname==='/')?true:false },
  { name: 'Categories', href: '/categories', current: (location.pathname==='/categories')?true:false  },
  { name: 'Equipments', href: '/equipments', current: (location.pathname==='/equipments')?true:false  },
  { name: 'Dashboard', href: '/dashboard', current: (location.pathname==='/dashboard')?true:false  },
  { name: 'Add Equipment', href: '/addEquipment', current: (location.pathname==='/addEquipment')?true:false  },
  { name: 'Bookings', href: '/booking-history', current: (location.pathname==='/booking-history')?true:false  },
  { name: 'Contact', href: '/contact', current: (location.pathname==='/contact')?true:false  },
  { name: 'AboutUs', href: '/about', current: (location.pathname==='/about')?true:false  },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

  const dispatch = useDispatch();
  const navigate= useNavigate();
  const authState = useSelector((state) => state.authReducer);
  const profile_picture='';
  if(authState.user.data.profile_picture){
    profile_picture=`${API}${authState.user.data.profile_picture}`;
  } 

  const [themeOption, setThemeOption] = useState(Cookies.get('theme')?Cookies.get('theme'):'system');


  useEffect(() => {
    const html = document.querySelector('html');
    if (themeOption === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        html.classList.add("dark");
      } else {
        html.classList.remove("dark");
      }
    } else if (themeOption === 'light') {
      html.classList.remove("dark");
    } else if (themeOption === 'dark') {
      html.classList.add("dark");
    }
  }, [themeOption]);

  function handleThemeOptionChange(event) {
    setThemeOption(event.target.value);
    Cookies.set("theme", event.target.value);
  }

  const LogoutHandler = () =>{
    Cookies.remove("access-token");
    Cookies.remove("refresh-token");
    Cookies.remove("uuid");
    Cookies.remove("user");
    Cookies.remove("is_admin");
    dispatch(getLogoutAction());
    dispatch(getRemoveTokenAction());
        navigate("/login");
    };
  return (
<>
<div className="bg-[#219653] shadow dark:bg-gray-800 py-1 px-4 flex justify-end">
<span className="text-xl font-semibold text-gray-700 dark:text-white capitalize px-4">
        Language
      </span>
<div className='text-gray-800 dark:text-white bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm py-1 px-3 leading-5 focus:outline-none focus:border-blue-300 focus:ring focus:ring-blue-200 disabled:opacity-50 disabled:pointer-events-none transition duration-150 ease-in-out' id="google_element"></div>

      <span className="text-xl font-semibold text-gray-700 dark:text-white capitalize px-4">
        Theme
      </span>
      <select
        value={themeOption}
        onChange={handleThemeOptionChange}
        className="text-gray-800 dark:text-white bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm py-1 px-3 leading-5 focus:outline-none focus:border-blue-300 focus:ring focus:ring-blue-200 disabled:opacity-50 disabled:pointer-events-none transition duration-150 ease-in-out"
      >
        <option value="system">System</option>
        <option value="light">Light</option>
        <option value="dark">Dark</option>
      </select>
    </div>
<Disclosure as="nav" className="bg-[#219653] shadow dark:bg-gray-800">
  {({ open }) => (
    <>
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            {/* Mobile menu button*/}
            <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md dark:text-gray-400 text-white hover:text-gray-500 hover:bg-gray-100 dark:hover:text-white dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 dark:focus:ring-white">
              <span className="sr-only">Open main menu</span>
              {open ? (
                <XIcon className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <MenuIcon className="block h-6 w-6" aria-hidden="true" />
              )}
            </Disclosure.Button>
          </div>
          <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex-shrink-0 flex items-center">
              <img
                className="block lg:hidden h-8 w-auto"
                src={logo}
                alt="Krishi Sangam"
              />
              <img
                className="hidden -ml-5 lg:block h-12 w-auto"
                src={logo}
                alt="Krishi Sangam"
              />
            </div>
            <div className="hidden sm:block sm:ml-6">
              <div className="flex space-x-4">
                {navigation?.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      item.current ? 'bg-green-700 dark:bg-gray-900 text-black-300 dark:text-white' : 'text-black-300 dark:text-gray-300 hover:bg-green-600 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-white',
                      'px-3 py-2 rounded-md text-sm font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
    {(Cookies.get('refresh-token'))?
    <>
            {/* Profile dropdown */}
            <Menu as="div" className="ml-3 relative z-10">
              <div>
                <Menu.Button className="flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-200 focus:ring-gray-800 dark:focus:ring-offset-gray-800 dark:focus:ring-white">
                  <span className="sr-only">Open user menu</span>
                      {profile_picture?
                      <img 
                      className="h-8 w-8 rounded-full object-cover" 
                      src={profile_picture}
                      alt=""
                      />:<Icon.PersonCircle  className='h-8 w-8 rounded-full object-cover'
                      />}

       <h1 className="mx-2 text-xl font-semibold text-gray-700 dark:text-white capitalize">Hi, {authState.user.data.first_name}</h1>
                </Menu.Button>
                
              </div>
              
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >  
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-white ring-opacity-5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/profile"
                        className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                      >
                        Your Profile
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/update-profile"
                        className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                      >
                        Settings
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                      onClick={LogoutHandler}
                        to="#"
                        className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                      >
                        Sign out
                      </Link>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
              </Menu></>
             :
             
<div className="flex items-center">
          <div className="flex-shrink-0">
            <button
            onClick={()=>navigate("/login")}
              className="ml-2 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-800 focus:ring-green-800 focus:ring-green-300"
            >
              <span>Sign Up/ Login</span>
            </button>
          </div>
          </div>}
            </div>
          </div>
        </div>
    

      <Disclosure.Panel className="sm:hidden">
        <div className="px-2 pt-2 pb-3 space-y-1">
          {navigation?.map((item) => (
            <Disclosure.Button
              key={item.name}
              as="a"
              href={item.href}
              className={classNames(
                item.current ? 'bg-green-700 dark:bg-gray-900 text-gray-900 dark:text-white' : 'text-black-300 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-white',
                'block px-3 py-2 rounded-md text-base font-medium'
              )}
              aria-current={item.current ? 'page' : undefined}
            >
              {item.name}
            </Disclosure.Button>
          ))}
        </div>
      </Disclosure.Panel>
    </>
  )}
</Disclosure>
</>
  )
}
export default Header

