
import React from 'react'
import { useState, useEffect } from 'react'
import { getEquipmentsAll,getCategoriesAll,getCompaniesAll } from '../core/helper/coreapicalls';
import { Range } from 'react-range';
import { API } from '../backend';
import Cookies from 'js-cookie';
import axios from 'axios';
import Sidebar from '../components/Sidebar/Sidebar';
import { CheckCircleIcon, XIcon } from '@heroicons/react/solid'

const EquipmentRequest = () => {
  const [alertC, showAlert] = useState({
    status:false,
    msg:"",
    type:""
})
    const [Equipments, setEquipments] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [filteredEquipments, setFilteredEquipments] = useState([]);
    const [visibleItems, setVisibleItems] = useState(10);
    const itemsPerLoad = 10;
    const [selectedManufacturer, setSelectedManufacturer] = useState('');
    const [dailyRentalFilter, setDailyRentalFilter] = useState([0, 25000]);
  const [totalResults, setTotalResults] = useState(0);
  const [error, setError] = useState(false);

    const loadEquipments = () => {
    
        getEquipmentsAll()
        .then((data) => {
          if (data.error){
            setError(data.error)
            console.log(error)
          }
          else{
            setEquipments(data);
          setTotalResults(data?.length)}
        })
        };
        const [Companies, setCompanies] = useState([])
  
      const loadCompanies = () => {
      
          getCompaniesAll()
          .then((data) => setCompanies(data))
          };
          
          useEffect(() => {
          loadCompanies();
          },[]);
          const [Categories, setCategories] = useState([])
        
          const loadCategories = () => {
        
              getCategoriesAll()
              .then((data) => setCategories(data))
            };
            
            useEffect(() => {
            loadCategories();
            },[]);
         
    useEffect(() => {
      loadEquipments();
    }, []);
      useEffect(() => {
      let filtered = Equipments.filter(
        (equipment) => (equipment?.status === "Pending")
      );
  
      if (selectedCategory) {
        filtered = filtered.filter(
          (equipment) => equipment?.category?.id === parseInt(selectedCategory)
        );
      }
  
      if (selectedManufacturer) {
        filtered = filtered.filter(
          (equipment) => equipment?.manufacturer?.id === parseInt(selectedManufacturer)
        );
      }
  
      if (dailyRentalFilter) {
        filtered = filtered.filter(
          (equipment) => equipment.daily_rental >= parseInt(dailyRentalFilter[0]) && equipment.daily_rental <= parseInt(dailyRentalFilter[1] )
        );
      }
  
      if(searchKeyword){
          filtered = filtered.filter(
              (equipment) => equipment.title.toLowerCase().includes(searchKeyword.toLowerCase())
            );
      
      }
      
      setFilteredEquipments(filtered);
  
      const maxxxx = Math.max(filteredEquipments?.map((equipment) => equipment.daily_rental).filter(daily_rental => daily_rental != null));
    }, [visibleItems,Equipments, selectedCategory, selectedManufacturer, dailyRentalFilter]);
  

  
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };
  
  const handleManufacturerChange = (event) => {
    setSelectedManufacturer(event.target.value);
  };
  
  const handleMinInputChange = (event) => {
    const newValue = parseInt(event.target.value);
  
    setDailyRentalFilter([newValue, dailyRentalFilter[1]]);
  };
  
  const handleMaxInputChange = (event) => {
    const newValue = parseInt(event.target.value);
    setDailyRentalFilter([dailyRentalFilter[0], newValue]);
  };
 
    const handleAccept = async (id, status) => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get('access-token')}`
        };
        await axios.put(`${API}/equipment/status/${id}/` , { status }, { headers });
        showAlert({status:true, msg:"Equipment has been updated", type:"green"})
        loadEquipments();
    }
  return (
    <div className="flex bg-gray-100 dark:bg-gray-900">
<Sidebar/>
    <div className="flex-grow bg-gray-100 dark:bg-gray-900 mx-auto py-4 sm:px-6 ">
    {alertC.status && 
  <div className={`rounded-md bg-${alertC.type}-50 p-4`}>
  <div className="flex">
    <div className="flex-shrink-0">
      <CheckCircleIcon className={`h-5 w-5 text-${alertC.type}-400`} aria-hidden="true" />
    </div>
    <div className="ml-3">
      <p className="text-sm font-medium text-green-800">{alertC.msg}</p>
    </div>
    <div className="ml-auto pl-3">
      <div className="-mx-1.5 -my-1.5">
        <button
          type="button"   
          onClick={()=> showAlert({status:false})}

          className={`inline-flex bg-${alertC.type}-50 rounded-md p-1.5 text-${alertC.type}-500 hover:bg-${alertC.type}-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-${alertC.type}-50 focus:ring-${alertC.type}-600`}
        >
          <span className="sr-only">Dismiss</span>
          <XIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>
</div>
}
     
      <div className="px-4 sm:px-0 flex flex-col sm:flex-row justify-between items-center bg-gray-100 dark:bg-gray-900">
      <h1 className=" mb-4 text-gray-900 tracking-tight dark:text-white text-3xl font-bold">Equipment Requests</h1>

        <input
    type="text"
    value={searchKeyword}
    className="block border border-grey-light p-3 rounded mb-4"
    onChange={event =>{ setSearchKeyword(event.target.value);
    }}
    placeholder="Search equipments..."
  />
      </div>
      <div className="shadow p-5 rounded-lg bg-white dark:bg-gray-800">
 
	<div className="flex items-center justify-between">
	  <p className="font-medium dark:text-white">
		Filters
	  </p>

	  {/* <button className="px-4 py-2 bg-gray-100 dark:bg-gray-500 hover:bg-gray-200 text-gray-800 dark:text-gray-100 text-sm font-medium rounded-md">
		Reset Filter
	  </button> */}
	</div>

	<div>
	  <div className="grid grid-cols-2 md:grid-cols-1 xl:grid-cols-2 gap-4 mt-4">
		<select value={selectedCategory} onChange={handleCategoryChange} className="px-4 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm">
    <option selected value=''>All Categories</option>
{ Categories?.map((Categories) =>   <option key={Categories.id} value={Categories.id}>{Categories.name}</option>) }
		</select>

		<select value={selectedManufacturer} onChange={handleManufacturerChange} className="px-4 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm">
    <option selected value="">All Manufacturers</option>
          { Companies?.map((Companies) =>   <option key={Companies.id} value={Companies.id}>{Companies.name}</option>) }

		</select>
    <div className="flex flex-wrap px-4 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm">

    <lable className="">Minimum Price:-    </lable>
    <input
          type="number"
          value={dailyRentalFilter[0]}
          min={0}
          onChange={handleMinInputChange}
          className="w-full px-1 -mx-2 py-2 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm"
        />
    </div>

    <div className="flex flex-wrap px-4 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm">

    <lable className="">Maximum Price:-    </lable>
    <input
         type="number"
         value={dailyRentalFilter[1]}
         onChange={handleMaxInputChange}
         max={25000}
          className="w-full px-1 -mx-2 py-2 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm"
        />
    </div>
	  </div>
	</div>
  <div className="relative mt-4 px-2 py-3">
  <Range
  values={dailyRentalFilter}
  step={1}
  min={0}
  max={25000}
  onChange={(newValues) => setDailyRentalFilter(newValues)}
  renderTrack={({ props, children }) => (
    <div
      {...props}
      className="w-full h-2 bg-gray-300 rounded-full cursor-pointer bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
    >
      {children}
    </div>
  )}
  renderThumb={({ index, props }) => (
    <div
      {...props}
      className={`h-4 w-4 bg-blue-500 rounded-full outline-none shadow-md cursor-pointer ${index === 0 ? 'ml-0' : 'ml-2'}`}
    />
  )}
/>

	  </div>
  </div>

 
      <div className="mt-16">
        

        <div className="space-y-16 sm:space-y-24">
          {(filteredEquipments?.length!==0)?filteredEquipments?.slice(0, visibleItems)?.map((equipment) => (
              <div key={equipment.eq_id} className="mt-6 flow-root px-4 sm:mt-10 sm:px-0">
                <div className="-my-6 divide-y divide-gray-200 sm:-my-10">
                    <div key={equipment.eq_id} className="flex py-6 sm:py-10">
                      <div className="min-w-0 flex-1 lg:flex lg:flex-col">
                        <div className="lg:flex-1">
                          <div className="sm:flex">
                            <div>
                              <h4 className="font-medium text-gray-900 dark:text-white">{equipment.title}</h4>
                              <p className="mt-2 text-sm text-gray-500 sm:block">Manufacturer:-{equipment?.manufacturer?.name}</p>
                              <p className="mt-2 text-sm text-gray-500 sm:block">Category:{equipment.category?.name}</p>
                              <p className="hidden mt-2 text-sm text-gray-500 sm:block">{equipment.description}</p>
                            </div>
                            <p className="mt-1 font-medium text-gray-900 dark:text-white sm:mt-0 sm:ml-6">Rs. {equipment.daily_rental} /Day</p>
                          </div>
                          <div className="mt-2 flex text-sm font-medium sm:mt-4">
                            <a href={`/equipment/${equipment?.eq_id}/${equipment?.title}`} className="text-indigo-600 hover:text-indigo-500">
                              View Equipment
                            </a>
                           
                            <div className="border-l border-gray-200 dark:border-gray-700 ml-4 pl-4 sm:ml-6 sm:pl-6">
                              <button onClick={(e) => handleAccept(equipment?.eq_id, 'Accepted')} className="bg-[#F5F5FA] hover:bg-gray-200 text-green-400 font-bold py-1 px-4 rounded">
                                Accept
                              </button>
                            </div>
                            <div className="border-l border-gray-200 dark:border-gray-700 ml-4 pl-4 sm:ml-6 sm:pl-6">
                              <button onClick={(e) => handleAccept(equipment?.eq_id, 'Rejected')} className="bg-[#F5F5FA] hover:bg-gray-200 text-red-400 font-bold py-1 px-4 rounded">
                                Reject
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ml-4 flex-shrink-0 sm:m-0 sm:mr-6 sm:order-first">
                        <img
                          src={equipment.image_1}
                          alt={equipment.title}
                          className="col-start-2 col-end-3 sm:col-start-1 sm:row-start-1 sm:row-span-2 w-20 h-20 rounded-lg object-center object-cover sm:w-40 sm:h-40 lg:w-52 lg:h-52"
                        />
                      </div>
                    </div>
                </div>
              </div>
          )):
          <div className={`mt-4 flex p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800`} role="alert">
    <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
    <span className="sr-only">Alert</span>
    <div>
    Nothing To Show Here!!!!
    </div>
  </div>}{(totalResults>visibleItems)?
                    <div className='flex justify-center my-10'>
                      <button onClick={() => setVisibleItems(visibleItems + itemsPerLoad)}>Load More</button>
                    </div>:''}
        </div>
      </div>
  </div>
  </div>
  )
}

export default EquipmentRequest

