import { API } from "../../backend";
import axios from "axios";
import Cookies from "js-cookie";
import instance from "../../core/helper/config";

  export const updateUser = async ({ formData, accessToken, uuid }) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`
  };
  try {
    const res = await instance.patch(`${API}/users/${uuid}/`, formData, {
      headers
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err.response?.data?.msg);
  }
};
  export const updateProfile = async ({ formData, accessToken }) => {
  const uuid = Cookies.get("uuid");
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`
  };
  try {
    const res = await instance.put(`${API}/users/${uuid}/`, formData, {
      headers
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err.response?.data?.msg);
  }
};

  export const changePassword = async ({ formData, accessToken }) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`
  };
  try {
    const res = await instance.post(`${API}/users/changepassword/`, formData, {
      headers
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err.response?.data);
  }
};
  export const resetPasswordEmail = async (formData) => {
  try {
    const res = await instance.post(`${API}/users/reset-password-email/`, formData);
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err.response?.data);
  }
};
  export const resetPassword = async (formData, uid, token) => {
  try {
    const res = await instance.post(`${API}/users/reset-password/${uid}/${token}/`, formData);
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err.response?.data);
  }
};

export const signup = async ({
    first_name,
    email,
    password,
    confirm_password,
    last_name,
    pin_code,
    phone_number
  }) => {
    try {
      const res = await axios.post(`${API}/users/signup/`, {
        first_name,
        last_name,
        email,
        password,
        confirm_password,
        pin_code,
        phone_number
      });
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err.response?.data);
    }
  };
export const verifyOTP = async ({
    email,
    otp
  }) => {
    try {
      const res = await axios.post(`${API}/users/signup/verify-otp/`, {
        email,
        otp
      });
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err.response?.data);
    }
  };
  
  export const createUser = async (user) => {
    try {
      const res = await axios.post(`${API}/users/signup/`, user);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err.response?.data);
    }
  };
  
  export const signin = async ({ email, password }) => {
    try {
      const res = await instance.post(`/users/login/`, {
        email,
        password
      });
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err.response?.data);
    }
  };

  export const renewAccessToken = async () => {
    const refresh_token = Cookies.get("refresh-token");
    try {
      const res = await axios.post(`${API}/api/token/refresh/`, {
        refresh: refresh_token
      });
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err.response?.data?.msg);
    }
  };

  export const profile = async ({ uuid, accessToken }) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    try {
      const res = await instance.get(`${API}/users/${uuid}/`, { headers });
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err.response?.data?.msg);
    }
  };
  
  export const disputeForm = async ({
    partner_id,
    email,
    name,
    phone_number,
    description,
    topic,
    equipment_id
  }) => {
    try {
      const res = await axios.post(`${API}/userforms/partner-dispute`, {
        partner_id,
        email,
        name,
        phone_number,
        description,
        topic,
        equipment_id
      });
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err.response?.data?.msg);
    }
  };
  
  export const cancellationForm = async ({
    booking_id,
    cancel_reason,
    description,
    token
  }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer  + ${token}`
      };
      const res = await instance.post(
        `${API}/userforms/cancel-form`,
        {
          booking_id,
          cancel_reason,
          description
        },
        { headers }
      );
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err.response?.data?.msg);
    }
  };
  

