import React from 'react';
import { Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    padding: 32,
    fontFamily: 'Helvetica',
    fontSize: 12,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 16,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  section: {
    marginBottom: 24,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  label: {
    marginRight: 8,
  },
  value: {
    flexGrow: 1,
  },
  image: {
    width: 120,
    height: 120,
    borderRadius: 8,
    marginRight: 16,
  },
});

function BookingInvoice(props) {
  return (
    <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.row}>
          <Text style={styles.title}>Booking #{props.Data.booking_id}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.subtitle}>Booking placed:- {props.Data.created_at?.slice(0, props.Data.created_at.indexOf('T'))}</Text>
        </View>
      </View>
      <View style={styles.section}>
        <View style={styles.row}>
          <Image src={props.Data.equipment?.image_1} style={styles.image} />
          <View style={styles.value}>
            <Text style={styles.title}>{props.Data.equipment?.title}</Text>
            <Text style={{ marginBottom: 8 }}>Rs {props.Data.equipment?.daily_rental} / Per Day</Text>
            <Text style={{ marginBottom: 8 }}>{props.Data.equipment?.description}</Text>
            <Text style={{ marginBottom: 8 }}>Manufacturer:- {props.Data.equipment?.manufacturer?.name}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={{ marginRight: 32 }}>
            <Text style={styles.subtitle}>Customer Details</Text>
            <Text>Name:- {props.Data.customer?.first_name} {props.Data.customer?.last_name}</Text>
            <Text>Phone Number:- {props.Data.customer?.phone_number}</Text>
            <Text>Email:- {props.Data.customer?.email}</Text>
            <Text>Address:- {(props.Data.customer?.address) ? props.Data.customer?.address : 'Not Available'}</Text>
          </View>
          <View>
            <Text style={styles.subtitle}>Owner Details</Text>
            <Text>Name:- {props.Data.owner?.first_name} {props.Data.owner?.last_name}</Text>
            <Text>Phone Number:- {props.Data.equipment?.owner.phone_number}</Text>
            <Text>Address:- {(props.Data.owner?.address) ? props.Data.owner?.address : 'Not Available'}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={{ marginRight: 32 }}>
            <Text style={styles.subtitle}>Booking Summary</Text>
            <Text>Booking Id:- {props.Data?.booking_id}</Text>
            <Text>Request Status:- {props.Data.status}</Text>
            {(props.Data?.number_of_days===1)?
            <Text>From/To Date:- {props.Data?.start_date}- {props.Data?.end_date}{'\n'}
                                  {props.Data?.start_time}- {props.Data?.end_time}</Text>:
            <Text>From/To Date:- {props.Data?.start_date}- {props.Data?.end_date}</Text>}
          </View>
          {(props.Data?.number_of_days===1)?
          <View>
            <Text style={styles.subtitle}>Amount Details</Text>
            <Text>Total:- Rs {props.Data?.equipment?.hourly_rental} / hour</Text>
            <Text>No of Days:- {props.Data?.number_of_days} days</Text>
            <Text>No of Hours:- {props.Data?.total_hours?.toString().replace("-", "")} hours</Text>
            <Text>Sub Total:- Rs {props.Data?.total_hourly_rent?.toString().replace("-", "")}</Text>
          </View>:
          <View>
            <Text style={styles.subtitle}>Amount Details</Text>
            <Text>Total:- Rs {props.Data?.equipment?.daily_rental} / day</Text>
            <Text>No of Days:- {props.Data?.number_of_days} days</Text>
            <Text>Sub Total:- Rs {props.Data?.total_daily_rent}</Text>
          </View>}
        </View>
      </View>
    </Page>
  </Document>
  );
}

export default BookingInvoice;
