export const isMatch = (password, cfPassword) => {
    return password === cfPassword;
  };
  
  export const isMobile =(phone) => {
    const re=/^[+]?(0|91)?[6-9][0-9]{9}$/;
    
;
    return re.test(phone);
  }

  export const isEmpty = (value) => {
    if (!value) return true;
    return false;
  };
  
  export const isEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  
  export const isValidPassword = (password) => {
    if (password?.length >= 4) return true;
    return false;
  };
  

  