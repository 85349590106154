import Cookies from "js-cookie";
import axios from "axios";
import instance from "../../core/helper/config";
import { API } from "../../backend";

const url = `${API}`;

export const getBooking = async (page) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("access-token")}`,
    };
    return await instance.get(`/booking/?page=${page}`, { headers });
  } catch (error) {
    console.log("Error while calling getBookings API", error);
  }
};

export const getBookingOwner = async (page) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("access-token")}`,
    };
    return await instance.get(`/booking/request/?page=${page}`, { headers });
  } catch (error) {
    console.log("Error while calling getBookings API", error);
  }
};

export const createBooking = async (
  equipment,
  start_date,
  end_date,
  start_time,
  end_time
) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("access-token")}`,
    };
    const res = await instance.post(
      "/booking/create/",
      {
        equipment,
        start_date,
        end_date,
        start_time,
        end_time,
      },
      { headers }
    );
    return Promise.resolve(res?.data);
  } catch (error) {
    console.log("Error while calling createBooking API", error);
    return Promise.reject(error?.response?.data);
  }
};

export const getBookingDetail = async (id) => {
    try {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get('access-token')}`
        };
        return await instance.get(`/booking/detail/${id}/` , { headers });
    } catch(error) {
        console.log('Error while calling getBookingDetail API', error);
    }
}


export const BookingListRequest = async () => {
    try {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get('access-token')}`
        };
        return await instance.get('/booking/request/' , { headers });
    } catch(error) {
        console.log('Error while calling getBookingDetail API', error);
    }
};

export const BookingUpdate = async (status, id) => {
  try {
      const headers = {
          "Content-Type": "application/json",
          Authorization: `"Bearer ${Cookies.get('access-token')}`
      };
      return await axios.patch(`${API}/booking/update/${id}/` , { status }, { headers });
  } catch(error) {
      console.log('Error while calling getBookingDetail API', error);
  }
};
