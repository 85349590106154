import React, { useState } from 'react'
import { resetPasswordEmail } from '../../auth/helper';
import { Link } from 'react-router-dom';

const ResetPasswordEmailSent = () => {
    const [error, setError]= useState({
      status:false,
      msg:"",
      type:""
  })

    const [email, setEmail] = useState('');
  
  const ResetPasswordEmail = async (e) =>{
      e.preventDefault();
      const formData = new FormData();
      formData.append('email', email);
     try{const data = await resetPasswordEmail(formData);
                if(data?.success){
                  setError({status:true, msg:"Email for Password Reset has been sent. Please check your email!", type:"green"})
                }
  }
  catch(error) {
    console.log(error)
      setError({
        status: true,
        msg: "You are not a registerd User",
        type: 'red',
      });
  }
}

  return (
<section className="bg-white dark:bg-gray-900 flex flex-col">
  <div className="container my-4 flex-1 flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-center text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                  Reset Your Account Password
              </h1>
              <form className="space-y-4 md:space-y-6" id="loginform" onSubmit={ResetPasswordEmail} >
                  <div>
                      <label htmlFor="email" 
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter Your registered email address</label>
                      <input 
                      type="email" 
                      name="email"
                      required={true} 
                      className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                      placeholder="Email*"
                      onChange={(e) =>{setError({status:false}); setEmail(e.target.value)}}                      />
                  </div>
                 
                  <button 
                  type="submit" 
                  className="w-full text-white bg-green-600  hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-400 font-medium rounded-lg text-sm px-5 py-3 my-1 text-center">
                    Send Email</button>
                  <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                  Already have an account?&nbsp;
                  <Link className="font-medium text-blue-600 hover:underline dark:text-blue-500" to="/login">
                        Login 
                        </Link>
                        </p>
                  <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                  Don't have an account?&nbsp; 
                  <Link className="font-medium text-blue-600 hover:underline dark:text-blue-500" to="../signup/">
                  Sign Up
                    </Link> 
                  </p>
              </form>
              {error?.status? <div className={`mt-4 flex p-4 mb-4 text-sm text-${error?.type}-800 border border-${error?.type}-300 rounded-lg bg-${error?.type}-50 dark:bg-gray-800 dark:text-${error?.type}-400 dark:border-${error?.type}-800`} role="alert">
  <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
  <span className="sr-only">Alert</span>
  <div>
  {error?.msg}
  </div>
</div>
 :''}
          </div>
      </div>
  </div>
</section>
  )
}

export default ResetPasswordEmailSent
