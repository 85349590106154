import React, { useEffect, useState } from 'react'
import Sidebar from '../components/Sidebar/Sidebar'
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { deleteEquipment, getEquipmentsAll } from '../core/helper/coreapicalls';
import EquipmentRow from '../components/Table Rows/EquipmentRow';
import Cookies from 'js-cookie';
import { CheckCircleIcon, XIcon } from '@heroicons/react/solid'

const EquipmentList = () => {
   
  const [visibleItems, setVisibleItems] = useState(10);
  const itemsPerLoad = 10;
    const [Equipments, setEquipments] = useState([])
    const [selectedEquipments, setSelectedEquipments] = useState([]);
    const [totalResults, setTotalResults] = useState(0)
    const [selectAll, setSelectAll] = useState(false);
    const [alertC, showAlert] = useState({
      status:false,
      msg:"",
      type:""
  })
    const loadEquipments = () => {
  
        getEquipmentsAll()
        .then((data) => {setEquipments(data);
            setTotalResults(data?.length) })
      };

      useEffect(() => {
      loadEquipments();
      },[visibleItems]);

      const handleDeleteSelectedEquipments = async () => {
        const access_token= Cookies.get('access-token');
        if (window.confirm('Are you sure you want to delete selected Equipments?')) {
          try {
            for (const eq_id of selectedEquipments) {
              const result = await deleteEquipment(eq_id, access_token);
              if (!result.success) {
                showAlert({status:true, msg:"Error Deleting Equipments Please Wait for some time", type:"red"})
              }
            }
            showAlert({status:true, msg:"Equipments Deleted Successfully", type:"green"})
            setSelectedEquipments([]);
            loadEquipments();
            setSelectAll(false);
          } catch (error) {
            showAlert({status:true, msg:"Error Deleting Equipments Please Wait for some time", type:"red"})
          }
        }
      };
 
      const handleCheckboxChange = (event) => {
        setSelectAll(!selectAll)
        if (event.target.checked) {
          const newSelectedEquipments = [];
          Equipments.forEach((equipment) => {
            newSelectedEquipments.push(equipment?.eq_id);
          });
          setSelectedEquipments(newSelectedEquipments);}
          else {
          setSelectedEquipments([]);
        }
      };
  return (
<>

    <div className="flex bg-gray-50 dark:bg-gray-900">
<Sidebar/>

    <div className="flex-grow overflow-x-auto w-full bg-gray-50 dark:bg-gray-900">
    {alertC.status && 
  <div className={`rounded-md bg-${alertC.type}-50 p-4`}>
  <div className="flex">
    <div className="flex-shrink-0">
      <CheckCircleIcon className={`h-5 w-5 text-${alertC.type}-400`} aria-hidden="true" />
    </div>
    <div className="ml-3">
      <p className="text-sm font-medium text-green-800">{alertC.msg}</p>
    </div>
    <div className="ml-auto pl-3">
      <div className="-mx-1.5 -my-1.5">
        <button
          type="button"   
          onClick={()=> showAlert({status:false})}

          className={`inline-flex bg-${alertC.type}-50 rounded-md p-1.5 text-${alertC.type}-500 hover:bg-${alertC.type}-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-${alertC.type}-50 focus:ring-${alertC.type}-600`}
        >
          <span className="sr-only">Dismiss</span>
          <XIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>
</div>
}
                    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead className="bg-gray-50 dark:bg-gray-800">
                            <tr>
                            <th scope="col" colSpan={4} className="py-3.5 px-4 text-lg font-medium text-left text-gray-600 dark:text-gray-400">
                                   Equipments List
                                </th>
                                <th scope="col" className="py-3.5 px-4 text-sm font-normal text-left text-gray-500 dark:text-gray-400">
                        <button onClick={handleDeleteSelectedEquipments} className="flex items-center gap-x-3 text-gray-500 transition-colors duration-200 dark:hover:text-red-500 dark:text-gray-300 hover:text-red-500 focus:outline-none">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                </svg>
                <span >Delete Selected</span>
                </button>
                                </th>
                            <Link to="/add-equipment">
                            <th scope="col" className="py-3.5 px-4 text-sm font-normal text-left text-gray-600 dark:text-gray-400 text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none">
                        <div className="flex items-center gap-x-3">
                           
                            <Icon.FilePlus className="w-5 h-5" width="24" height="24" />
                <span >Add Equipment</span>
                </div>
                                </th>
            </Link>
                            </tr>
                            <tr>
                                <th scope="col" className="py-3.5 px-4 text-gray-600 text-md font-medium text-left dark:text-gray-400">
                                    <div className="flex items-center gap-x-3">
                                        <input type="checkbox" checked={selectAll} onChange={handleCheckboxChange} className="text-blue-500 border-gray-300 rounded dark:bg-gray-900 dark:ring-offset-gray-900 dark:border-gray-700"/>
                                        <span>Title</span>
                                    </div>
                                </th>

                                <th scope="col" className="px-12 py-3.5 text-md font-medium text-left text-gray-600 dark:text-gray-400">
                                        Owner

                                       
                                </th>

                                <th scope="col" className="px-4 py-3.5 text-md font-medium text-left text-gray-600 dark:text-gray-400">
                                        Equipment Id
                                </th>

                                <th scope="col" className="px-4 py-3.5 text-md font-medium text-left text-gray-600 dark:text-gray-400">Manufacturer</th>

                                <th scope="col" className="px-4 py-3.5 text-md font-medium text-left text-gray-600 dark:text-gray-400">Category</th>

                                <th scope="col" className="px-4 py-3.5 text-md font-medium text-left text-gray-600 dark:text-gray-400">Edit</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
{ (Equipments?.length!==0)?Equipments?.slice(0, visibleItems)?.map((Equipment) =>    <EquipmentRow key={Equipment?.id} equipment={Equipment} onDelete={loadEquipments} selectedEquipments={selectedEquipments} setSelectedEquipments={setSelectedEquipments}/>):
<div className={`mt-4 flex p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800`} role="alert">
    <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
    <span className="sr-only">Alert</span>
    <div>
    Nothing To Show Here!!!!
    </div>
  </div> }
                        </tbody>
                    </table>
                    {(totalResults>visibleItems)?
                    <div className='flex justify-center my-10'>
                      <button onClick={() => setVisibleItems(visibleItems + itemsPerLoad)}>Load More</button>
                    </div>:''}
                </div>
            </div>
           


</>
  )
}

export default EquipmentList
