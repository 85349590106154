import React, { useEffect, useState } from 'react'
import Sidebar from '../components/Sidebar/Sidebar'
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { deleteUser, getUsersAll } from '../core/helper/coreapicalls';
import UserRow from '../components/Table Rows/UserRow';
import Cookies from 'js-cookie'
import { CheckCircleIcon, XIcon } from '@heroicons/react/solid'


const UsersList = () => {
  
  const [Users, setUsers] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [alertC, showAlert] = useState({
    status:false,
    msg:"",
    type:""
})
  const loadUsers = () => {
    const access_token= Cookies.get("access-token");
    
    getUsersAll(access_token)
    .then((data) => {setUsers(data);
    })
  };
  useEffect(() => {
        loadUsers();
        },[]);

        const handleDeleteSelectedUsers = async () => {
            if (window.confirm('Are you sure you want to delete selected Users?')) {
              try {
                for (const uuid of selectedUsers) {
                  const access_token= Cookies.get("access-token");
                  const result = await deleteUser(uuid, access_token);
                  if (!result.success) {
                    showAlert({status:true, msg:"Error Deleting User Please Wait for some time", type:"red"})
                  }
                }
                showAlert({status:true, msg:"Users Deleted Successfully", type:"green"})
                setSelectedUsers([]);
                loadUsers();
                setSelectAll(false);
              } catch (error) {
                showAlert({status:true, msg:"Error Deleting User Please Wait for some time", type:"red"})
              }
            }
          };
          const handleCheckboxChange = (event) => {
            setSelectAll(!selectAll)
            if (event.target.checked) {
              const newSelectedUsers = [];
              Users.forEach((user) => {
                newSelectedUsers.push(user?.uuid);
              });
              setSelectedUsers(newSelectedUsers);}
              else {
              setSelectedUsers([]);
            }
          };
  return (
<>

    <div className="flex bg-gray-50 dark:bg-gray-900">
<Sidebar/>

    <div className="flex-grow overflow-x-auto w-full bg-gray-50 dark:bg-gray-900">
    {alertC.status && 
  <div className={`rounded-md bg-${alertC.type}-50 p-4`}>
  <div className="flex">
    <div className="flex-shrink-0">
      <CheckCircleIcon className={`h-5 w-5 text-${alertC.type}-400`} aria-hidden="true" />
    </div>
    <div className="ml-3">
      <p className="text-sm font-medium text-green-800">{alertC.msg}</p>
    </div>
    <div className="ml-auto pl-3">
      <div className="-mx-1.5 -my-1.5">
        <button
          type="button"   
          onClick={()=> showAlert({status:false})}

          className={`inline-flex bg-${alertC.type}-50 rounded-md p-1.5 text-${alertC.type}-500 hover:bg-${alertC.type}-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-${alertC.type}-50 focus:ring-${alertC.type}-600`}
        >
          <span className="sr-only">Dismiss</span>
          <XIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>
</div>
}
                    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead className="bg-gray-50 dark:bg-gray-800">
                            <tr>
                            <th scope="col" colSpan={4} className="py-3.5 px-4 text-lg font-normal text-left text-gray-500 dark:text-gray-400">
                                   Users List
                                </th>

                            <th scope="col" className="py-3.5 px-4 text-sm font-normal text-left text-gray-500 dark:text-gray-400">
                        <button onClick={handleDeleteSelectedUsers} className="flex items-center gap-x-3 text-gray-500 transition-colors duration-200 dark:hover:text-red-500 dark:text-gray-300 hover:text-red-500 focus:outline-none">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                </svg>
                <span >Delete Selected</span>
                </button>
                                </th>
                            <Link to="/add-user">
                            <th scope="col" className="py-3.5 px-4 text-sm font-normal text-left text-gray-500 dark:text-gray-400 text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none">
                        <div className="flex items-center gap-x-3">
                           
                            <Icon.PersonAdd className="w-5 h-5" width="24" height="24" />
                <span >Add User</span>
                </div>
                                </th>
            </Link>
                            </tr>
                            <tr>
                                <th scope="col" className="py-3.5 px-4 text-sm font-normal text-left text-gray-500 dark:text-gray-400">
                                    <div className="flex items-center gap-x-3">
                                        <input type="checkbox" checked={selectAll} onChange={handleCheckboxChange} className="text-blue-500 border-gray-300 rounded dark:bg-gray-900 dark:ring-offset-gray-900 dark:border-gray-700"/>
                                        <span>Name</span>
                                    </div>
                                </th>

                                <th scope="col" className="px-12 py-3.5 text-sm font-normal text-left text-gray-500 dark:text-gray-400">
                                Status
                                </th>

                                <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left text-gray-500 dark:text-gray-400">
                                    User Id
                                </th>

                                <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left text-gray-500 dark:text-gray-400">Email address</th>

                                <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left text-gray-500 dark:text-gray-400">Phone Number</th>

                                <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left text-gray-500 dark:text-gray-400">Edit</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
{ (Users?.length!==0)? Users?.map((User) =>    <UserRow key={User.uuid} user={User} onDelete={loadUsers} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} showAlert={showAlert}/>):
<div className={`mt-4 flex p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800`} role="alert">
    <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
    <span className="sr-only">Alert</span>
    <div>
    Nothing To Show Here!!!!
    </div>
  </div> }
                        </tbody>
                    </table>
  
                </div>
            </div>
           


</>
  )
}

export default UsersList
