import React, { useEffect, useState } from 'react'
import Sidebar from '../components/Sidebar/Sidebar'
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import BookingRow from '../components/Table Rows/BookingRow';
import Cookies from 'js-cookie';
import { CheckCircleIcon, XIcon } from '@heroicons/react/solid'
import instance from '../core/helper/config';

const BookingList = () => {
   
  const [visibleItems, setVisibleItems] = useState(10);
  const itemsPerLoad = 10;
    const [Booking, setBooking] = useState([])
    const [totalResults, setTotalResults] = useState(0)
    const [selectAll, setSelectAll] = useState(false);
    const [alertC, showAlert] = useState({
      status:false,
      msg:"",
      type:""
  })
        const loadBooking = async () => {
            const headers = {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get('access-token')}`
            };
            const { data } = await instance.get(`/booking/equip`, { headers });
            setBooking(data);
            setTotalResults(data?.length) 
        
          };

      useEffect(() => {
        loadBooking();
      },[visibleItems]);

  return (
<>

    <div className="flex bg-gray-50 dark:bg-gray-900">
<Sidebar/>

    <div className="flex-grow overflow-x-auto w-full bg-gray-50 dark:bg-gray-900">
    {alertC.status && 
  <div className={`rounded-md bg-${alertC.type}-50 p-4`}>
  <div className="flex">
    <div className="flex-shrink-0">
      <CheckCircleIcon className={`h-5 w-5 text-${alertC.type}-400`} aria-hidden="true" />
    </div>
    <div className="ml-3">
      <p className="text-sm font-medium text-green-800">{alertC.msg}</p>
    </div>
    <div className="ml-auto pl-3">
      <div className="-mx-1.5 -my-1.5">
        <button
          type="button"   
          onClick={()=> showAlert({status:false})}

          className={`inline-flex bg-${alertC.type}-50 rounded-md p-1.5 text-${alertC.type}-500 hover:bg-${alertC.type}-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-${alertC.type}-50 focus:ring-${alertC.type}-600`}
        >
          <span className="sr-only">Dismiss</span>
          <XIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>
</div>
}
                    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead className="bg-gray-50 dark:bg-gray-800">
                            <tr>
                            <th scope="col" colSpan={4} className="py-3.5 px-4 text-lg font-medium text-left text-gray-600 dark:text-gray-400">
                                   Booking List
                                </th>
                                <th scope="col" className="py-3.5 px-4 text-sm font-normal text-left text-gray-500 dark:text-gray-400">
                        
                                </th>
                            <Link to="/add-booking">
                            <th scope="col" className="py-3.5 px-4 text-sm font-normal text-left text-gray-600 dark:text-gray-400 text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none">
                        <div className="flex items-center gap-x-3">
                           
                            <Icon.FilePlus className="w-5 h-5" width="24" height="24" />
                <span >Add Booking</span>
                </div>
                                </th>
            </Link>
                            </tr>
                            <tr>
                                <th scope="col" className="py-3.5 px-4 text-gray-600 text-md font-medium text-left dark:text-gray-400">
                                    <div className="flex items-center gap-x-3">
                                        <span>Booking Id</span>
                                    </div>
                                </th>

                                <th scope="col" className="px-12 py-3.5 text-md font-medium text-left text-gray-600 dark:text-gray-400">
                                        Booking Person

                                       
                                </th>

                                <th scope="col" className="px-4 py-3.5 text-md font-medium text-left text-gray-600 dark:text-gray-400">
                                        Equipment Id
                                </th>

                                <th scope="col" className="px-4 py-3.5 text-md font-medium text-left text-gray-600 dark:text-gray-400">Booking Date</th>

                                <th scope="col" className="px-4 py-3.5 text-md font-medium text-left text-gray-600 dark:text-gray-400">Booking Amount</th>

                                <th scope="col" className="px-4 py-3.5 text-md font-medium text-left text-gray-600 dark:text-gray-400">Edit</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
{ (Booking?.length!==0)?Booking?.slice(0, visibleItems)?.map((Booking) =>    <BookingRow key={Booking?.booking_id} booking={Booking}  />):
<div className={`mt-4 flex p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800`} role="alert">
    <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
    <span className="sr-only">Alert</span>
    <div>
    Nothing To Show Here!!!!
    </div>
  </div> }
                        </tbody>
                    </table>
                    {(totalResults>visibleItems)?
                    <div className='flex justify-center my-10'>
                      <button onClick={() => setVisibleItems(visibleItems + itemsPerLoad)}>Load More</button>
                    </div>:''}
                </div>
            </div>
           


</>
  )
}

export default BookingList
