import Cookies from 'js-cookie';
import React, { useState } from 'react'
import Sidebar from '../components/Sidebar/Sidebar';
import { addCategory } from './../core/helper/coreapicalls';

const AddCategory = () => {
const[addCategoryFormData, setAddCategoryFormData] = useState({
    "name":'',
    "description":'',
    "image":null
});
const [error, setError]= useState({
    status:false,
    msg:"",
    type:""
})
const access_token= Cookies.get('access-token');

const inputHandler = (event) => {
  setError({status:false});
 setAddCategoryFormData({
    ...addCategoryFormData,
    [event.target.name]:event.target.value
 })
};
const handleImageChange = (event) => {
  setError({status:false});
  const target = event.target;
  const value = target.files[0];
  const name = target.name;
  setAddCategoryFormData({...addCategoryFormData , [name]: value });
};


const submitHandler = (event) =>{
    event.preventDefault();
    const categoryData = new FormData();
    categoryData.append('name', addCategoryFormData?.name);
    categoryData.append('description', addCategoryFormData?.description);
    (addCategoryFormData?.image) &&( categoryData.append('image', addCategoryFormData?.image))
    addCategory(categoryData, access_token)
    .then(data =>{
        if(data?.success){
          document.getElementById('categoryform').reset();
          setAddCategoryFormData({
            ...addCategoryFormData,
            "name":'',
    "description":'',
         })
            setError({status:true, msg:"Category Created Successfully", type:"green"})
        }
    })
    .catch((e)=> console.log(e));
}   

  return (
    
<div className="flex bg-gray-100 dark:bg-gray-900">
<Sidebar/>
<div className="flex-grow bg-gray-100 dark:bg-gray-900">
                  <form id="categoryform" onSubmit={submitHandler}>
          <div className="shadow sm:rounded-md sm:overflow-hidden ">
            <div className="bg-white py-6 px-4 space-y-6 sm:p-6 dark:bg-gray-800">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">Add New Category</h3>
              </div>

              <div className="grid grid-cols-6 gap-6">

              <div className="col-span-6">
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Name
                  </label>
                  <input
                    type="text"
                    onChange={inputHandler}
                    name="name"
                        placeholder="Name*"
                        required={true}
                        value={addCategoryFormData?.name}
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
 
              <div className="col-span-6">
                  <label htmlFor="description" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Description
                  </label>
                  <textarea
                    type="text"
                    placeholder="Description"
                    required={true}
                    onChange={inputHandler}
                    value={addCategoryFormData?.description}
                    name="description"
                    id="description"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
  
                <div className="col-span-3 sm:col-span-3 lg:col-span-2">
                  <label className="block text-sm font-medium text-gray-700">Image</label>
                  <div className="mt-1 flex items-center">
                    <input name="image"
                        id="default_size"
                      className="ml-5 bg-white border border-gray-300 dark:border-gray-900 dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onChange={handleImageChange}                   
                        type="file" accept="image/*" />
                  {(addCategoryFormData.image)?
                  <span className="ml-5 inline-block bg-gray-100 rounded-lg overflow-hidden h-20 w-20">
                        <img
                        src={URL.createObjectURL(addCategoryFormData.image)}
                        alt="Preview"
                        className='h-full w-full'
                        />
                        </span>
                        :''
                        }
                  
                  
                  </div>
                </div>

              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 dark:bg-gray-700">
              <button
                type="submit"
                className="w-full bg-green-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                Add Category
              </button>
            </div>
          </div>
          </form>
          {error?.status? <div className={`bg-${error?.type}-200 mt-4 flex p-4 mb-4 text-sm text-${error?.type}-800 border border-${error?.type}-300 rounded-lg bg-${error?.type}-50 dark:bg-gray-800 dark:text-${error?.type}-400 dark:border-${error?.type}-800`} role="alert">
  <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
  <div>
  {error?.msg}
  </div>
</div>
 :''}
          </div>
          </div>
  )
  }
export default AddCategory
