const initialstate = {
  user: {
    data: {
      id:parseInt("") ,
      uuid: "",
      user_id: "",
      first_name: "",
      last_name: "",
      email: "",
      address: "",
      state: "",
      city: "",
      pin_code: parseInt(""),
      profile_picture: "",
      phone_number: "",
      is_admin: false,
    },
  },
  isLoggedIn: false,
};

const authReducer = (state = initialstate, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isLoggedIn: true,
      };

    case "SAVE_PROFILE":
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };

    case "LOGOUT":
      return {
        ...state,
        user: { data: {} },
        isLoggedIn: false,
      };

    default:
      return state;
  }
};

export default authReducer;
