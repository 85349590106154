import Cookies from "js-cookie";
import axios from "axios";
import {API} from '../../backend'
import { renewAccessToken } from "../../auth/helper";
const instance = axios.create({
  baseURL : `${API}`,

});

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const status = error?.response ? error?.response.status : null;

    if (status === 401) {
      // will loop if refreshToken returns 401
      try {
        const token = await renewAccessToken();
        Cookies.set("access-token", token.access);
        error.config.headers["Authorization"] = "Bearer " + token.access;
        error.config.baseURL = undefined;
        return axios.request(error?.config);
      } catch (refreshError) {
        // Return the original error along with the refresh error
        error.refreshError = refreshError;
        return Promise.reject(error);
      }
    } else if (status === 400) {
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default instance;
