import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import Sidebar from '../components/Sidebar/Sidebar';
import { addCompany, getCategoriesAll } from './../core/helper/coreapicalls';
import Select from "react-select";


const AddCompany = () => {
const[addCompanyFormData, setAddCompanyFormData] = useState({
    "name":'',
    "category":[],
});
const [error, setError]= useState({
    status:false,
    msg:"",
    type:""
})
const access_token= Cookies.get('access-token');
const [Categories, setCategories] = useState([])

  const loadCategories = () => {

      getCategoriesAll()
      .then((data) => setCategories(data))
    };
    
    useEffect(() => {
    loadCategories();
    },[]);
      
const inputHandler = (event) => {
  setError({status:false});
 setAddCompanyFormData({
    ...addCompanyFormData,
    [event.target.name]:event.target.value
 })
};
const submitHandler = (event) =>{
    event.preventDefault();

    addCompany(addCompanyFormData, access_token)
    .then(data =>{
        if(data?.success){
          document.getElementById('companyform').reset();
          setAddCompanyFormData({
            ...addCompanyFormData,
            "name":'',
    "category":[],
         })
            setError({status:true, msg:"Company Created Successfully", type:"green"})
   
        }
    })
    .catch((e)=> console.log(e));
}   
const allOption = { value: "all", label: "Select All" };


  return (
    
<div className="flex bg-gray-100 dark:bg-gray-900">
<Sidebar/>
<div className="flex-grow bg-gray-100 dark:bg-gray-900">
                  <form id="companyform" onSubmit={submitHandler}>
          <div className="shadow sm:rounded-md sm:overflow-hidden ">
            <div className="bg-white py-6 px-4 space-y-6 sm:p-6 dark:bg-gray-800">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">Add New Company</h3>
              </div>

              <div className="grid grid-cols-6 gap-6">

              <div className="col-span-6">
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Name
                  </label>
                  <input
                    type="text"
                    onChange={inputHandler}
                    name="name"
                        placeholder="Name*"
                        required={true}
                        value={addCompanyFormData?.name}
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
 
                <div className="col-span-6">
                  <label htmlFor="category" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Category
                  </label>
                  <Select
                    required={true}
                    isMulti
                    options={[allOption, ...Categories?.map((category) => ({ value: category.id, label: category.name }))]}
                    value={addCompanyFormData.category?.map((catId) => ({ value: catId, label: Categories.find(cat => cat.id === catId)?.name }))}
                    onChange={(selectedOptions) => {
                      const categoryIds = selectedOptions?.map(option => option.value);
                      if (categoryIds.includes("all")) {
                        setAddCompanyFormData({
                          ...addCompanyFormData,
                          category: Categories?.map(category => category.id),
                        });
                      } else {
                        setAddCompanyFormData({
                          ...addCompanyFormData,
                          category: categoryIds,
                        });
                      }
                    }}
                    
                  />
                </div>


              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 dark:bg-gray-700">
              <button
                type="submit"
                className="w-full bg-green-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                Add Company
              </button>
            </div>
          </div>
          </form>
          {error?.status? <div className={`bg-${error?.type}-200 mt-4 flex p-4 mb-4 text-sm text-${error?.type}-800 border border-${error?.type}-300 rounded-lg bg-${error?.type}-50 dark:bg-gray-800 dark:text-${error?.type}-400 dark:border-${error?.type}-800`} role="alert">
  <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
  <div>
  {error?.msg}
  </div>
</div>
 :''}
          </div>
          </div>
  )
  }
export default AddCompany
