import React from 'react'
import { useState, useEffect } from 'react'
import EquipmentCard from '../components/Equipment/EquipmentCard'
import { getEquipmentsAll,getCategoriesAll,getCompaniesAll } from '../core/helper/coreapicalls';
import { Range } from 'react-range';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import Sidebar from '../components/Sidebar/Sidebar';
const UserEquipment = () => {
  const [Equipments, setEquipments] = useState([]);
  const [error, setError] = useState(false);
  const [visibleItems, setVisibleItems] = useState(10);
  const itemsPerLoad = 10;
  const authState = useSelector((state) => state.authReducer);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [filteredEquipments, setFilteredEquipments] = useState([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState('');
  const [dailyRentalFilter, setDailyRentalFilter] = useState([0, 25000]);
const [totalResults, setTotalResults] = useState(0);

  const loadEquipments = () => {
  
      getEquipmentsAll()
      .then((data) => {
        if (data.error){
          setError(data.error)
        }
        else{
          const filtered = data.filter(
            (equipment) => equipment?.owner?.uuid === Cookies.get('uuid')
          );
          setEquipments(filtered);
          setTotalResults(Equipments?.length);
        }
      })
      };
      const [Companies, setCompanies] = useState([])

    const loadCompanies = () => {
    
        getCompaniesAll()
        .then((data) => setCompanies(data))
        };
        
        useEffect(() => {
        loadCompanies();
        },[]);
        const [Categories, setCategories] = useState([])
      
        const loadCategories = () => {
      
            getCategoriesAll()
            .then((data) => setCategories(data))
          };
          
          useEffect(() => {
          loadCategories();
          },[]);
       
  useEffect(() => {
    loadEquipments();
  }, []);
    useEffect(() => {
    let filtered = Equipments;

    if (selectedCategory) {
      filtered = filtered.filter(
        (equipment) => equipment?.category?.id === parseInt(selectedCategory)
      );
    }

    if (selectedManufacturer) {
      filtered = filtered.filter(
        (equipment) => equipment?.manufacturer?.id === parseInt(selectedManufacturer)
      );
    }

    if (dailyRentalFilter) {
      filtered = filtered.filter(
        (equipment) => equipment.daily_rental >= parseInt(dailyRentalFilter[0]) && equipment.daily_rental <= parseInt(dailyRentalFilter[1] )
      );
    }

    if(searchKeyword){
        filtered = filtered.filter(
            (equipment) => equipment.title.toLowerCase().includes(searchKeyword.toLowerCase())
          );
    
    }
    
    setFilteredEquipments(filtered);

    const maxxxx = Math.max(filteredEquipments?.map((equipment) => equipment.daily_rental).filter(daily_rental => daily_rental != null));
  }, [visibleItems, Equipments, selectedCategory, selectedManufacturer, dailyRentalFilter, searchKeyword]);

  

const handleCategoryChange = (event) => {
  setSelectedCategory(event.target.value);
};

const handleManufacturerChange = (event) => {
  setSelectedManufacturer(event.target.value);
};

const handleMinInputChange = (event) => {
  const newValue = parseInt(event.target.value);

  setDailyRentalFilter([newValue, dailyRentalFilter[1]]);
};

const handleMaxInputChange = (event) => {
  const newValue = parseInt(event.target.value);
  setDailyRentalFilter([dailyRentalFilter[0], newValue]);
}; 
  return (
    <>   
   <div className="flex bg-gray-100 dark:bg-gray-900">
<Sidebar/>

    <div className="overflow-x-auto flex-grow bg-gray-100 dark:bg-gray-900 mx-auto py-4 sm:px-6 ">

      <div className="px-4 sm:px-0 flex flex-col sm:flex-row justify-between items-center bg-gray-100 dark:bg-gray-900">
      <h1 className=" mb-4 text-gray-900 tracking-tight dark:text-white text-3xl font-bold">{authState?.user?.data?.first_name}'s Equipments</h1>
        <input
    type="text"
    value={searchKeyword}
    className="block border border-grey-light p-3 rounded mb-4 "
    onChange={event =>{ setSearchKeyword(event.target.value);
    }}
    placeholder="Search equipments..."
  />
      </div>

      <div className="shadow p-5 rounded-lg bg-white dark:bg-gray-800">
 
 <div className="flex items-center justify-between">
   <p className="font-medium dark:text-white">
   Filters
   </p>

   {/* <button className="px-4 py-2 bg-gray-100 dark:bg-gray-500 hover:bg-gray-200 text-gray-800 dark:text-gray-100 text-sm font-medium rounded-md">
   Reset Filter
   </button> */}
 </div>

 <div>
   <div className="grid grid-cols-2 md:grid-cols-1 xl:grid-cols-2 gap-4 mt-4">
   <select value={selectedCategory} onChange={handleCategoryChange} className="px-4 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm">
   <option selected value=''>All Categories</option>
{ Categories?.map((Categories) =>   <option key={Categories.id} value={Categories.id}>{Categories.name}</option>) }
   </select>

   <select value={selectedManufacturer} onChange={handleManufacturerChange} className="px-4 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm">
   <option selected value="">All Manufacturers</option>
         { Companies?.map((Companies) =>   <option key={Companies.id} value={Companies.id}>{Companies.name}</option>) }

   </select>
   <div className="flex flex-wrap px-4 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm">

   <lable className="">Minimum Price:-    </lable>
   <input
         type="number"
         value={dailyRentalFilter[0]}
         min={0}
         onChange={handleMinInputChange}
         className="w-full px-1 -mx-2 py-2 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm"
       />
   </div>

   <div className="flex flex-wrap px-4 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm">

   <lable className="">Maximum Price:-    </lable>
   <input
        type="number"
        value={dailyRentalFilter[1]}
        onChange={handleMaxInputChange}
        max={25000}
         className="w-full px-1 -mx-2 py-2 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm"
       />
   </div>

   </div>
 </div>
 <div className="relative mt-4 px-2 py-3">
 <Range
 values={dailyRentalFilter}
 step={1}
 min={0}
 max={25000}
 onChange={(newValues) => setDailyRentalFilter(newValues)}
 renderTrack={({ props, children }) => (
   <div
     {...props}
     className="w-full h-2 bg-gray-300 rounded-full cursor-pointer bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
   >
     {children}
   </div>
 )}
 renderThumb={({ index, props }) => (
   <div
     {...props}
     className={`h-4 w-4 bg-blue-500 rounded-full outline-none shadow-md cursor-pointer ${index === 0 ? 'ml-0' : 'ml-2'}`}
   />
 )}
/>

   </div>
 </div>


    

      <h2 className="px-4 mb-4 text-gray-900 tracking-tight dark:text-white text-3xl font-bold">All Equipments</h2>

      <div className="mt-8">
        

        <div className="max-w-6xl flex flex-wrap mx-3">
        { (filteredEquipments?.length !== 0)?
    filteredEquipments?.slice(0, visibleItems)?.map((equipment) => (
      <EquipmentCard equipment={equipment}/>
)):
    <div className={`mt-4 flex p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800`} role="alert">
    <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
    <span className="sr-only">Alert</span>
    
    <div>
    Nothing To Show Here!!!!
    </div>
  </div>
}{(totalResults>visibleItems)?
  <div className='flex justify-center my-10'>
    <button onClick={() => setVisibleItems(visibleItems + itemsPerLoad)}>Load More</button>
  </div>:''}
        </div>
      </div>
    
      <h2 className="px-4 mb-4 text-gray-900 tracking-tight dark:text-white text-3xl font-bold">Active Equipments</h2>

<div className="mt-8">
  

  <div className="max-w-6xl flex flex-wrap mx-3">
  { (filteredEquipments.filter(
      (equipment) => ((equipment?.status !== "Pending") && (equipment?.status !== "Deleted"))
    )?.length !== 0)?
    filteredEquipments.filter(
      (equipment) => ((equipment?.status !== "Pending") && (equipment?.status !== "Deleted"))
    )?.slice(0, visibleItems)?.map((equipment) => <EquipmentCard equipment={equipment}/>):
    <div className={`mt-4 flex p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800`} role="alert">
    <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
    <span className="sr-only">Alert</span>
   
    <div>
    Nothing To Show Here!!!!
    </div>
  </div>
} {(totalResults>visibleItems)?
                    <div className='flex justify-center my-10'>
                      <button onClick={() => setVisibleItems(visibleItems + itemsPerLoad)}>Load More</button>
                    </div>:''}

  </div>
</div>
<h2 className="px-4 mb-4 text-gray-900 tracking-tight dark:text-white text-3xl font-bold">Rejected Equipments</h2>

<div className="mt-8">
  

  <div className="max-w-6xl flex flex-wrap mx-3">

  { (filteredEquipments.filter(
      (equipment) => ((equipment?.status === "Rejected"))
    )?.length !== 0)?
    filteredEquipments.filter(
      (equipment) => ((equipment?.status === "Rejected"))
    )?.slice(0, visibleItems)?.map((equipment) => <EquipmentCard equipment={equipment}/>):
    <div className={`mt-4 flex p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800`} role="alert">
    <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
    <span className="sr-only">Alert</span>
   
    <div>
    Nothing To Show Here!!!!
    </div>
  </div>
}
{(totalResults>visibleItems)?
                    <div className='flex justify-center my-10'>
                      <button onClick={() => setVisibleItems(visibleItems + itemsPerLoad)}>Load More</button>
                    </div>:''}

  </div>
</div>
<h2 className="px-4 mb-4 text-gray-900 tracking-tight dark:text-white text-3xl font-bold">Deleted Equipments</h2>

<div className="mt-8">
  

  <div className="max-w-6xl flex flex-wrap mx-3">
  { (filteredEquipments.filter(
      (equipment) => ((equipment?.status === "Deleted"))
    )?.length !== 0)?
    filteredEquipments.filter(
      (equipment) => ((equipment?.status === "Deleted"))
    )?.slice(0, visibleItems)?.map((equipment) => <EquipmentCard equipment={equipment}/>):
    <div className={`mt-4 flex p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800`} role="alert">
    <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
    <span className="sr-only">Alert</span>
    
    <div>
    Nothing To Show Here!!!!
    </div>
  </div>
}{(totalResults>visibleItems)?
                    <div className='flex justify-center my-10'>
                      <button onClick={() => setVisibleItems(visibleItems + itemsPerLoad)}>Load More</button>
                    </div>:''}

  </div>
</div>
  </div>
  </div>
    </>
  )
}

export default UserEquipment
