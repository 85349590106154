import React, { useState, useEffect } from 'react'
import { getCategoryEquipments } from '../../core/helper/coreapicalls';
import { useParams } from 'react-router-dom';
import EquipmentCard from '../Equipment/EquipmentCard';
import Pagination from '../Pagination';


const CategoryEquipments = () => {

  const params = useParams();
    const category_id =params.category_id;
    const category_name =params.category_name;
    const [Equipments, setEquipments] = useState([]);
    const [error, setError] = useState(false);
    const [totalResults, setTotalResults] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);
  
    const loadEquipments = () => {
    
      getCategoryEquipments(category_id,currentPage)
        .then((data) => {
          if (data?.error){
            setError(data?.error)
            console.log(error)
          }
          else{
            setEquipments(data?.results);
              setTotalResults(data?.count);}
        })
        };
    
    useEffect(() => {
      loadEquipments();
  },[currentPage]);
  
  const onPageChange = (page) => {
    setCurrentPage(page);
  };
  
    return (
      <>   
      <div className="px-4 py-8">
       <h1 className="text-3xl font-bold text-gray-800">Equipments From Category {category_name}</h1>
     </div>
     <div className="relative flex flex-wrap mx-3">
 
     { 
         (Equipments?.length!==0)?Equipments?.map((equipment) => <EquipmentCard equipment={equipment}/>):
         <div className={`mt-4 flex p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800`} role="alert">
    <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
    <span className="sr-only">Alert</span>
    <div>
    Nothing To Show Here!!!!
    </div>
  </div>
     }
 
  </div>
 <div className='flex justify-center my-10'>
     <Pagination
         currentPage={currentPage}
         totalItems={totalResults}
         itemsPerPage={8}
         onPageChange={onPageChange}
       />
 </div>
     </>

  )
}

export default CategoryEquipments


