import { isMatch } from "../../pages/Validation/Validators";
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { changePassword } from '../../auth/helper';
import Sidebar from '../../components/Sidebar/Sidebar';

const ChangePassword = () => {
  const tokenState = useSelector((state) => state.tokenReducer);
    const [error, setError]= useState({
      status:false,
      msg:"",
      type:""
  })
    const [password, setPassword] = useState({
      old_password:"",
      password:"",
      confirm_password:""
    });

  const ChangePassword = async (e) =>{
      
      e.preventDefault();
      if (!isMatch(password.password,password.confirm_password)) {
        setError({status:true, msg:"Password do not match", type:"red"})
        
      }
      if (!isMatch(password.old_password,password.confirm_password)) {
        setError({status:true, msg:"New Password Cannot be same as old password", type:"red"})
        
      }
     else{
     try{const data = await changePassword({
                  formData: password,
                  accessToken: tokenState.token.accessToken,
                });
                if(data?.success){
                  setError({status:true, msg:`${data?.message}`, type:"green"})
        
                }
  document.getElementById('change-password-form').reset()
  }
  catch(error) {
    if(!error?.success){
      setError({status:true, msg:"Incorrect Old Password", type:"red"})
          
      }
  }
  }
}
    return (
<div className="flex bg-gray-100 dark:bg-gray-900">
<Sidebar/>
<div className="flex-grow bg-gray-100 dark:bg-gray-900">


                  <form id="change-password-form" onSubmit={ChangePassword}>
          <div className="shadow sm:rounded-md sm:overflow-hidden ">
            <div className="bg-white py-6 px-4 space-y-6 sm:p-6 dark:bg-gray-800">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">Change your Password</h3>
              </div>

              <div className="grid grid-cols-6 gap-6">
            
                <div className="col-span-6">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-white">
                    Old Password
                  </label>
                  <input
                    type="password"
                    name="old_password"
                        placeholder="Old Password*" 
                        required={true}
                        autoComplete="on"
                        value={password.old_password}
                        onChange={(e) =>{setError({status:false}); setPassword({...password, old_password:e.target.value})}}
                    id="old_password"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-white">
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                        placeholder="Password*" 
                        required={true}
                        autoComplete="on"
                        value={password.password}
                        onChange={(e) => {setError({status:false}); setPassword({...password, password:e.target.value})}}
                    id="password"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-white">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    name="confirm_password"
                        placeholder="Confirm Password*" 
                        required={true}
                        autoComplete="on"
                        value={password.confirm_password}
                        onChange={(e) => setPassword({...password, confirm_password:e.target.value})}
                    id="confirm_password"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>


              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 dark:bg-gray-700">
              <button
                type="submit"
                className="w-full bg-green-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                Save Profile
              </button>
            </div>
          </div>
          </form>
          {error?.status? <div className={`bg-${error?.type}-200 mt-4 flex p-4 mb-4 text-sm text-${error?.type}-800 border border-${error?.type}-300 rounded-lg bg-${error?.type}-50 dark:bg-gray-800 dark:text-${error?.type}-400 dark:border-${error?.type}-800`} role="alert">
  <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
  <div>
  {error?.msg}
  </div>
</div>
 :''}
          </div>
          </div>

    )
  }
  
export default ChangePassword
