import React from 'react';
import logo from "../../src/img/logo3.png"
import values from "../../src/img/values.jpeg"
import developer from "../../src/img/creator.jpeg"
const AboutUs = () => {
  return (
    <>
      <section className="bg-white py-20 lg:py-[120px] overflow-hidden relative dark:bg-gray-900">
        <div className="container dark:text-white">
          <div className="flex flex-wrap lg:justify-between mx-4">
            <div className="w-full lg:w-1/2 xl:w-6/12 px-4">
              <div className="max-w-[570px] mb-12 lg:mb-0">
                <h2
                  className="
                  text-dark
                  mb-6
                  uppercase
                  font-bold
                  text-[32px]
                  sm:text-[40px]
                  lg:text-[36px]
                  xl:text-[40px]
                  "
                >
                  About Us
                </h2>
                <p className="text-base text-body-color leading-relaxed mb-9">
                  We are a leading Farming Equipment Rental company based in Teerthanker Mahaveer University, providing high-quality and affordable farm machinery to farmers and agribusinesses. Our mission is to provide exceptional service to our customers, help them increase productivity, and reduce downtime.
                </p>
              </div>
            </div>
            <div className="w-full lg:w-1/2 xl:w-5/12 px-4">
              <img
                src={logo}
                alt="About Us"
                className="w-full rounded-lg shadow-lg"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-green-600 py-10 lg:py-[60px] overflow-hidden relative z-10 dark:bg-gray-800">
        <div className="container dark:text-white">
          <div className="flex flex-wrap lg:justify-between mx-4">
            <div className="w-full lg:w-1/2 xl:w-6/12 px-4">
              <div className="max-w-[570px] mb-12 lg:mb-0">
                <h2
                  className="
                  text-white
                  mb-6
                  uppercase
                  font-bold
                  text-[32px]
                  sm:text-[40px]
                  lg:text-[36px]
                  xl:text-[40px]
                  "
                >
                  Our Values
                </h2>
                <p className="text-base text-white leading-relaxed mb-9">
                  At Kisan Saarthi, we believe in transparency, honesty, and hard work. We strive to provide our customers with the best possible service and equipment, and we are committed to building long-lasting relationships with them. We are passionate about agriculture and understand the importance of reliable farm machinery for a successful harvest. Our goal is to help farmers increase productivity and profitability while reducing the cost and environmental impact of farming operations.
                </p>
              </div>
            </div>
            <div className="w-full lg:w-1/2 xl:w-5/12 px-4">
              <img
                src={values}
                alt="Our Values"
                className="w-full rounded-lg shadow-lg"
              />
            </div>
          </div>
        </div>
      </section>

        <section className="bg-white py-20 lg:py-[120px] overflow-hidden relative z-10 dark:bg-gray-900">
        <div className="container dark:text-white">
          <div className="max-w-[760px] mx-auto px-4">
            <h2 className="text-dark text-[32px] sm:text-[40px] lg:text-[36px] xl:text-[40px] font-bold mb-8">
              Our Mission
            </h2>
            <p className="text-base text-body-color leading-relaxed mb-12">
              At Kisan Saarthi, our mission is to provide farmers and agriculture businesses with affordable and high-quality farming equipment rentals. We understand the challenges that farmers face when it comes to purchasing expensive equipment and maintaining it. That's why we offer a range of equipment rentals that cater to different needs and budgets. Our goal is to help farmers improve their productivity and profitability by providing them with reliable equipment and exceptional customer service.
            </p>
            <h2 className="text-dark text-[32px] sm:text-[40px] lg:text-[36px] xl:text-[40px] font-bold mb-8">Our Vision</h2>
      <p className="text-base text-body-color leading-relaxed mb-12">
        Our vision is to revolutionize the farming industry by providing modern and innovative solutions for farmers. We believe that by leveraging technology and making farming equipment more accessible, we can help farmers increase their yields and make their businesses more profitable.
        We aim to be the go-to source for all farming equipment needs, and to create a community of farmers who can share knowledge and best practices to improve the industry as a whole. We are committed to sustainability and reducing our impact on the environment, and we believe that by working together with our customers and partners, we can create a brighter future for agriculture.
      </p>
            <h2 className="text-dark text-[32px] sm:text-[40px] lg:text-[36px] xl:text-[40px] font-bold mb-8">
    Our Story
  </h2>
  <p className="text-base text-body-color leading-relaxed mb-12">
  Kisan Saarthi was founded in 2022 by Harsh Jain, a young developer who saw farmers  who are frustrated with the high costs of buying and maintaining farming equipment. He saw an opportunity to help other farmers by providing them with affordable equipment rentals, and so he started Kisan Saarthi from his College. Over the years, Kisan Saarthi has grown into a successful business, serving hundreds of farmers and agriculture businesses in the region. Today, Kisan Saarthi is known for its exceptional customer service and commitment to providing high-quality equipment rentals at competitive prices.
  </p>

  <h2 className="text-dark text-[32px] sm:text-[40px] lg:text-[36px] xl:text-[40px] font-bold mb-8">
    Our Team
  </h2>
  <p className="text-base text-body-color leading-relaxed mb-12">
    Our team at Kisan Saarthi is made up of experienced farmers and industry professionals who are passionate about agriculture and helping farmers succeed. We understand the challenges that farmers face on a daily basis, and we are committed to providing them with the best equipment rental solutions. Whether you need advice on which equipment to rent or help with maintenance and repairs, our team is here to assist you every step of the way.
  </p>

  <h2 className="text-dark text-[32px] sm:text-[40px] lg:text-[36px] xl:text-[40px] font-bold mb-8">
    Contact Us
  </h2>
  <p className="text-base text-body-color leading-relaxed mb-12">
    If you have any questions or concerns, please feel free to contact us. Our customer service team is available 24/7 to assist you with any queries you may have. You can reach us by phone, email or through our website's contact form. We look forward to hearing from you!
  </p>
  <div className="text-center">
    <a href="/contact" className="inline-block text-white py-3 rounded bg-green-600 hover:bg-green-800 focus:outline-none my-1 px-8">
      Contact Us
    </a>
  </div>
</div>
</div>
</section>

<section className="bg-gray-100 dark:bg-gray-900">
      <div className="container px-4 py-16 mx-auto dark:text-white">
        <div className="max-w-2xl mx-auto text-center mb-12">
          <h2 className="text-4xl font-bold text-gray-800 mb-4 dark:text-white">Our Developers</h2>
          <p className="text-gray-600 leading-relaxed dark:text-white">
            Our team of experienced developers is dedicated to building high-quality software that meets the unique needs of our clients. With a focus on innovation, efficiency, and collaboration, we are committed to delivering exceptional results on every project.
          </p>
        </div>
        <div className="flex flex-wrap justify-center ">
          <div className="max-w-sm rounded overflow-hidden shadow-lg m-4">
            <img className="w-full h-sm" src={developer} alt="Harsh Jain" />
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2 dark:text-white">Harsh Jain</div>
              <p className="text-gray-700 text-base dark:text-gray-400">
              Harsh is a full-stack developer and a student at Teerthanker Mahaveer University. He specializes in React and Node.js in Front-End, and Django at Back-End and is passionate about creating user-friendly, efficient applications that meet the needs of clients.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
</>
 );
};

export default AboutUs;