import React, { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import {isMatch } from "../Validation/Validators";
import { resetPassword } from '../../auth/helper';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { getLogoutAction, getRemoveTokenAction } from '../../redux/actions';

const ResetPassword = () => {
  const navigate= useNavigate()
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [error, setError]= useState({
    status:false,
    msg:"",
    type:""
})
const params = useParams();
  const uid =params.user_id;
  const token =params.reset_token;

const dispatch = useDispatch();

const resetPasswordUser = async (e) =>{
  e.preventDefault();
  if (!isMatch(password,confirm_password)) {
    setError({status:true, msg:"Password do not match", type:"red"})
    setTimeout(() => {
      setError({status:false});
            }, 2000);
  }
  else{
    const formData = new FormData();
    formData.append('password', password);
    formData.append('confirm_password', confirm_password);
   try{const data = await resetPassword(formData, uid, token);
              if(data?.success){
                setError({status:true, msg:"Password Changed Successfully. Redirecting to Login Page!!", type:"green"})
                Cookies.remove("access-token");
                Cookies.remove("refresh-token");
                Cookies.remove("uuid");
                Cookies.remove("user");
                Cookies.remove("is_admin");
                dispatch(getLogoutAction());
                dispatch(getRemoveTokenAction());
                setTimeout(() => {
                  navigate("/login");
                      }, 2000);
                      
              }
}
catch(error) {
    setError({
      status: true,
      msg: "Link has expired please use another link",
      type: 'red',
    });
}}}




  
  
    return (
      <>
<section className="bg-white dark:bg-gray-900 flex flex-col">
  <div className="container my-4 flex-1 flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-center text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Set New Password
              </h1>
              <form className="space-y-4 md:space-y-6" id="resetform" onSubmit={resetPasswordUser} >
                  <div>
                      <label htmlFor="password" 
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                      <input 
                      type="password" 
                      name="password"
                      className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                      required={true}                    
                      placeholder="Password"
                        autoComplete="on"
                      onChange={(e) =>{setError({status:false}); setPassword(e.target.value)}}
                      />
                  </div>
                  <div>
                      <label htmlFor="password" 
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm Password</label>
                      <input 
                      type="password" 
                      name="confirm_password"
                      placeholder="Confirm Password"
                      className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                      required={true}
                        autoComplete="on"
                      onChange={(e) =>{setError({status:false}); setConfirmPassword(e.target.value)}}
                      />
                  </div>
                  <button 
                  type="submit" 
                  className="w-full text-white bg-green-600  hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-400 font-medium rounded-lg text-sm px-5 py-3 my-1 text-center">
                    Reset Password</button>
                  <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                  Already have an account?&nbsp;
                  <Link className="font-medium text-blue-600 hover:underline dark:text-blue-500" to="/login">
                        Login 
                        </Link>
                        </p>
                  <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                  Don't have an account?&nbsp; 
                  <Link className="font-medium text-blue-600 hover:underline dark:text-blue-500" to="../signup/">
                  Sign Up
                    </Link> 
                  </p>
              </form>
              {error?.status? <div className={`mt-4 flex p-4 mb-4 text-sm text-${error?.type}-800 border border-${error?.type}-300 rounded-lg bg-${error?.type}-50 dark:bg-gray-800 dark:text-${error?.type}-400 dark:border-${error?.type}-800`} role="alert">
  <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
  <span className="sr-only">Alert</span>
  <div>
  {error?.msg}
  </div>
</div>
 :''}
          </div>
      </div>
  </div>
</section>
      </>
    )
  }
  

export default ResetPassword
