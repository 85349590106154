import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import * as Icon from 'react-bootstrap-icons';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './slider.css';
import tractorImage from '../../img/tractor.jpg';
import farmerImage from '../../img/farmer.jpg';
import fieldImage from '../../img/field.webp';
import home from '../../img/home.jpg'
import { useNavigate } from 'react-router-dom';
const Slider = () => {
  const customArrowStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  const customArrowClass = 'bg-gray-900 text-white rounded-full w-12 h-12 absolute top-1/2 transform -translate-y-1/2';
  const navigate = useNavigate();

  const renderCustomArrowPrev = (clickHandler, hasPrev, label) => (
    <button
      className={`${customArrowClass} left-0 z-10 ${!hasPrev ? 'opacity-50 cursor-not-allowed' : ''}`}
      onClick={clickHandler}
      disabled={!hasPrev}
      style={customArrowStyle}
    >
      <Icon.ArrowLeftCircleFill className="rounded-full w-12 h-12" style={{ fontSize: '20px', lineHeight: 1 }} />
    </button>
  );

  const renderCustomArrowNext = (clickHandler, hasNext, label) => (
    <button
      className={`${customArrowClass} right-0 ${!hasNext ? 'opacity-50 cursor-not-allowed' : ''}`}
      onClick={clickHandler}
      disabled={!hasNext}
      style={customArrowStyle}
    >
      <Icon.ArrowRightCircleFill className="rounded-full w-12 h-12" style={{ fontSize: '20px', lineHeight: 1 }} />
    </button>
  );

  return (
    <Carousel
      autoplay
      infiniteLoop
      showIndicators
      showThumbs={false}
      interval={1000}
      showArrows
      renderArrowNext={renderCustomArrowNext}
      renderArrowPrev={renderCustomArrowPrev}
    >
  <div className="relative">
          <div className="absolute w-full h-full bg-gradient-to-r from-green-600/10 to-green-600/10 bottom-0  z-20" />
          <img src={tractorImage} className="home1Img" />
          <div>
            <div className="flex flex-col justify-center -mt-60 wrapper">
              <p className="text-2xl font-normal text-white">
                Namaste, welcome to Kisan Saarthi.
              </p>
              <h1 className="text-4xl font-bold text-white">
                <span className="text-[#219653]">Farmer’s Eqipments</span> at
                reasonable <br /> and affordable prices.
              </h1>
              <p className="text-lg font-normal mt-2 mb-4 text-white">
                Start now with just one click.
              </p>
              <div className="flex justify-center items-center mx-auto">
                <button
                onClick={()=> navigate(`/equipments`)}
                  className="shadow-md mr-3 tooltip bg-[#219653] hover:bg-[#64a55a] text-white font-semibold text-lg w-[200px] py-2 px-2 rounded"
                >
                  Book Now
                </button>
              </div>
            </div>
          </div>
        </div>
        <img src={home} />


   
        <img src={fieldImage} alt="field" />
      
   
  
    </Carousel>
  );
};

export default Slider;
