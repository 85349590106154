import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import Sidebar from '../../components/Sidebar/Sidebar';
import { updateProfile} from '../../auth/helper/index';
import { City, State } from "country-state-city";
import Cookies from 'js-cookie';

const UpdateProfile = ({ onChange }) => {
  const authState = useSelector((state) => state.authReducer);
  const tokenState = useSelector((state) => state.tokenReducer);
  const user = authState.user.data;
  const [formData, setFormData] = useState({
    first_name: user.first_name,
    last_name: user.last_name,
    address: user.address,
    state: user.state,
    city: user.city,
    pin_code: parseInt(user.pin_code),
  });
  const selectedCountry = { label: "India", value: "IN" };

  
    const handleStateChange = (selectedOption) => {
      setFormData(
        {...formData,
          state: selectedOption.target.value,
        city:""});
    };

  const [error, setError]= useState({
    status:false,
    msg:"",
    type:""
  })
  useEffect(() => {
    if(authState.isLoggedIn){
    user && setFormData({
      ...formData, 
      first_name: user.first_name,
    last_name: user.last_name,
    address: user.address,
    state: user.state,
    city: user.city,
    pin_code: parseInt(user.pin_code),
    });}
        },[user, authState.isLoggedIn,])

const inputHandler = (event) => {
  setError({status:false});
  setFormData({
     ...formData,
     [event.target.name]:event.target.value
  })
};

const access = Cookies.get("access-token");
const submitHandler =async (event) =>{
  event.preventDefault();
  if(access){
    const data = await updateProfile({
      formData: formData,
      accessToken: tokenState.token.accessToken,
    });
    if (data.success) {
      setError({status:true, msg:"Profile Updated Successfully", type:"green"})
    
    }
  };

};

  return (

<div className="flex bg-gray-100 dark:bg-gray-900">
<Sidebar/>
<div className="flex-grow bg-gray-100 dark:bg-gray-900">


                  <form id="updateform" onSubmit={submitHandler}>
          <div className="shadow sm:rounded-md sm:overflow-hidden ">
            <div className="bg-white py-6 px-4 space-y-6 sm:p-6 dark:bg-gray-800">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">Update Personal Information</h3>
              </div>

              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 dark:text-white">
                    First name
                  </label>
                  <input
                  required={true}
                    type="text"
                    name="first_name"
                    id="first-name"
                    onChange={inputHandler} 
                    value={formData.first_name}
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 dark:text-white">
                    Last name
                  </label>
                  <input
                  value={formData.last_name}
                  type="text"
                  onChange={inputHandler}
                  name="last_name"
                    id="last-name"
                    autoComplete="family-name"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="email-address" className="block text-sm font-medium text-gray-700 dark:text-white">
                    Email address
                  </label>
                  <input
                    type="email"
                    disabled
                  value={authState.user.data.email}
                    name="email"
                    id="email-address"
                    autoComplete="email"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="phone_number" className="block text-sm font-medium text-gray-700 dark:text-white">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    disabled
                  value={authState.user.data.phone_number}
                    name="phone_number"
                    id="phone_number"
                    autoComplete="phone-number"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6">
                  <label htmlFor="street-address" className="block text-sm font-medium text-gray-700 dark:text-white">
                    Address
                  </label>
                  <input
                    type="text"
                    onChange={inputHandler}
                    value={formData.address}
                    name="address"
                    id="street-address"
                    autoComplete="street-address"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label htmlFor="region" className="block text-sm font-medium text-gray-700 dark:text-white">
                    State
                  </label>
                  <select
                  required={true}
                  id="state"
                    onChange={handleStateChange}
                    name="state"
                    value={formData.state}
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:focus:outline-none dark:focus:ring-indigo-500 dark:focus:border-indigo-500 sm:text-sm"
                  >
                    <option value="" selected>Select State</option>
                    {State.getStatesOfCountry(selectedCountry.value)?.map((state) => (
                    <option key={state.isoCode} value={state.isoCode}>{state.name}</option>))}
                  </select>
                </div>

                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label htmlFor="city" className="block text-sm font-medium text-gray-700 dark:text-white">
                    City
                  </label>
                  <select
                  required={true}
                  id="city"
                  isDisabled={!formData.state}
                    onChange={inputHandler}
                    name="city"
                    value={formData.city}
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:focus:outline-none dark:focus:ring-indigo-500 dark:focus:border-indigo-500 sm:text-sm"
                  >
                    <option selected>Select City</option>
                    {formData.state? City.getCitiesOfState(selectedCountry.value, formData.state)?.map((city) => (
                      <option key={city.name} value={city.name}>{city.name}</option>)): []}
                  </select>
                </div>
                
                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700 dark:text-white">
                    ZIP / Postal code
                  </label>
                  <input
                    required={true}
                    onChange={inputHandler}
                    type="number"
                    name="pin_code"
                    maxLength={6}
                    max={999999}
                    min={100000}
                    pattern="[1-9]{1}[0-9]{5}"
                    value={formData.pin_code}
                    id="postal-code"
                    autoComplete="postal-code"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 dark:bg-gray-700">
              <button
                type="submit"
                className="w-full bg-green-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                Save Profile
              </button>
            </div>
          </div>
          </form>
          {error?.status? <div className={`bg-${error?.type}-200 mt-4 flex p-4 mb-4 text-sm text-${error?.type}-800 border border-${error?.type}-300 rounded-lg bg-${error?.type}-50 dark:bg-gray-800  dark:text-${error?.type}-400 dark:border-${error?.type}-800`} role="alert">
  <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
  <div>
  {error?.msg}
  </div>
</div>
 :''}
          </div>
          </div>

  )
}
export default UpdateProfile
