import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {signin, verifyOTP } from '../auth/helper'
import { useDispatch } from 'react-redux'
import Cookies from "js-cookie";
import {
    getLoginAction,
    getSaveTokenAction,
    getSaveProfileAction
  } from "../redux/actions";
const Login = () => {
    const navigate=useNavigate();
    const[loginFormData, setLoginFormData] = useState({
        "email":'',
        "password":''
    });
const dispatch = useDispatch()
    const [error, setError]= useState({
        status:false,
        msg:"",
        type:""
    }) 
    const {email, password} =loginFormData
    const [showOTP, setShowOTP]= useState(false);
    const [otp, setOtp]= useState('');
    const inputHandler = (event) => {
      setError({status:false})
     setLoginFormData({
        ...loginFormData,
        [event.target.name]:event.target.value
     })
    };
  async function submitHandler(event) {

        event.preventDefault();

        try {
            const data = await signin({ email, password });

      if (data.success) {
          Cookies.set("access-token", data.data.tokens.access, {
            path: "/",
            expires: new Date().setDate(new Date().getDate() + 1)
          });
          Cookies.set("refresh-token", data.data.tokens.refresh, {
            path: "/",
            expires: new Date().setDate(new Date().getDate() + 1)
          });
          Cookies.set("uuid", data.data.uuid, {
            path: "/",
            expires: new Date().setDate(new Date().getDate() + 1)
          });
          Cookies.set("is_admin", data.data.is_admin, {
            path: "/",
            expires: new Date().setDate(new Date().getDate() + 1)
          });
          dispatch(getLoginAction());
          dispatch(
            getSaveTokenAction({
              accessToken: data.data.tokens.access,
              refreshToken: data.data.tokens.refresh
            })
            );
            dispatch(getSaveProfileAction(data));
            navigate("/dashboard");
          }
    
          return;
        } catch (err) {
          if(err?.success[0]==="False")
          {
              setError({status:true, msg:`${err?.message[0]}`, type:"red"})
              if(err?.message[0]==="Account has not been verified. Please check your Email for the OTP and verify your account"){
                setShowOTP(true);
              }
          }
          else if(err?.success==="False")
          {
              setError({status:true, msg:`${err?.message}`, type:"red"})
              
          }
          }}
          
          async function otpHandler(event) {
    
            event.preventDefault();
            try {
                const data = await verifyOTP({
                    email, 
                    otp});
            
                if(data.success)
                {  
                    document.getElementById('otpform').reset();
                    setError({status:true, msg:"Email Has been Verified Please login", type:"green"})
                    setShowOTP(false);
        } 
                else{
                }
            }
            catch(error) {
                if(error?.success==="False")
                {
                    setError({status:true, msg:`${error?.message}`, type:"red"})
    
                }
                
        }
            }        
    
    
    return (
      <>
      {(showOTP===false)?
        <section className="bg-white dark:bg-gray-900 flex flex-col">
        <div className="container my-4 flex-1 flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <h1 className="text-center text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                        Sign in to your account
                    </h1>
                    <form className="space-y-4 md:space-y-6" id="loginform" onSubmit={submitHandler} >
                        <div>
                            <label htmlFor="email" 
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                            <input 
                            type="email" 
                            name="email"
                            required={true} 
                            className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                            placeholder="Email*"
                            value={loginFormData.email}
                          onChange={inputHandler}
                            />
                        </div>
                        <div>
                            <label htmlFor="password" 
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                            <input 
                            type="password" 
                            name="password"
                            className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                            placeholder="••••••••" 
                            required={true}
                              autoComplete="on"
                            value={loginFormData.password}
                          onChange={inputHandler}
                            />
                        </div>
                        <button 
                        type="submit" 
                        className="w-full text-white bg-green-600  hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-400 font-medium rounded-lg text-sm px-5 py-3 my-1 text-center">Log In</button>
                        <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                        <Link className="font-medium text-blue-600 hover:underline dark:text-blue-500" to="/reset-password">
                              Forgot Password? 
                              </Link>
                              </p>
                        <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                        Don't have an account? 
                        <Link className="font-medium text-blue-600 hover:underline dark:text-blue-500" to="../signup/">
                        Sign Up
                          </Link> 
                        </p>
                    </form>
                    {error?.status? <div className={`mt-4 flex p-4 mb-4 text-sm text-${error?.type}-800 border border-${error?.type}-300 rounded-lg bg-${error?.type}-50 dark:bg-gray-800 dark:text-${error?.type}-400 dark:border-${error?.type}-800`} role="alert">
        <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
        <span className="sr-only">Alert</span>
        <div>
        {error?.msg}
        </div>
      </div>
       :''}
                </div>
            </div>
        </div>
      </section>:

        <section className="bg-white dark:bg-gray-900 flex flex-col">
    <div className="container my-4 flex-1 flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-center text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                    Verify Email 
                </h1>
                <p className="text-center text-gray-900 dark:text-white">
                    The OTP has been sent to email:-{email} 
                </p>
                <form className="space-y-4 md:space-y-6" id='otpform' onSubmit={otpHandler} >
                    <div>
                        <label for="otp" 
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">OTP</label>
                        <input 
                        name="otp"
                        className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        value={otp}
                        placeholder="OTP*" 
  
                              onChange={(e) => {
                                  setError({status:false});
                                  setOtp(e.target.value);
                              }}
                          required={true}
                          type="number"
                          pattern="[1-9]{1}[0-9]{5}"
                        />
                    </div>
                    <button 
                    type="submit" 
                    className="w-full text-white bg-green-600  hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-400 font-medium rounded-lg text-sm px-5 py-3 my-1 text-center">Verify Account</button>
                 <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                        Already have an account?
                        <Link className="font-medium text-blue-600 hover:underline dark:text-blue-500" to="../login/">
                              Log in
                          </Link> 
                        </p>
                </form>
                {error?.status? <div className={`bg-${error?.type}-200 mt-4 flex p-4 mb-4 text-sm text-${error?.type}-800 border border-${error?.type}-300 rounded-lg bg-${error?.type}-50 dark:bg-gray-800 dark:text-${error?.type}-400 dark:border-${error?.type}-800`} role="alert">
    <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
    <span className="sr-only">Alert</span>
    <div>
    {error?.msg}
    </div>
  </div>
   :''}
            </div>
        </div>
    </div>
  </section>
      }
  </>
)
}

export default Login
