import React, { useState, useEffect } from 'react'
import "../Slider/slider.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { StarIcon } from '@heroicons/react/solid'
import { Carousel} from "react-responsive-carousel";
import Datepicker from "react-tailwindcss-datepicker"; 
import "react-tailwindcss-datepicker/dist/index.esm.js";
import { addEquipmentReview, getEquipment } from '../../core/helper/coreapicalls';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from "js-cookie";
import { createBooking } from '../../auth/helper/bookingAPI';
import instance from '../../core/helper/config';
import * as Icon from 'react-bootstrap-icons';
import { CheckCircleIcon, XIcon } from '@heroicons/react/solid'
import { useSelector } from 'react-redux';
import axios from 'axios';
import { API } from '../../backend';
const EquipmentDetail = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate()- 1);
  const [visible, setVisible] = useState(false);
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [value, setValue] = useState({ 
    startDate: new Date(), 
    endDate: new Date()
    }); 
  const [selectedRating, setSelectedRating]= useState(0)
  const[review, setReview] = useState('')
  const authState = useSelector((state) => state.authReducer);
  const [error, setError]= useState({
    status:false,
    msg:"",
    type:""
})
const [alertC, showAlert] = useState({
  status:false,
  msg:"",
  type:""
})
  const [errorRating, setErrorRating]= useState({
    status:false,
    msg:"",
    type:""
})
const access_token = Cookies.get("access-token");
    const [equipment, setEquipment] = useState([]);
  const [invalidDate, setInvalidDate] = useState([]);
  const params = useParams();
  const navigate = useNavigate();
    const eq_id =params.equipment_id;
const loadEquipmentDetail = () => {

  getEquipment(eq_id)
  .then((data) => setEquipment(data))
};
useEffect(() => {
loadEquipmentDetail();
},[])


const handleSelect = (newValue) => {
  setError({status:false});
  setValue({ startDate: new Date(newValue.startDate), endDate: new Date(newValue.endDate) });
};

const renderCustomArrowPrev = (clickHandler, hasPrev, label) => {
  return (
    <button
        className={`bg-gray-900 text-white rounded-full w-12 h-12 absolute top-1/2 transform -translate-y-1/2 left-0 z-10 ${
          !hasPrev ? 'opacity-50 cursor-not-allowed' : ''
        }`}
        onClick={clickHandler} 
        disabled={!hasPrev} 
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
<Icon.ArrowLeftCircleFill className='rounded-full w-12 h-12' style={{ fontSize: "20px", lineHeight: 1 }}/>
      </button>
  
  );
};

const renderCustomArrowNext = (onClickHandler, hasNext, label) => {
  return (
    <button
        className={`bg-gray-900 text-white rounded-full w-12 h-12 absolute top-1/2 transform -translate-y-1/2 right-0 ${
          !hasNext ? 'opacity-50 cursor-not-allowed' : ''
        }`}
        onClick={onClickHandler} 
        disabled={!hasNext} 
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Icon.ArrowRightCircleFill className='rounded-full w-12 h-12' style={{ fontSize: "20px", lineHeight: 1 }} />
      </button>
   
  );
};

const start_year = value.startDate.getFullYear();
const start_month = String(value.startDate.getMonth() + 1).padStart(2, '0');
const start_day = String(value.startDate.getDate()).padStart(2, '0');
const formattedStartDate = `${start_year}-${start_month}-${start_day}`;

const end_year = value.endDate.getFullYear();
const end_month = String(value.endDate.getMonth() + 1).padStart(2, '0');
const end_day = String(value.endDate.getDate()).padStart(2, '0');
const formattedEndDate = `${end_year}-${end_month}-${end_day}`;


  
const handleBooking = async (event) => {
  event.preventDefault();
  try {
  const data  = await createBooking(equipment?.id, formattedStartDate, formattedEndDate, startTime, endTime);
    if(data?.success)
    {  
      navigate('/booking-history');
} 
if(!data?.success){
  setError({status:true, msg:`Slot not Available. Please choose another slot which contains valid dates`, type:"red"})
  
  
}
}
catch(error) {
  console.log(error);
  console.log(error?.success)
  if(error?.success==='False'){
    setError({status:true, msg:`${error?.message}`, type:"red"})
  }
  else{

    setError({status:true, msg:`${error[0]}`, type:"red"})
  }

}

  
}
useEffect(() => {
  const fetchInvalid = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get('access-token')}`
    };
    const { data } = await instance.get(`/booking/equip?equipment_id=${equipment?.id}`, { headers });
    const newArray = data?.map(item => ({ startDate: item.start_date, endDate: item.end_date }));
    setInvalidDate(newArray);
  };
  fetchInvalid();
}, [equipment]);
const redirect = () => {
  navigate("/login");
  return null;
}
const handleStartTimeChange = (event) => {
  setStartTime(event.target.value);
};

const handleEndTimeChange = (event) => {
  setEndTime(event.target.value);
};
const handleAccept = async (id, status) => {
  const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get('access-token')}`
  };
  const confirmed = window.confirm("Are you sure you want to delete the equipment?");
  if (confirmed) {
  await axios.put(`${API}/equipment/status/${id}/` , { status }, { headers });
  showAlert({status:true, msg:"Equipment has been Deleted", type:"red"})
navigate('/my-equipments');
}}

const handleReviewInput = (event) =>{
  
    event.preventDefault();
    if(selectedRating===0){
      setErrorRating({status:true, msg:"Please Select the rating first", type:"red"})

    }
    const reviewData = new FormData();
    reviewData.append('rating', selectedRating);
    reviewData.append('reviews', review);
    reviewData.append('equipment', equipment.id);
    
    addEquipmentReview(reviewData, access_token)
    .then(data =>{
        if(data?.success){
            setErrorRating({status:true, msg:`${data?.message}`, type:"green"})
            setSelectedRating(0);
            setReview('');
   
        }
    })
    .catch((e)=> console.log(e));
}   

const ratingList = equipment?.equipment_rating?.rating_list || [];
const sortedData = [...ratingList].sort((a, b) => new Date(b.add_time) - new Date(a.add_time))?.slice(0, 5);

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

    return (
<div className="bg-gray-100 dark:bg-gray-900">
{alertC.status && 
  <div className={`rounded-md bg-${alertC.type}-50 p-4`}>
  <div className="flex">
    <div className="flex-shrink-0">
      <CheckCircleIcon className={`h-5 w-5 text-${alertC.type}-400`} aria-hidden="true" />
    </div>
    <div className="ml-3">
      <p className="text-sm font-medium text-green-800">{alertC.msg}</p>
    </div>
    <div className="ml-auto pl-3">
      <div className="-mx-1.5 -my-1.5">
        <button
          type="button"   
          onClick={()=> showAlert({status:false})}

          className={`inline-flex bg-${alertC.type}-50 rounded-md p-1.5 text-${alertC.type}-500 hover:bg-${alertC.type}-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-${alertC.type}-50 focus:ring-${alertC.type}-600`}
        >
          <span className="sr-only">Dismiss</span>
          <XIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>
</div>
}
  <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 py-24 px-4 sm:px-6 sm:py-12 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
    <div className="items-center"> 
    <Carousel
                    autoplay={true}
                    infiniteLoop={true}
                    showStatus={false}
                    showIndicators={true}
                    showThumbs={false}
                    interval={2000}
                    showArrows={true}
                    stopOnHover={true}
                    renderArrowNext={renderCustomArrowNext}
                    renderArrowPrev={renderCustomArrowPrev}
                    
                >
                  
                  <Link to={equipment?.image_1}>
                    <div>
                        <img style={{ height: '300px', width: '800px', objectFit: 'contain'}} src={equipment?.image_1} alt='' />
                        </div></Link>
                        <Link to={equipment?.image_2}>
                    <div>
                        {equipment?.image_2 != null && <img style={{ height: '300px', width: '800px', objectFit: 'contain'}} src={equipment?.image_2} alt='' /> }
                    </div></Link>
                    <Link to={equipment?.image_3}>
                    <div>
                        {equipment?.image_3 != null && <img style={{ height: '300px', width: '800px', objectFit: 'contain'}} src={equipment?.image_3} alt='' /> }
                    </div></Link>
                    <Link to={equipment?.image_4}>
                    <div>
                        {equipment?.image_4 != null && <img style={{ height: '300px', width: '800px', objectFit: 'contain'}} src={equipment?.image_4} alt='' /> }
                    </div></Link>
                    <Link to={equipment?.image_5}>
                    <div>
                    {equipment?.image_5 != null && <img style={{ height: '300px', width: '800px', objectFit: 'contain'}} src={equipment?.image_5} alt='' /> }
                    </div></Link>

                </Carousel>
    </div>
    <div>
      <h2 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">{equipment?.title}</h2>
      <p className="mt-4 text-gray-500 ">{equipment?.description}</p>
      <h4 className='mt-4 text-xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-2xl'>Rs. {equipment?.daily_rental} per day</h4>
      <dl className="mt-8 grid grid-cols-1 gap-x-6 gap-y-5 sm:grid-cols-4 sm:gap-y-8 lg:gap-x-8">
        <div className="border-t border-gray-200 dark-border-gray-700 pt-4">
          <dt className="font-medium text-gray-900 dark:text-white">Category</dt>
          <dd className="mt-2 text-sm text-gray-500 ">{equipment?.category?.name}</dd>
        </div>

        <div className="border-t border-gray-200 pt-4">
          <dt className="font-medium text-gray-900 dark:text-white">Manufacturer</dt>
          <dd className="mt-2 text-sm text-gray-500">{equipment?.manufacturer?.name}</dd>
        </div>

        <div className="border-t border-gray-200 pt-4">
        <dt className="font-medium text-gray-900 dark:text-white">Model</dt>
          <dd className="mt-2 text-sm text-gray-500">{equipment?.model}</dd>
        </div>

        <div className="border-t border-gray-200 pt-4">
          <dt className="font-medium text-gray-900 dark:text-white">Manufacturing Year</dt>
          <dd className="mt-2 text-sm text-gray-500">{equipment?.manufacturing_year}</dd>
        </div>
        <div className="border-t border-gray-200 pt-4">
          <dt className="font-medium text-gray-900 dark:text-white">Equipment Location</dt>
          <dd className="mt-2 text-sm text-gray-500">{equipment?.equipment_location}</dd>
        </div>

        <div className="border-t border-gray-200 pt-4">
          <dt className="font-medium text-gray-900 dark:text-white">Height x Width</dt>
          <dd className="mt-2 text-sm text-gray-500">{equipment?.height}&quot; x {equipment?.width}</dd>
        </div>
        <div className="border-t border-gray-200 pt-4">
          <dt className="font-medium text-gray-900 dark:text-white">Condition</dt>
          <dd className="mt-2 text-sm text-gray-500">{equipment?.condition}</dd>
        </div>
      
        <div className="border-t border-gray-200 pt-4">
          <dt className="font-medium text-gray-900 dark:text-white">Horsepower</dt>
          <dd className="mt-2 text-sm text-gray-500">{equipment?.horsepower}</dd>
        </div>

      </dl>
      {!(authState?.user?.data?.uuid===equipment?.owner?.uuid)?
      <form onSubmit={handleBooking}>
      <div className='mt-2 grid gap-x-6 gap-y-2 sm:gap-y-2 lg:gap-x-8'>
                        <Datepicker 
                    value={value}
                    separator={"to"}
                    showShortcuts={true}
                    showFooter={true}
                    useRange={false}
                    disabledDates={invalidDate}
                    minDate={yesterday}
                    maxDate={equipment?.available_end_time}
                    primaryColor={"green"} 
                    displayFormat={"DD/MM/YYYY"} 
                    onChange={handleSelect}
/>

            <label className='text-gray-900 dark:text-white'>Start time:</label>
            <input type="time"
          onChange={handleStartTimeChange}
          value={startTime}
          disableClock={true}
          format={'HH:mm'}
          required={true}
          className="mx-4"
          />
           <label className='text-gray-900 dark:text-white'>End time:</label>
        <input type="time"
          onChange={handleEndTimeChange}
          value={endTime}
          required={true}
          disableClock={true}
          format={'HH:mm'}
        />
       
          <div>
        </div>
                        {
                            Cookies.get('access-token') ? (
                                <button type='submit' className="bg-green-500 hover:bg-[#8cdf80] text-white w-full font-semibold py-1 px-8 rounded">
                                    Book Now
                                </button>
                            ) : (
                                <button onClick={() => redirect()} className="bg-green-500 opacity-50 cursor-not-allowed hover:bg-[#8cdf80] text-white w-full font-semibold py-1 px-8 rounded">
                                    Book Now
                                </button>
                            )
                        }
                        
                        
                        
                </div>
                </form>  :
                <div className='mt-5 grid gap-x-6 gap-y-2 sm:gap-y-2 lg:gap-x-8'>
                <button onClick={()=> navigate(`/equipment-update/${equipment?.eq_id}/${equipment?.title}`)} className="bg-green-500 hover:bg-green-700 text-white w-full font-semibold py-1 px-8 rounded">
                                    Update Details
                                </button>      
                                {(equipment?.status!=="Deleted")?
                                  <button onClick={(e) => handleAccept(equipment?.eq_id, 'Deleted')} className="hover:bg-red-700 text-white bg-red-500 w-full font-bold py-1 px-8 rounded">
                                  Delete
                  </button>:   <div className="border-t border-gray-200 pt-4">
          <dt className="font-medium text-gray-900 dark:text-white">Status: {equipment?.status}</dt>
        </div>
                                }                                         
              
             </div>
                    }

                {error?.status? <div className={`bg-${error?.type}-200 mt-4 flex p-4 mb-4 text-sm text-${error?.type}-800 border border-${error?.type}-300 rounded-lg bg-${error?.type}-50 dark:bg-gray-800 dark:text-${error?.type}-400 dark:border-${error?.type}-800`} role="alert">
  <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
  <span className="sr-only">Alert</span>
  <div>
  {error?.msg}
  </div>
</div>
 :''}
            </div>
    </div>

      <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:py-32 lg:px-8 lg:grid lg:grid-cols-12 lg:gap-x-8">
        <div className="lg:col-span-4">
          <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white">Customer Reviews</h2>

          <div className="mt-3 flex items-center">
            <div>
              <div className="flex items-center">
                {[0, 1, 2, 3, 4].map((rating) => (
                  <StarIcon
                    key={rating}
                    className={classNames(
                      equipment?.equipment_rating?.average_rating > rating ? 'text-yellow-400' : 'text-gray-300',
                      'flex-shrink-0 h-5 w-5'
                    )}
                    aria-hidden="true"
                  />
                ))}
              </div>
              <p className="sr-only">{equipment?.equipment_rating?.average_rating} out of 5 stars</p>
            </div>
            <p className="ml-2 text-sm text-gray-900 dark:text-white">{equipment?.equipment_rating?.average_rating.toFixed(1)} out of 5 stars Based on {equipment?.equipment_rating?.total_reviews} reviews</p>
          </div>

          <div className="mt-6">
            <h3 className="sr-only">Review data</h3>

            <dl className="space-y-3">
              {equipment?.equipment_rating?.counts?.map((count) => (
                <div key={count.rating} className="flex items-center text-sm">
                  <dt className="flex-1 flex items-center">
                    <p className="w-3 font-medium text-gray-900 dark:text-white">
                      {count.rating}
                      <span className="sr-only"> star reviews</span>
                    </p>
                    <div aria-hidden="true" className="ml-1 flex-1 flex items-center">
                      <StarIcon
                        className={classNames(
                          count.count > 0 ? 'text-yellow-400' : 'text-gray-300',
                          'flex-shrink-0 h-5 w-5'
                        )}
                        aria-hidden="true"
                      />

                      <div className="ml-3 relative flex-1">
                        <div className="h-3 bg-gray-100 border border-gray-200 rounded-full" />
                        {count.count > 0 ? (
                          <div
                            className="absolute inset-y-0 bg-yellow-400 border border-yellow-400 rounded-full"
                            style={{ width: `calc(${count.count} / ${equipment?.equipment_rating?.total_reviews} * 100%)` }}
                          />
                        ) : null}
                      </div>
                    </div>
                  </dt>
                  <dd className="ml-3 w-10 text-right tabular-nums text-sm text-gray-900 dark:text-white">
                    {(count.count!==0)?Math.round((count.count / equipment?.equipment_rating?.total_reviews) * 100):0}%
                  </dd>
                </div>
              ))}
            </dl>
          </div>

          <div className="mt-10">
            <h3 className="text-lg font-medium text-gray-900 dark:text-white">Share your thoughts</h3>
            <p className="mt-1 text-sm text-gray-600 dark:text-gray-500">
              If you’ve used this product, share your thoughts with other customers
            </p>

            <button
              onClick={() => setIsFormOpen(true)}
              className="mt-6 inline-flex w-full bg-white border border-gray-300 rounded-md py-2 px-8 items-center justify-center text-sm font-medium text-gray-900 hover:bg-gray-50 sm:w-auto lg:w-full"
            >
              Write a review
            </button>
            {isFormOpen &&
    <form className="mt-6" onSubmit={handleReviewInput}>
      <label htmlFor="rating" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
        Rating
      </label>
      <div className="mt-1 flex items-center">
        {[1, 2, 3, 4, 5].map((rating) => (
          <StarIcon
            key={rating}
            className={classNames(
              selectedRating >= rating ? 'text-yellow-400' : 'text-gray-300',
              'h-5 w-5 cursor-pointer'
            )}
            aria-hidden="true"
            required={true}
            onClick={() => {setErrorRating({status:false});
              setSelectedRating(rating);}}
          />
        ))}
      </div>
      <label htmlFor="review" className="block mt-4 text-sm font-medium text-gray-700 dark:text-gray-200">
        Review
      </label>
      <textarea
        id="review"
        name="review"
        required
        rows={3}
        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-yellow-400 focus:ring focus:ring-yellow-400 focus:ring-opacity-50"
        onChange={(event)=> setReview(event.target.value)}
      />
      <div className="mt-4">
        <button
          type="submit"
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400"
        >
          Submit
        </button>
        <button
          onClick={()=> {setSelectedRating(0);
            setReview('');
            setErrorRating({status:false});
            setIsFormOpen(false);}}
          className="ml-4 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
        >
          Cancel
        </button>
      </div>
    </form>
  }
       {errorRating?.status? <div className={`bg-${errorRating?.type}-200 mt-4 flex p-4 mb-4 text-sm text-${errorRating?.type}-800 border border-${errorRating?.type}-300 rounded-lg bg-${errorRating?.type}-50 dark:bg-gray-800 dark:text-${errorRating?.type}-400 dark:border-${errorRating?.type}-800`} role="alert">
     <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
     <div>
     {errorRating?.msg}
     </div>
   </div>
    :''}
          </div>
        </div>

        <div className="mt-16 lg:mt-0 lg:col-start-6 lg:col-span-7">
          <h3 className="sr-only">Recent reviews</h3>

          <div className="flow-root">
            <div className="-my-12 divide-y divide-gray-200">
              {sortedData?.map((review) => (
                <div key={review.id} className="py-12">
                  <div className="flex items-center">
                    <img src={`${API}/${review?.user?.profile_picture}`} alt={`${review?.user?.first_name} ${review?.user?.last_name}.`} className="h-12 w-12 rounded-full" />
                    <div className="ml-4">
                      <h4 className="text-sm font-bold text-gray-900 dark:text-white">{review?.user?.first_name} {review?.user?.last_name}</h4>
                      <div className="mt-1 flex items-center">
                        {[0, 1, 2, 3, 4].map((rating) => (
                          <StarIcon
                            key={rating}
                            className={classNames(
                              review.rating > rating ? 'text-yellow-400' : 'text-gray-300',
                              'h-5 w-5 flex-shrink-0'
                            )}
                            aria-hidden="true"
                          />
                        ))}
                      </div>
                      <p className="sr-only">{review.rating} out of 5 stars</p>
                    </div>
                  </div>

                  <div
                    className="mt-4 space-y-6 text-base italic dakr:text-gray-500 text-gray-600"
                    dangerouslySetInnerHTML={{ __html: review.reviews }}
                  />
                </div>
              ))}
            </div>
            {ratingList?.length >5 &&
            <button
              onClick={() => navigate(`/equipment/reviews/${equipment?.eq_id}/${equipment?.title}`)}
              className="mt-6 inline-flex w-full bg-white border border-gray-300 rounded-md py-2 px-8 items-center justify-center text-sm font-medium dark:text-white text-gray-900 hover:bg-gray-50 sm:w-auto lg:w-full"
            >
              See all {ratingList?.length} Reviews
            </button>}
          </div>
        </div>
      </div>
  </div>


  )
}

export default EquipmentDetail
