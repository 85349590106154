import React, { useEffect, useState } from 'react'
// import './BookingRequest.css';
import pending from '../../img/Ellipse64.png';
import accepted from '../../img/Accepted.png';
import rejected from '../../img/Rejected.png';
import completed from '../../img/completed.png';
import progress from '../../img/Progress.png';
import { PDFDownloadLink} from '@react-pdf/renderer';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import { getBookingDetail} from "../../auth/helper/bookingAPI";
import { API } from '../../backend';
import { useSelector } from 'react-redux'
import { CheckCircleIcon, XIcon } from '@heroicons/react/solid'
import BookingInvoice from '../invoice';

const BookingRequest = () => {
  const [alertC, showAlert] = useState({
    status:false,
    msg:"",
    type:""
})
    const [output, setOutput] = useState(null);
    const [Data, setData] = useState([]);
    const [step, setStep] = useState(0);
    const authState = useSelector((state) => state.authReducer);

    const navigate = useNavigate();
    useEffect(() => {
        if(!Cookies.get('access-token')) {
            navigate('/login');
        }
    }, []);
    const params = useParams();
    async function BookingDetail() {
        const { data } = await getBookingDetail(params.id);
        setData(data);
        if(data?.status==="Pending"){
            setStep(0)
        }
        if(data?.status==="Accepted"){
            setStep(1)
        }
        if(data?.status==="Rejected"){
            setStep(1)
        }
        if(data?.status==="Cancelled"){
            setStep(1)
        }
        if(data?.status==="Inprogress"){
            setStep(2)
        }
        if(data?.status==="Completed"){
            setStep(3)
        }
        
      }
      function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }
      useEffect(() => {
        BookingDetail();
      }, []);

    const handleAccept = async (id, status) => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get('access-token')}`
        };
        await axios.patch(`${API}/booking/update/${id}/` , { status }, { headers });
        showAlert({status:true, msg:"Booking has been updated", type:"green"})
        BookingDetail();
    }
    const DownloadPdfButton = () => (
      <PDFDownloadLink document={<BookingInvoice Data={Data} />} fileName={`${Data?.booking_id}.pdf`}>
        {({ blob, url, loading, error }) =>
         <button className="hidden text-sm font-medium text-indigo-600 hover:text-indigo-500 sm:block">
         {loading ? 'Loading document...' : 'Download Invoice'}
       </button>
        }
      </PDFDownloadLink>
    );
    return (

<div className="bg-gray-100 dark:bg-gray-900">
      <main className="max-w-2xl mx-auto pt-8 pb-24 sm:pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="px-4 space-y-2 sm:px-0 sm:flex sm:items-baseline sm:justify-between sm:space-y-0">
          <div className="flex sm:items-baseline sm:space-x-4">
            <h1 className="text-2xl font-extrabold tracking-tight dark:text-white text-gray-900 sm:text-3xl">Booking #{Data?.booking_id}</h1>
            <DownloadPdfButton />
          </div>
          <p className="text-sm text-gray-600 dark:text-gray-400">
            Booking placed{' '}
            <time className="font-medium text-gray-900 dark:text-gray-400">
            {Data?.created_at?.slice(0, Data.created_at.indexOf('T'))}
            </time>
          </p>
        </div>

        {/* Products */}
        <section aria-labelledby="products-heading" className="mt-6">
          <h2 id="products-heading" className="sr-only">
            Products purchased
          </h2>

          <div className="space-y-8">
              <div className="bg-white dark:bg-gray-800 border-t border-b border-gray-200 dark:border-gray-700 shadow-sm sm:border sm:rounded-lg">
                <div className="py-6 px-4 sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:p-8">
                  <div className="sm:flex lg:col-span-7">
                    <div className="flex-shrink-0 w-full aspect-w-1 aspect-h-1 rounded-lg overflow-hidden sm:aspect-none sm:w-40 sm:h-40">
                      <img
                        src={Data?.equipment?.image_1}
                        alt={Data?.equipment?.title}
                        className="w-full h-full object-center object-cover sm:w-full sm:h-full"
                      />
                    </div>

                    <div className="mt-6 sm:mt-0 sm:ml-6">
                      <h3 className="text-base font-medium text-gray-900 dark:text-white">
                        <a href={`/equipment/${Data?.equipment?.eq_id}/${Data?.equipment?.title}`}>{Data?.equipment?.title}</a>
                      </h3>
                      <p className="mt-2 text-sm font-medium text-gray-900 dark:text-white">Rs {Data?.equipment?.daily_rental} / Per Day</p>
                      <p className="mt-3 text-sm text-gray-500 dark:text-gray-400">{Data?.equipment?.description}</p>
                      <p className="mt-3 text-sm text-gray-500 dark:text-gray-400">Manufacturer:- {Data?.equipment?.manufacturer?.name}</p>
                    </div>
                  </div>

                  <div className="mt-6 lg:mt-0 lg:col-span-5">
                    <dl className="grid grid-cols-2 gap-x-6 text-sm">
                      <div>
                        <dt className="font-medium text-gray-900 dark:text-white">Customer Details</dt>
                        <dd className="mt-3 text-gray-500 dark:text-gray-400">
                          <span className="block">Name:- {Data?.customer?.first_name} {Data?.customer?.last_name}</span>
                          <span className="block">Phone Number:- {Data?.customer?.phone_number}</span>
                          <span className="block">Email:- {Data?.customer?.email}</span>
                          <span className="block">Address:- {(Data?.customer?.address)?Data?.customer?.address:"Not Available"}</span>
                        </dd>
                      </div>
                      <div>
                        <dt className="font-medium text-gray-900 dark:text-white">Owner Details</dt>
                        <dd className="mt-3 text-gray-500 dark:text-gray-400">
                        <span className="block">Name:- {Data?.owner?.first_name} {Data?.owner?.last_name}</span>
                          <span className="block">Phone Number:- {Data?.equipment?.owner.phone_number}</span>
                          <span className="block">Address:- {(Data?.owner?.address)?Data?.owner?.address:"Not Available"}</span>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
                    {/* Stepper 
                    <div className="p-5 mb-10">
                        <div className="mx-14 p-4">
                        <div className="flex items-center">
                        <div className="flex items-center relative">
                            <div className="rounded-full transition duration-500 ease-in-out h-12 w-12">
                                <img src={pending} className="mb-10" width="100%" height="100%"></img>
                            </div>
                            <div className="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-teal-600">Pending</div>
                        </div>
                        {!(Data?.status==="Pending")?
                        <div className="flex-auto border-t-2 transition ease-in-out duration-500  linebg1"></div>:<div className="flex-auto linebgwhite"></div>
                        }
                      
                            
                                    {(Data?.status==="Pending")?
                                    <div className="flex items-center relative">
                                    <div className="rounded-full transition duration-500 ease-in-out h-12 w-12">
                                    <img src={accepted} className="mb-10" width="100%" height="100%"></img>
                                    </div>
                                    <div className="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-teal-600">Accepted/Rejected</div>
                                    </div>
                                    :(Data?.status==="Accepted" || Data?.status==="Inprogress" || Data?.status==="Completed")?
                                    <div className="flex items-center relative">
                                    <div className="rounded-full transition duration-500 ease-in-out h-12 w-12">
                                    <img src={accepted} className="mb-10" width="100%" height="100%"></img>
                                    </div>
                                    <div className="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-teal-600">Accepted</div>
                                    </div>:(Data?.status==="Rejected")?
                                    <div className="flex items-center text-white relative">
                                    <div className="rounded-full transition duration-500 ease-in-out h-12 w-12">
                                    <img src={rejected} className="mb-10" width="100%" height="100%"></img>
                                    </div>
                                    <div className="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-teal-600">Rejected</div>
                                </div>
                                :(Data?.status==="Cancelled")?
                                <div className="flex items-center text-white relative">
                                <div className="rounded-full transition duration-500 ease-in-out h-12 w-12">
                                <img src={rejected} className="mb-10" width="100%" height="100%"></img>
                                </div>
                                <div className="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-teal-600">Cancelled</div>
                            </div>:'' }
                            {(Data?.status==="Inprogress" || Data?.status==="Completed")?
                                    <div className="flex-auto border-t-2 transition duration-500 ease-in-out linebg2"></div>:<div className="flex-auto linebgwhite"></div>
                                }
                                {!(Data?.status==="Cancelled")?
                                    <div className="flex items-center text-white relative">
                                    <div className="rounded-full transition duration-500 ease-in-out h-12 w-12">
                                    <img src={progress} className="mb-10" width="100%" height="100%"></img>
                                    </div>
                                    <div className="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-teal-600">Inprogress</div>
                                </div>
                                :''
                            }
                            {(Data?.status==="Completed")?
                                <div className="flex-auto border-t-2 transition duration-500 ease-in-out linebg3"></div>:<div className="flex-auto linebgwhite"></div>
                                }
                                {!(Data?.status==="Cancelled")?
                                    <div className="flex items-center text-gray-500 relative">
                                    <div className="rounded-full transition duration-500 ease-in-out h-12 w-12">
                                    <img src={completed} className="mb-10" width="100%" height="100%"></img>
                                    </div>
                                    <div className="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-gray-500">Completed</div>
                                </div>
                                :''
                            }
                                </div>
                        </div>
                    </div>
                    */}
                    {/* Stepper New*/}
                <div className="border-t border-gray-200 dark:border-gray-700 py-6 px-4 sm:px-6 lg:p-8">
                  <h4 className="sr-only">Status</h4>
                  <p className="text-sm font-medium text-gray-900 dark:text-white">
                    Status:- {Data.status} 
                  </p>
                  <div className="mt-6" aria-hidden="true">
                    {(Data?.status==="Pending")?
                    <>
                    <div className="bg-gray-200 dark:bg-gray-600 rounded-full overflow-hidden">
                      <div
                        className="h-2 bg-indigo-600 rounded-full"
                        style={{ width: `calc((${step} * 2 + 1) / 8 * 100%)` }}
                      />
                    </div>
                    <div className="hidden sm:grid grid-cols-4 text-sm font-medium text-gray-600 mt-6">
                      <div className="text-indigo-600">Pending</div>
                      <div className={classNames(step > 0 ? 'text-indigo-600' : '', 'text-center')}>
                        Accepted/Rejected
                      </div>
                      <div className={classNames(step > 1 ? 'text-indigo-600' : '', 'text-center')}>
                        Inprogress
                      </div>
                      <div className={classNames(step > 2 ? 'text-indigo-600' : '', 'text-right')}>
                        Completed
                      </div>
                    </div>
                    </>:(Data?.status==="Accepted" || Data?.status==="Inprogress" || Data?.status==="Completed")?
                    <>
                    <div className="bg-gray-200 dark:bg-gray-600 rounded-full overflow-hidden">
                      <div
                        className="h-2 bg-indigo-600 rounded-full"
                        style={{ width: `calc((${step} * 2 + 1) / 8 * 100%)` }}
                      />
                    </div>
                    <div className="hidden sm:grid grid-cols-4 text-sm font-medium text-gray-600 mt-6">
                    <div className="text-indigo-600">Pending</div>
                    <div className={classNames(step > 0 ? 'text-indigo-600' : '', 'text-center')}>
                      Accepted
                    </div>
                    <div className={classNames(step > 1 ? 'text-indigo-600' : '', 'text-center')}>
                      Inprogress
                    </div>
                    <div className={classNames(step > 2 ? 'text-indigo-600' : '', 'text-right')}>
                      Completed
                    </div>
                  </div>
                    </>:(Data?.status==="Cancelled")?
                    <>
                    <div className="bg-gray-200 dark:bg-gray-600 rounded-full overflow-hidden">
                      <div
                        className="h-2 bg-indigo-600 rounded-full"
                        style={{ width: '100%' }}
                      />
                    </div>
                    <div className="hidden sm:grid grid-cols-4 text-sm font-medium text-gray-600 mt-6">
                  <div className="text-indigo-600">Pending</div>
                  <div className={classNames(step > 0 ? 'text-indigo-600' : '', 'text-center')}>
                    
                  </div>
                  <div className={classNames(step > 0 ? 'text-indigo-600' : '', 'text-center')}>
                    
                  </div>
                  <div className={classNames(step > 0 ? 'text-indigo-600' : '', 'text-right')}>
                    Cancelled
                  </div>
                </div>
                    </>:(Data?.status==="Rejected")?
                  <>
                       <div className="bg-gray-200 dark:bg-gray-600 rounded-full overflow-hidden">
                      <div
                        className="h-2 bg-indigo-600 rounded-full"
                        style={{ width: '100%' }}
                      />
                    </div>
                    <div className="hidden sm:grid grid-cols-4 text-sm font-medium text-gray-600 mt-6">
                  <div className="text-indigo-600">Pending</div>
                  <div className={classNames(step > 0 ? 'text-indigo-600' : '', 'text-center')}>
                    
                  </div>
                  <div className={classNames(step > 0 ? 'text-indigo-600' : '', 'text-center')}>
                    
                  </div>
                  <div className={classNames(step > 0 ? 'text-indigo-600' : '', 'text-right')}>
                    Rejected
                  </div>
                </div></>:''}
                  </div>
                </div>
              </div>
          </div>
        </section>
        {alertC.status && 
  <div className={`rounded-md bg-${alertC.type}-50 p-4`}>
  <div className="flex">
    <div className="flex-shrink-0">
      <CheckCircleIcon className={`h-5 w-5 text-${alertC.type}-400`} aria-hidden="true" />
    </div>
    <div className="ml-3">
      <p className="text-sm font-medium text-green-800">{alertC.msg}</p>
    </div>
    <div className="ml-auto pl-3">
      <div className="-mx-1.5 -my-1.5">
        <button
          type="button"
          onClick={()=> showAlert({status:false})}
          className={`inline-flex bg-${alertC.type}-50 rounded-md p-1.5 text-${alertC.type}-500 hover:bg-${alertC.type}-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-${alertC.type}-50 focus:ring-${alertC.type}-600`}
        >
          <span className="sr-only">Dismiss</span>
          <XIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>
</div>
} 
        {(Data?.owner?.email===authState?.user?.data?.email)?
                    (Data?.status==="Pending")?
                    <div className='flex justify-center my-5'>
                        <div className=''>
                            <h1 className='text-xl font-semibold text-gray-600 my-6'>Accept / Reject Booking</h1>
                            <div className='flex justify-between'>
                                <button onClick={(e) => handleAccept(params.id, 'Accepted')} className="-ml-10 mr-10 bg-[#F5F5FA] hover:bg-gray-200 text-green-400 font-bold py-1 px-8 rounded">
                                    Accept
                                </button>
                                <button onClick={(e) => handleAccept(params.id, 'Rejected')} className="ml-10 bg-[#F5F5FA] hover:bg-gray-200 text-red-400 font-bold py-1 px-8 rounded">
                                    Reject
                                </button>
                            </div>
                        </div>
                    </div>:(Data?.status==="Accepted")?
                   <div className='flex justify-center my-5'>
                   <div className=''>
                       <h1 className='text-xl font-semibold text-gray-600 my-6'>Inprogress / Cancel Booking</h1>
                       <div className='flex justify-between'>
                           <button onClick={(e) => handleAccept(params.id, 'Inprogress')} className="-ml-10 mr-10 bg-[#F5F5FA] hover:bg-gray-200 text-green-400 font-bold py-1 px-8 rounded">
                           Inprogress
                           </button>
                           <button onClick={(e) => handleAccept(params.id, 'Cancelled')} className="ml-10 bg-[#F5F5FA] hover:bg-gray-200 text-red-400 font-bold py-1 px-8 rounded">
                           Cancel
                           </button>
                       </div>
                   </div>
               </div>:(Data?.status==="Inprogress")?
               <div className='flex justify-center my-5'>
               <div className=''>
                   <h1 className='text-xl font-semibold text-gray-600 my-6'>Complete / Cancel Booking</h1>
                   <div className='flex justify-between'>
                       <button onClick={(e) => handleAccept(params.id, 'Completed')} className="-ml-10 mr-10 bg-[#F5F5FA] hover:bg-gray-200 text-green-400 font-bold py-1 px-8 rounded">
                       Complete
                       </button>
                       <button onClick={(e) => handleAccept(params.id, 'Cancelled')} className="ml-10 bg-[#F5F5FA] hover:bg-gray-200 text-red-400 font-bold py-1 px-8 rounded">
                       Cancel
                       </button>
                   </div>
               </div>
           </div>:'':(Data?.customer?.email===authState?.user?.data?.email)?
               !(Data?.status==="Cancelled")?
                <div className='flex justify-center my-5'>
                           <div className='my-6'>
                           <div className='flex justify-between'>
                               <button onClick={(e) => navigate(`/cancellation-form/${Data?.booking_id}`)} className=" bg-[#F5F5FA] hover:bg-gray-200 text-red-400 font-bold py-1 px-8 rounded">
                                   Wanna Cancel????
                               </button>
                           </div>
                       </div>
                        
                     </div>:
                (Data?.status==="Inprogress")?
                <div className='flex justify-center my-5'>
               <div className=''>
                   <h1 className='text-xl font-semibold text-gray-600 my-6'>Complete / Cancel Booking</h1>
                   <div className='flex justify-between'>
                       <button onClick={(e) => handleAccept(params.id, 'Completed')} className="-ml-10 mr-10 bg-[#F5F5FA] hover:bg-gray-200 text-green-400 font-bold py-1 px-8 rounded">
                       Complete
                       </button>
                       <button onClick={(e) => handleAccept(params.id, 'Cancelled')} className="ml-10 bg-[#F5F5FA] hover:bg-gray-200 text-red-400 font-bold py-1 px-8 rounded">
                       Cancel
                       </button>
                   </div>
               </div>
           </div>:'':''
                }
        {/* Billing */}
        <section aria-labelledby="summary-heading" className="mt-5">
          <h2 id="summary-heading" className="sr-only">
            Booking Summary
          </h2>

          <div className="bg-gray-200 dark:bg-gray-400 py-6 px-4 sm:px-6 sm:rounded-lg lg:px-8 lg:py-8 lg:grid lg:grid-cols-12 lg:gap-x-8">
            <dl className="grid grid-cols-2 gap-6 text-sm sm:grid-cols-2 md:gap-x-8 lg:col-span-7">
              <div>
                <dt className="font-medium text-gray-900 dark:text-black">Booking Summary</dt>
                <dd className="mt-3 text-gray-500 dark:text-gray-700">
                  <span className="block">Booking Id:-{Data?.booking_id}</span>
                  <span className="block">Request Status:-{Data.status}</span>
                  {(Data?.number_of_days===1)?
                   <span className="block">From/To Date:-
                                        {Data?.start_date}- {Data?.end_date}<br/>
                                        {Data?.start_time}- {Data?.end_time}</span>:
                                        <span className="block">From/To Date:-
                                        {Data?.start_date}- {Data?.end_date}
                                     </span>}
                </dd>
              </div>
            </dl>
            {(Data?.number_of_days===1)?
            <dl className="mt-8 divide-y divide-gray-200 dark:divide-gray-700 text-sm lg:mt-0 lg:col-span-5">
            <div className="pb-4 flex items-center justify-between">
              <dt className="text-gray-600 dark:text-gray-900">Total</dt>
              <dd className="font-medium text-gray-900">Rs {Data?.equipment?.hourly_rental} / hour</dd>
            </div>
            <div className="py-4 flex items-center justify-between">
              <dt className="text-gray-600 dark:text-gray-900">No. of days</dt>
              <dd className="font-medium text-gray-900">{Data?.number_of_days} days</dd>
            </div>
            <div className="py-4 flex items-center justify-between">
              <dt className="text-gray-600 dark:text-gray-900">No. of hours</dt>
              <dd className="font-medium text-gray-900">{Data?.total_hours?.toString().replace("-", "")} hours</dd>
            </div>
            <div className="pt-4 flex items-center justify-between">
              <dt className="font-medium text-gray-900">Sub Total</dt>
              <dd className="font-bold text-[#68AC5D] dark:text-indigo-600">Rs {Data?.total_hourly_rent?.toString().replace("-", "")}</dd>
            </div>
          </dl>
                        :
                        <dl className="mt-8 divide-y divide-gray-200 dark:divide-gray-700 text-sm lg:mt-0 lg:col-span-5">
                        <div className="pb-4 flex items-center justify-between">
                          <dt className="text-gray-600">Total</dt>
                          <dd className="font-medium text-gray-900">Rs {Data?.equipment?.daily_rental} / day</dd>
                        </div>
                        <div className="py-4 flex items-center justify-between">
                          <dt className="text-gray-600 dark:text-gray-900">No. of days</dt>
                          <dd className="font-medium text-gray-900">{Data?.number_of_days} days</dd>
                        </div>
                        <div className="pt-4 flex items-center justify-between">
                          <dt className="font-medium text-gray-900">Sub Total</dt>
                          <dd className="font-bold text-[#68AC5D] dark:text-indigo-600">Rs {Data?.total_daily_rent}</dd>
                        </div>
                      </dl>
                            }
            
          </div>
        </section>
      </main>
    </div>
      
    )
}

export default BookingRequest