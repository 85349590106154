
import React, { useState, useEffect } from 'react'
import Sidebar from '../components/Sidebar/Sidebar';
import { getEquipment,getUsersAll,getCompaniesAll,getCategoriesAll,updateEquipment } from '../core/helper/coreapicalls';
import Cookies from 'js-cookie';
import {IoMdSwap} from 'react-icons/io'
import Datepicker from "react-tailwindcss-datepicker"; 
import "react-tailwindcss-datepicker/dist/index.esm.js";
import { useNavigate, useParams } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';
import { MAP_BOX_API } from '../backend';
import { useSelector } from 'react-redux';

const EditEquipmentAdmin = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate()- 1);
  const [title, setTitle] = useState('');
  const [owner, setOwner] = useState('');
  const [manufacturer, setManufacturer] = useState('');
  const [category, setCategory] = useState('');
  const [equipment_location, setEquipment_location] = useState('');
  const [equipment_longitude, setEquipment_longitude] = useState();
  const [equipment_latitude, setEquipment_latitude] = useState();
  const [description, setDescription] = useState('');
  const [daily_rental, setDaily_rental] = useState('');
  const [hourly_rental, setHourly_rental] = useState('');
  const [image_1, setImage_1] = useState(null);
  const [image_2, setImage_2] = useState(null);
  const [image_3, setImage_3] = useState(null);
  const [image_4, setImage_4] = useState(null);
  const [image_5, setImage_5] = useState(null);
  const [oldImage_1, setOldImage_1] = useState(null);
  const [oldImage_2, setOldImage_2] = useState(null);
  const [oldImage_3, setOldImage_3] = useState(null);
  const [oldImage_4, setOldImage_4] = useState(null);
  const [oldImage_5, setOldImage_5] = useState(null);
  const [manufacturing_year, setManufacturing_year] = useState('');
  const [model, setModel] = useState('');
  const [condition, setCondition] = useState('');
  const [horsepower, setHorsepower] = useState('');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [is_available, setIs_available] = useState();
  mapboxgl.accessToken = 'pk.eyJ1IjoiYmxvZ2JvdHRsZSIsImEiOiJjbGdvcDBmdGIwYTRjM2VtdTVlMXMwNW0yIn0.Y8CdbbiM9RiNFWYpUWM9iA';
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const authState = useSelector((state) => state.authReducer);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const handleSearchInput = (e) => {
    const query = e.target.value;
    setQuery(query);
  };
  useEffect(() => {
    const performSearch = async () => {
      const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?country=IN&access_token=${mapboxgl.accessToken}`;
      const response = await fetch(url);
      const data = await response.json();
      const results = data.features?.map((feature) => ({
        name: feature.place_name,
        latitude: feature.center[1],
        longitude: feature.center[0],
      }));
      setResults(results);
    };

    performSearch();
  }, [query]);
  

  const [value, setValue] = useState({ 
    startDate: new Date(), 
    endDate: new Date()
    }); 
  const [show_phone_number, setShow_phone_number] = useState();
  const params = useParams();
  const eq_id =params.equipment_id;
  const [error, setError]= useState({
      status:false,
      msg:"",
      type:""
  })
  const navigate=useNavigate();
  const [Companies, setCompanies] = useState([])
  const [Categories, setCategories] = useState([])
  const loadEquipmentDetail = async () => {
        try {
          const data = await getEquipment(eq_id);
          const startDate = new Date(data.available_start_time);
          const endDate = new Date(data.available_end_time);
          setValue({ startDate, endDate });
          setTitle(data.title);
          setOwner(data.owner.id);
          setManufacturer(data.manufacturer.id);
          setCategory(data.category.id);
          setEquipment_location(data.equipment_location);
          setEquipment_longitude(data.equipment_longitude);
          setEquipment_latitude(data.equipment_latitude);
          setDescription(data.description);
          setDaily_rental(data.daily_rental);
          setHourly_rental(data.hourly_rental);
          setOldImage_1(data.image_1);
          setOldImage_2(data.image_2);
          setOldImage_3(data.image_3);
          setOldImage_4(data.image_4);
          setOldImage_5(data.image_5);
          setManufacturing_year(data.manufacturing_year);
          setModel(data.model);
          setCondition(data.condition);
          setHorsepower(data.horsepower);
          setWidth(data.width);
          setHeight(data.height);
          setIs_available(data.is_available);
          setShow_phone_number(data.show_phone_number);
        } catch (error) {
          // handle error here
        }
      }
      
  useEffect(() => {
    loadEquipmentDetail();
    },[]);
    const handleLocationSelect = (event) => {
      setSelectedLocation(event.target.value);
      const selectedOption = document.querySelector(`option[value="${event.target.value}"]`);
      if(selectedOption){
        const newLatitude = selectedOption.dataset.latitude;
        const newLongitude = selectedOption.dataset.longitude;
        setEquipment_latitude(newLatitude)
        setEquipment_longitude(newLongitude)
      }
 

    };
  
  

const loadCompanies = () => {

    getCompaniesAll()
    .then((data) => setCompanies(data))
  };
  
  useEffect(() => {
  loadCompanies();
  },[]);

  const loadCategories = () => {

      getCategoriesAll()
      .then((data) => setCategories(data))
    };
    
    useEffect(() => {
    loadCategories();
    },[]);
    const access_token= Cookies.get('access-token');
const [Users, setUsers] = useState([])
    const loadUsers = () => {
  
        getUsersAll(access_token)
        .then((data) => setUsers(data))
      };
      useEffect(() => {
        loadUsers();
        },[]);




const submitHandler = (event) =>{
    event.preventDefault();
    const start_year = value.startDate.getFullYear();
  const start_month = String(value.startDate.getMonth() + 1).padStart(2, '0');
  const start_day = String(value.startDate.getDate()).padStart(2, '0');
  const formattedStartDate = `${start_year}-${start_month}-${start_day}`;

  const end_year = value.endDate.getFullYear();
  const end_month = String(value.endDate.getMonth() + 1).padStart(2, '0');
  const end_day = String(value.endDate.getDate()).padStart(2, '0');
  const formattedEndDate = `${end_year}-${end_month}-${end_day}`;
  
    const equipmentData = new FormData();
    equipmentData.append('title', title);
    equipmentData.append('manufacturer', manufacturer);
    (authState?.user?.data?.is_admin)?equipmentData.append('owner', owner):equipmentData.append('owner', authState?.user?.data?.id);
    equipmentData.append('category', category);
    equipmentData.append('equipment_location', equipment_location);
    equipmentData.append('equipment_latitude', equipment_latitude);
    equipmentData.append('equipment_longitude', equipment_longitude);
    equipmentData.append('description', description);
    equipmentData.append('daily_rental', daily_rental);
    equipmentData.append('hourly_rental', hourly_rental);
    equipmentData.append('available_start_time', formattedStartDate);
    equipmentData.append('available_end_time', formattedEndDate);
    equipmentData.append('model', model);
    equipmentData.append('manufacturing_year', manufacturing_year);
    equipmentData.append('condition', condition);
    equipmentData.append('horsepower', horsepower);
    equipmentData.append('width', width);
    equipmentData.append('height', height);
    equipmentData.append('is_available', is_available);
    equipmentData.append('show_phone_number', show_phone_number);
    (image_1) && ( equipmentData.append('image_1', image_1));
    (image_2) && ( equipmentData.append('image_2', image_2));
    (image_3) && ( equipmentData.append('image_3', image_3));
    (image_4) && ( equipmentData.append('image_4', image_4));
    (image_5) && ( equipmentData.append('image_5', image_5));


    updateEquipment(equipmentData, access_token, eq_id)
    .then(data =>{
        if(data?.id){
            setError({status:true, msg:"Equipment has been Updated", type:"green"})
    
        }
    })
    .catch((e)=> console.log(e));
}   
const handleSelect = (newValue) => {
  setError({status:false});
  setValue({ startDate: new Date(newValue.startDate), endDate: new Date(newValue.endDate) });
};
  return (
    <>
<div className="flex bg-gray-100 dark:bg-gray-900">
<Sidebar/>
<div className="flex-grow bg-gray-100 dark:bg-gray-900">

<form id="productform" onSubmit={submitHandler}>
          <div className="shadow sm:rounded-md sm:overflow-hidden ">
            <div className="bg-white py-6 px-4 space-y-6 sm:p-6 dark:bg-gray-800">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">Edit Equipment</h3>
              </div>
      
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Title*
                  </label>
                  <input
                  required={true}
                    type="text"
                    name="title"
                    placeholder="Title*"
                    id="title"
                    value={title}
                        onChange={(event) =>{ setError({status:false});setTitle(event.target.value)}}
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                {(authState.user.data.is_admin)?
                <div className="col-span-6 sm:col-span-3">
                <label htmlFor="email-address" className="block text-sm font-medium text-gray-700 dark:text-white">
                Owner
                </label>
                <select
                required={true}
                id="owner"
                value={owner}
                onChange={(event) =>{ setError({status:false});setOwner(event.target.value)}}
                  name="owner"
                  className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:focus:outline-none dark:focus:ring-indigo-500 dark:focus:border-indigo-500 sm:text-sm"
                >
                  <option selected>Choose a Owner*</option>
                  { Users?.map((User) =>   <option key={User.uuid} value={User.id}>{User.email}</option>) }                  </select>
              </div>:
              <div className="col-span-6 sm:col-span-3">
              <label htmlFor="email-address" className="block text-sm font-medium text-gray-700 dark:text-white">
              Owner
              </label>
              <input
                type="email"
                disabled
                value={authState?.user?.data?.email}
              name="owner"
                id="email-address"
                className="mt-1 block w-full border border-gray-300 dark:border-gray-900 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>}
                

                

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="manufacturer" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Manufacturer
                  </label>
                  <select
                  required={true}
                  id="manufacturer"
                  value={manufacturer}
                  onChange={(event) =>{ setError({status:false});setManufacturer(event.target.value)}}
                  name="manufacturer"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:focus:outline-none dark:focus:ring-indigo-500 dark:focus:border-indigo-500 sm:text-sm"
                  >
                    <option selected>Choose a Manufacturer</option>
                  { Companies?.map((Companies) =>   <option key={Companies.id} value={Companies.id}>{Companies.name}</option>) }
                  </select>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="category" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Category
                  </label>
                  <select
                  required={true}
                  value={category}
                  id="category"
                  onChange={(event) =>{ setError({status:false});setCategory(event.target.value)}}
                  name="category"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:focus:outline-none dark:focus:ring-indigo-500 dark:focus:border-indigo-500 sm:text-sm"
                  >
                    <option selected>Choose a Category</option>
                    { Categories?.map((Categories) =>   <option key={Categories.id} value={Categories.id}>{Categories.name}</option>) }
                  </select>
                </div>

                <div className="col-span-6">
                  <label htmlFor="description" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Description
                  </label>
                  <textarea
                    type="text"
                    placeholder="Description"
                    required={true}
                    value={description}
                    onChange={(event) =>{ setError({status:false});setDescription(event.target.value)}}
                    name="description"
                    id="description"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="number" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Daily Rental
                  </label>
                  <input
                  value={daily_rental}
                  onChange={(event) =>{ setError({status:false});setDaily_rental(event.target.value)}}
                  type="number"
                  placeholder="Daily Rental*"
                  required={true}
                  name="daily_rental"
                    id="daily_rental"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="number" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Hourly Rental
                  </label>
                  <input
                    type="number"
                    placeholder="Hourly Rental*"
                    required={true} 
                    name="hourly_rental"
                    id="hourly_rental"
                    value={hourly_rental}
                    onChange={(event) =>{ setError({status:false});setHourly_rental(event.target.value)}}
                                        className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                
                <div className="col-span-6">
                  <label className="block text-sm font-medium text-gray-700">Image 1</label>
                  <div className="mt-1 flex items-center">
                    <input name="image_1"
                        id="default_size"
                      className="bg-white border border-gray-300 dark:border-gray-900 dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onChange={(event) =>{ setError({status:false});setImage_1(event.target.files[0])}}
                      type="file" accept="image/*" />
  {(oldImage_1)?
                  <span className="ml-5 inline-block bg-gray-100 rounded-lg overflow-hidden h-20 w-20">
                        <img
                        src={`${oldImage_1}`}
                        alt="Preview"
                        className='h-full w-full'
                        />
                        </span>
                        :''
                        }
                        {(image_1!==null && image_1!==undefined &&image_1!=='')?
                        <>
                        <IoMdSwap className="ml-5 w-16 h-16" width="24" height="24"/>
                  <span className="ml-5 inline-block bg-gray-100 rounded-lg overflow-hidden h-20 w-20">
                        <img
                        src={URL.createObjectURL(image_1)}
                        alt="Preview"
                        className='h-full w-full'
                        />
                        </span>
                        </>
                        :''
                        }
                  </div>
                </div>

                <div className="col-span-6">
                  <label className="block text-sm font-medium text-gray-700">Image 2</label>
                  <div className="mt-1 flex items-center">
                
                    <input name="image_2"
                        id="default_size"
                      className="bg-white border border-gray-300 dark:border-gray-900 dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onChange={(event) =>{ setError({status:false});setImage_2(event.target.files[0])}}
                      type="file" accept="image/*" />
                      {(oldImage_2)?
                  <span className="ml-5 inline-block bg-gray-100 rounded-lg overflow-hidden h-20 w-20">
                        <img
                        src={`${oldImage_2}`}
                        alt="Preview"
                        className='h-full w-full'
                        />
                        </span>
                        :''
                        }
                        {(image_2!==null && image_2!==undefined &&image_2!=='')?
                        <>
                        <IoMdSwap className="ml-5 w-16 h-16" width="24" height="24"/>
                  <span className="ml-5 inline-block bg-gray-100 rounded-lg overflow-hidden h-20 w-20">
                        <img
                        src={URL.createObjectURL(image_2)}
                        alt="Preview"
                        className='h-full w-full'
                        />
                        </span>
                        </>
                        :''
                        }
                  </div>
                </div>

                <div className="col-span-6">
                  <label className="block text-sm font-medium text-gray-700">Image 3</label>
                  <div className="mt-1 flex items-center">
                  
                    <input name="image_3"
                        id="default_size"
                      className="bg-white border border-gray-300 dark:border-gray-900 dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onChange={(event) =>{ setError({status:false});setImage_3(event.target.files[0])}}
                      type="file" accept="image/*" />
                      {(oldImage_3)?
                  <span className="ml-5 inline-block bg-gray-100 rounded-lg overflow-hidden h-20 w-20">
                        <img
                        src={`${oldImage_3}`}
                        alt="Preview"
                        className='h-full w-full'
                        />
                        </span>
                        :''
                        }
                        {(image_3!==null && image_3!==undefined &&image_3!=='')?
                        <>
                        <IoMdSwap className="ml-5 w-16 h-16" width="24" height="24"/>
                  <span className="ml-5 inline-block bg-gray-100 rounded-lg overflow-hidden h-20 w-20">
                        <img
                        src={URL.createObjectURL(image_3)}
                        alt="Preview"
                        className='h-full w-full'
                        />
                        </span>
                        </>
                        :''
                        }
                  </div>
                </div>

                <div className="col-span-6">
                  <label className="block text-sm font-medium text-gray-700">Image 4</label>
                  <div className="mt-1 flex items-center">
                    <input name="image_4"
                        id="default_size"
                      className="bg-white border border-gray-300 dark:border-gray-900 dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onChange={(event) =>{ setError({status:false});setImage_4(event.target.files[0])}}
                      type="file" accept="image/*" />
                      {(oldImage_4)?
                  <span className="ml-5 inline-block bg-gray-100 rounded-lg overflow-hidden h-20 w-20">
                        <img
                        src={`${oldImage_4}`}
                        alt="Preview"
                        className='h-full w-full'
                        />
                        </span>
                        :''
                        }
                        {(image_4!==null && image_4!==undefined &&image_4!=='')?
                        <>
                        <IoMdSwap className="ml-5 w-16 h-16" width="24" height="24"/>
                  <span className="ml-5 inline-block bg-gray-100 rounded-lg overflow-hidden h-20 w-20">
                        <img
                        src={URL.createObjectURL(image_4)}
                        alt="Preview"
                        className='h-full w-full'
                        />
                        </span>
                        </>
                        :''
                        }
                  </div>
                </div>

                <div className="col-span-6">
                  <label className="block text-sm font-medium text-gray-700">Image 5</label>
                  <div className="mt-1 flex items-center">
                  
                    <input name="image_5"
                        id="default_size"
                      className="bg-white border border-gray-300 dark:border-gray-900 dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onChange={(event) =>{ setError({status:false});setImage_5(event.target.files[0])}}
                      type="file" accept="image/*" />
                      {(oldImage_5)?
                  <span className="ml-5 inline-block bg-gray-100 rounded-lg overflow-hidden h-20 w-20">
                        <img
                        src={`${oldImage_5}`}
                        alt="Preview"
                        className='h-full w-full'
                        />
                        </span>
                        :''
                        }
                        {(image_5!==null && image_5!==undefined &&image_5!=='')?
                        <>
                        <IoMdSwap className="ml-5 w-16 h-16" width="24" height="24"/>
                  <span className="ml-5 inline-block bg-gray-100 rounded-lg overflow-hidden h-20 w-20">
                        <img
                        src={URL.createObjectURL(image_5)}
                        alt="Preview"
                        className='h-full w-full'
                        />
                        </span>
                        </>
                        :''
                        }
                  </div>
                </div>
                
                <div className="col-span-6 sm:col-span-6">
                  <label htmlFor="city" className="block text-sm font-medium text-gray-700 dark:text-white">
                    Select Available dates
                  </label>
                  <Datepicker 
                    value={value}
                    separator={"to"}
                    showShortcuts={true}
                    showFooter={true}
                    useRange={false}
                    minDate={yesterday}
                    primaryColor={"green"} 
                    displayFormat={"DD/MM/YYYY"} 
                    onChange={handleSelect}
/>
                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label htmlFor="text" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Equipment Location
                  </label>
                  <input
      type="text"
      value={equipment_location}
      onChange={(e)=>{handleSearchInput(e);
        setError({status:false});
        setEquipment_location(e.target.value);}}
      className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:focus:outline-none dark:focus:ring-indigo-500 dark:focus:border-indigo-500 sm:text-sm"
      list="locations"
      name="equipment_location"
      required={true}
      onSelect={handleLocationSelect}
    />
    <datalist id="locations">
      {results?.map((result) => (
        <option
          key={`${result.latitude},${result.longitude}`}
          value={result.name}
          data-longitude={result.longitude}
          data-latitude={result.latitude}
        >
          {result.name}
        </option>
      ))}
    </datalist>
    {selectedLocation && <p>Selected location: {selectedLocation}</p>}
                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label htmlFor="number" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Manufacturing Year
                  </label>
                  <input
                  required={true}
                  onChange={(event) =>{ setError({status:false});setManufacturing_year(event.target.value)}}
             value={manufacturing_year}
                    type="number"
                    name="manufacturing_year" 
                    placeholder="Manufacturing Year*"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label htmlFor="text" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Model
                  </label>
                  <input
                  required={true}
                  onChange={(event) =>{ setError({status:false});setModel(event.target.value)}}
                  value={model}                    type="text"
                    placeholder="Model*"
                    name="model" 
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
    
                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label htmlFor="condition" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Condition
                  </label>
                  <select
                  required={true}
                  id="condition"
                  value={condition}
                  onChange={(event) =>{ setError({status:false});setCondition(event.target.value)}}
                    name="condition"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:focus:outline-none dark:focus:ring-indigo-500 dark:focus:border-indigo-500 sm:text-sm"
                  >
                      <option selected>Choose Condition</option>
                      <option value="New">New</option>
                      <option value="Used">Used</option>
                  </select>       
                </div>
                         
                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label htmlFor="number" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Horsepower
                  </label>
                  <input
                  required={true}
                    type="number"
                    placeholder="Horsepower*"
                        name="horsepower" 
                        onChange={(event) =>{ setError({status:false});setHorsepower(event.target.value)}}
                        value={horsepower}                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label htmlFor="number" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Width
                  </label>
                  <input
                  required={true}
                  onChange={(event) =>{ setError({status:false});setWidth(event.target.value)}}
                  value={width}                    type="number"
                    placeholder="Width*"
                    name="width" 
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                
                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label htmlFor="number" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Height
                  </label>
                  <input
                  required={true}
                  onChange={(event) =>{ setError({status:false});setHeight(event.target.value)}}
                  value={height}                    type="number"
                    placeholder="Height*"
                        name="height" 
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <div className="flex items-start">
                  <input
                  name="is_available"
                        type="checkbox"
                        
                        onChange={(e) => {
                          setIs_available(e.target.checked)
                        }}
                         checked={is_available}                    className="h-4 w-4 flex border-gray-300 items-center dark:border-gray-900 rounded py-2 px-3 text-indigo-600 focus:ring-indigo-500"
                  />  
                   <div className="ml-3 text-sm">
                        <label htmlFor="is_available" className="font-medium text-gray-700 dark:text-white">
                        Is Available
                        </label>
                        <p className="text-gray-500 dark:text-gray-400">Make it visible and available for booking.</p>
                      </div>
                </div>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <div className="flex items-start">
                  <input
                  name="show_phone_number" 
                        type="checkbox"
                        onChange={(e) => {
                          setShow_phone_number(e.target.checked)
                      }}
                       checked={show_phone_number}                    className="h-4 w-4 border-gray-300 flex items-center dark:border-gray-900 rounded py-2 px-3 text-indigo-600 focus:ring-indigo-500"
                  />  
                   <div className="ml-3 text-sm">
                        <label htmlFor="show_phone_number" className="font-medium text-gray-700 dark:text-white">
                        Show Phone Number
                        </label>
                        <p className="text-gray-500 dark:text-gray-400">If checked then your phone number will be visible in booking details to user</p>
                      </div>
                </div>
                </div>

              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 dark:bg-gray-700">
              <button
                type="submit"
                className="w-full bg-green-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                Save Equipment
              </button>
            </div>
          </div>
          </form>
          {error?.status? <div className={`bg-${error?.type}-200 mt-4 flex p-4 mb-4 text-sm text-${error?.type}-800 border border-${error?.type}-300 rounded-lg bg-${error?.type}-50 dark:bg-gray-800 dark:text-${error?.type}-400 dark:border-${error?.type}-800`} role="alert">
  <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
  <span className="sr-only">Alert</span>
  <div>
  {error?.msg}
      </div>
      </div>
 :''}
</div>
   </div>










   </>
  )
  }
export default EditEquipmentAdmin
