import React from 'react'
import { useNavigate } from 'react-router-dom';

const BookingRow = (props) => {
    const navigate = useNavigate();
  return (
    <tr key={props?.booking?.eq_id}>
    <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
        <div className="inline-flex items-center gap-x-3">
            <div className="flex items-center gap-x-2">                
                    <h2 className="font-medium text-gray-800 dark:text-white ">{props?.booking?.booking_id}</h2>
            </div>
        </div>
    </td>
    <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{props?.booking?.customer?.email}</td>

    
    <td className="px-4 py-4 text-sm text-gray-600 dark:text-gray-300 whitespace-nowrap">{props?.booking?.equipment?.eq_id}</td>
    <td className="px-4 py-4 text-sm text-gray-600 dark:text-gray-300 whitespace-nowrap">{props?.booking?.created_at?.slice(0, props?.booking?.created_at.indexOf('T'))}</td>
    {(props?.booking?.number_of_days===1)?
        <td className="px-4 py-4 text-sm text-gray-600 dark:text-gray-300 whitespace-nowrap">Rs {props?.booking?.total_hourly_rent?.toString().replace("-", "")}
        </td>:
        <td className="px-4 py-4 text-sm text-gray-600 dark:text-gray-300 whitespace-nowrap">Rs {props?.booking?.total_daily_rent}
        </td>
        }
    
    
    <td className="px-4 py-4 text-sm whitespace-nowrap">
        <div className="flex items-center gap-x-6">
          
            <button onClick={()=> navigate(`/booking-update/${props?.booking?.booking_id}`)} className="text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                </svg>
            </button>
        </div>
    </td>
</tr>
  )
}

export default BookingRow
