import React from 'react'
import { useState, useEffect } from 'react'
import CategoryCard from '../components/Category/CategoryCard'
import { getCategoriesAll } from '../core/helper/coreapicalls';

const Categories = () => {
  const [Categories, setCategories] = useState([])
  const [totalResults, setTotalResults] = useState(0)
  const [visibleItems, setVisibleItems] = useState(10);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);
  const itemsPerLoad = 10;
const loadCategories = () => {
  
  getCategoriesAll()
  .then((data) => {
    setCategories(data);
    setTotalResults(data?.length);}
)
};

useEffect(() => {
loadCategories();
},[]);
useEffect(() => {
  let filtered = Categories

  if(searchKeyword){
      filtered = filtered.filter(
          (category) => category.name.toLowerCase().includes(searchKeyword.toLowerCase())
        );
  
  }
  
  setFilteredCategories(filtered);
}, [visibleItems, Categories, filteredCategories,searchKeyword]);


  return (
    <div className='bg-gray-100 dark:bg-gray-900'>   
    <div className="px-4 py-8 flex flex-col sm:flex-row justify-between items-center bg-gray-100 dark:bg-gray-900">
      <h1 className="mb-4 text-gray-900 tracking-tight dark:text-white text-3xl font-bold">All Categories</h1>
      <input
    type="text"
    value={searchKeyword}
    className="block border border-grey-light p-3 rounded mb-4"
    onChange={event =>{ setSearchKeyword(event.target.value);
    }}
    placeholder="Search category..."
  />
    </div>
    <div className="relative flex flex-wrap mx-3 ">

    { (filteredCategories?.length!==0)?filteredCategories?.slice(0, visibleItems)?.map((Categories) =>  <CategoryCard category={Categories}/>):
    <div className={`mt-4 flex p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800`} role="alert">
    <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
    <span className="sr-only">Alert</span>
    <div>
    Nothing To Show Here!!!!
    </div>
  </div> }
 </div>
 {(totalResults>visibleItems)?
                    <div className='flex justify-center my-10'>
                      <button onClick={() => setVisibleItems(visibleItems + itemsPerLoad)}>Load More</button>
                    </div>:''}
</div>
  )
}

export default Categories
