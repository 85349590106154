import {API} from '../../backend'

export const getCategoriesAll = () => {
    return fetch(`${API}/category/`, {method:"GET"})
    .then((response) => response.json())
    .catch((err) => console.log(err))
}

export const getUsersAll = (accessToken) => {
    return fetch(`${API}/users/`,{
        method: "GET",
        headers:{
            "Authorization": `Bearer ${accessToken}`
        
        }})
    .then((response) => response.json())
    .catch(err => console.log(err));
}

export const getCompaniesAll = () => {
    return fetch(`${API}/company/`,{method: "GET"})
    .then((response) => response.json())
    .catch(err => console.log(err));
}


export const getEquipmentsAll = () => {
    return fetch(`${API}/equipment`,{method: "GET"})
    .then((response) => response.json())
    .catch(err => console.log(err));
}



export const getCategories = (page) => {
    return fetch(`${API}/category/?page=${page}`, {method:"GET"})
    .then((response) => response.json())
    .catch((err) => console.log(err))
}

export const getUsers = (accessToken, page) => {
    return fetch(`${API}/users/?page=${page}`,{
        method: "GET",
        headers:{
            "Authorization": `Bearer ${accessToken}`
        
        }})
    .then((response) => response.json())
    .catch(err => console.log(err));
}

export const getCompanies = (page) => {
    return fetch(`${API}/company/?page=${page}`,{method: "GET"})
    .then((response) => response.json())
    .catch(err => console.log(err));
}

export const getEquipment = (eq_id) => {
    return fetch(`${API}/equipment/detail/${eq_id}/`, {method:"GET"})
    .then((response) => response.json())
    .catch((err) => console.log(err))
}

export const addEquipment = (equipment, accessToken) => {
    return fetch(`${API}/equipment/create/`,{
        method: "POST",
        headers:{
            "Authorization": `Bearer ${accessToken}`
        },
        body: equipment
    })
    .then((response) => {
        return response.json();
    })
    .catch(err => console.log(err));
};
export const updateEquipment = (equipment, accessToken,eq_id) => {
    return fetch(`${API}/equipment/update/${eq_id}/`,{
        method: "PUT",
        headers:{
            "Authorization": `Bearer ${accessToken}`
        },
        body: equipment
    })
    .then((response) => {
        return response.json();
    })
    .catch(err => console.log(err));
};

export const addCategory = (category, accessToken) => {
    return fetch(`${API}/category/create/`,{
        method: "POST",
        headers:{
            "Authorization": `Bearer ${accessToken}`
        
        },
        body: category
    })
    .then((response) => {
        return response.json();
    })
    .catch(err => console.log(err));
};

export const getCategory = (id, accessToken) => {
    return fetch(`${API}/category/detail/${id}`, {
        method:"GET",
        headers:{
            "Authorization": `Bearer ${accessToken}`
        },})
    .then((response) => response.json())
    .catch((err) => console.log(err))
}

export const updateCategory = (category, accessToken,id) => {
    return fetch(`${API}/category/update/${id}/`,{
        method: "PUT",
        headers:{
            "Authorization": `Bearer ${accessToken}`
        },
        body: category
    })
    .then((response) => {
        return response.json();
    })
    .catch(err => console.log(err));
};

export const addCompany = (company, accessToken) => {
    return fetch(`${API}/company/create/`,{
        method: "POST",
        headers:{
            "Content-Type":"application/json",
            "Authorization": `Bearer ${accessToken}`
        
        },
        body: JSON.stringify(company)
    })
    .then((response) => {
        return response.json();
    })
    .catch(err => console.log(err));
};

export const getCompany = (company_id, accessToken) => {
    return fetch(`${API}/company/detail/${company_id}`, {
        method:"GET",
        headers:{
            "Authorization": `Bearer ${accessToken}`
        },})
    .then((response) => response.json())
    .catch((err) => console.log(err))
}


export const updateCompany = (company, accessToken,company_id) => {
    return fetch(`${API}/company/update/${company_id}/`,{
        method: "PUT",
        headers:{
            "Authorization": `Bearer ${accessToken}`
        },
        body: company
    })
    .then((response) => {
        return response.json();
    })
    .catch(err => console.log(err));
};

export const deleteEquipment = (eq_id, accessToken) => {
    return fetch(`${API}/equipment/delete/${eq_id}`,{
        method: "DELETE",
        headers:{
            "Authorization": `Bearer ${accessToken}`
        
        },
    })
    .then((response) => {
        return response.json();
    })
    .catch(err => console.log(err));
};
export const deleteCategory = (category_id,accessToken) => {
    return fetch(`${API}/category/delete/${category_id}`,{
        method: "DELETE",
        headers:{
            "Authorization": `Bearer ${accessToken}`
        
        },
    })
    .then((response) => {
        return response.json();
    })
    .catch(err => console.log(err));
};
export const deleteCompany = (company_id,accessToken) => {
    return fetch(`${API}/company/delete/${company_id}`,{
        method: "DELETE",
        headers:{
            "Authorization": `Bearer ${accessToken}`
        
        },
    })
    .then((response) => {
        return response.json();
    })
    .catch(err => console.log(err));
};
export const deleteUser = (uuid,accessToken) => {
    return fetch(`${API}/users/delete/${uuid}`,{
        method: "DELETE",
        headers:{
            "Authorization": `Bearer ${accessToken}`
        
        },
    })
    .then((response) => {
        return response.json();
    })
    .catch(err => console.log(err));
};


export const getCategoryEquipments = (category_id,page) => {
    return fetch(`${API}/equipment/?category=${category_id}&page=${page}`, {method:"GET"})
    .then((response) => response.json())
    .catch((err) => console.log(err))
}

export const addEquipmentReview = (review, accessToken) => {
    return fetch(`${API}/equipment/rating/`,{
        method: "POST",
        headers:{
            "Authorization": `Bearer ${accessToken}`
        },
        body: review
    })
    .then((response) => {
        return response.json();
    })
    .catch(err => console.log(err));
};

