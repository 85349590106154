// DistanceCalculator.js

export function calculateDistance(latitude1, longitude1, latitude2, longitude2) {

    const R = 6371; // Earth's radius in km
    const dLat = ((latitude2 - latitude1) * Math.PI) / 180;
    const dLon = ((longitude2 - longitude1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((latitude1 * Math.PI) / 180) *
        Math.cos((latitude2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d;
  }
  