import React from 'react'
import { useState, useEffect } from 'react'
import EquipmentCard from '../components/Equipment/EquipmentCard'
import { getEquipmentsAll,getCategoriesAll,getCompaniesAll} 
from '../core/helper/coreapicalls';
import { Range } from 'react-range';
import mapboxgl from 'mapbox-gl';
import Cookies from 'js-cookie';
import { calculateDistance } from './CalculateDistance';
import { MAP_BOX_API } from '../backend';
import { CheckCircleIcon, XIcon } from '@heroicons/react/solid'

const Equipment = () => {
  const [Equipments, setEquipments] = useState([]);
  const [error, setError] = useState(false);
  const [visibleItems, setVisibleItems] = useState(10);
  const itemsPerLoad = 10;
  const [searchKeyword, setSearchKeyword] = useState('');
  const [alertC, showAlert] = useState({
    status:false,
    msg:"",
    type:""
})
  const [selectedCategory, setSelectedCategory] = useState('');
  const [filteredEquipments, setFilteredEquipments] = useState([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState('');
  const [dailyRentalFilter, setDailyRentalFilter] = useState([0, 25000]);
  const [totalResults, setTotalResults] = useState(0);
  const [showInput, setShowInput] = useState(false);
  const [showInputRadius, setShowInputRadius] = useState(false);
  const [selectedOption, setSelectedOption] = useState("All Locations");
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [manuallatitude, setManualLatitude] = useState();
  const [manuallongitude, setManualLongitude] = useState();
  const [radius, setRadius] = useState(50);
  const [Companies, setCompanies] = useState([])

  const [Categories, setCategories] = useState([])
  mapboxgl.accessToken = 'pk.eyJ1IjoiYmxvZ2JvdHRsZSIsImEiOiJjbGdvcDBmdGIwYTRjM2VtdTVlMXMwNW0yIn0.Y8CdbbiM9RiNFWYpUWM9iA';

  const handleSearchInput = (e) => {
    const query = e.target.value;
    setQuery(query);
  };
  useEffect(() => {
    const performSearch = async () => {
      const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?country=IN&access_token=${mapboxgl.accessToken}`;
      const response = await fetch(url);
      const data = await response.json();
      const results = data.features?.map((feature) => ({
        name: feature.place_name,
        latitude: feature.center[1],
        longitude: feature.center[0],
      }));
      setResults(results);
    };

    performSearch();
  }, [query]);
  const handleLocationSelect = (event) => {
    const selectedOption1 = document.querySelector(`option[value="${event.target.value}"]`);
    if(selectedOption1){
      const newLatitude = selectedOption1.dataset.latitude;
      const newLongitude = selectedOption1.dataset.longitude;
      setManualLongitude(newLongitude);
      setManualLatitude(newLatitude);}
 
  };
  const handleOptionChange = (event) => {
    const value = event.target.value;
    
    if (value === "All Locations") {
      setShowInput(false);
      setShowInputRadius(false);
      setSelectedOption(value);
    } 
    if (value === "Enter Location Manually") {
      setShowInput(true);
      setShowInputRadius(true);
      setSelectedOption(value);
    } 
    if (value === "Current Location") {
      
      if (Cookies.get('user_latitude')!==null && Cookies.get('user_longitude')!==null) {
        setShowInputRadius(true);
        setShowInput(false);
        setSelectedOption(value);
      } else {
        showAlert({status:true, msg:"Location access has been disabled. Some features may not work.", type:"red"})
        setSelectedOption('All Locations');
      }
      
    }
  };
  
    const loadEquipments = () => {
    getEquipmentsAll()
    .then((data) => {
      if (data.error){
        setError(data.error)
        console.log(error)
      }
          else{
            setEquipments(data);
          setTotalResults(data?.length)}
        })
     
      };

    const loadCompanies = () => {
        getCompaniesAll()
        .then((data) => setCompanies(data))
        };

    const loadCategories = () => {
            getCategoriesAll()
            .then((data) => setCategories(data))
          };
          
          useEffect(() => {
          loadCompanies();
          loadEquipments();
          loadCategories();
          },[]);

    useEffect(() => {
    let filtered = Equipments.filter(
      (equipment) => ((equipment?.status !== "Pending") && (equipment?.status !== "Deleted"))
    );

    if (selectedCategory) {
      filtered = filtered.filter(
        (equipment) => equipment?.category?.id === parseInt(selectedCategory)
      );
    }

    if (selectedManufacturer) {
      filtered = filtered.filter(
        (equipment) => equipment?.manufacturer?.id === parseInt(selectedManufacturer)
      );
    }

    if (dailyRentalFilter) {
      filtered = filtered.filter(
        (equipment) => equipment.daily_rental >= parseInt(dailyRentalFilter[0]) && equipment.daily_rental <= parseInt(dailyRentalFilter[1] )
      );
    }

    if(searchKeyword){
        filtered = filtered.filter(
            (equipment) => equipment.title.toLowerCase().includes(searchKeyword.toLowerCase())
          );
    
    }

    if(selectedOption){
      if (selectedOption === "Enter Location Manually") {
        filtered = filtered.filter((equipment) => {
          const distance = calculateDistance(
            manuallatitude,
            manuallongitude,
            equipment?.equipment_latitude,
            equipment?.equipment_longitude
        );
        return distance <= radius;
        });
      }
      if (selectedOption === "Current Location") {
        filtered = filtered.filter((equipment) => {
          const distance = calculateDistance(
            Cookies.get('user_latitude'),
            Cookies.get('user_longitude'),
            equipment?.equipment_latitude,
            equipment?.equipment_longitude
        );
        return distance <= radius;
        });
      }
    
    }
    
    setFilteredEquipments(filtered);
    const maxxxx = Math.max(filteredEquipments?.map((equipment) => equipment.daily_rental).filter(daily_rental => daily_rental != null));
  }, [visibleItems, Equipments, selectedCategory, selectedManufacturer, dailyRentalFilter,selectedOption, manuallatitude, manuallongitude, radius ,searchKeyword]);

  

const handleCategoryChange = (event) => {
  setSelectedCategory(event.target.value);
};

const handleManufacturerChange = (event) => {
  setSelectedManufacturer(event.target.value);
};

const handleMinInputChange = (event) => {
  const newValue = parseInt(event.target.value);

  setDailyRentalFilter([newValue, dailyRentalFilter[1]]);
};

const handleMaxInputChange = (event) => {
  const newValue = parseInt(event.target.value);
  setDailyRentalFilter([dailyRentalFilter[0], newValue]);
};


const handleRadiusChange = (event) => {
  setRadius(event.target.value);
};

  return (
    <div className='bg-gray-100 dark:bg-gray-900'>  
    {alertC.status && 
  <div className={`rounded-md bg-${alertC.type}-50 p-4`}>
  <div className="flex">
    <div className="flex-shrink-0">
      <CheckCircleIcon className={`h-5 w-5 text-${alertC.type}-400`} aria-hidden="true" />
    </div>
    <div className="ml-3">
      <p className="text-sm font-medium text-green-800">{alertC.msg}</p>
    </div>
    <div className="ml-auto pl-3">
      <div className="-mx-1.5 -my-1.5">
        <button
          type="button"   
          onClick={()=> showAlert({status:false})}

          className={`inline-flex bg-${alertC.type}-50 rounded-md p-1.5 text-${alertC.type}-500 hover:bg-${alertC.type}-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-${alertC.type}-50 focus:ring-${alertC.type}-600`}
        >
          <span className="sr-only">Dismiss</span>
          <XIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>
</div>
} 
    <div className="px-4 py-8 flex flex-col sm:flex-row justify-between items-center bg-gray-100 dark:bg-gray-900">
  <h1 className=" mb-4 text-gray-900 tracking-tight dark:text-white text-3xl font-bold">All Equipments</h1>
  <input
    type="text"
    value={searchKeyword}
    className="block border border-grey-light p-3 rounded mb-4"
    onChange={event =>{ setSearchKeyword(event.target.value);
    }}
    placeholder="Search equipments..."
  />
</div>

<div className="shadow p-5 rounded-lg bg-white dark:bg-gray-800">

 <div className="flex items-center justify-between">
   <p className="font-medium dark:text-white">
   Filters
   </p>

   {/* <button className="px-4 py-2 bg-gray-100 dark:bg-gray-500 hover:bg-gray-200 text-gray-800 dark:text-gray-100 text-sm font-medium rounded-md">
   Reset Filter
   </button> */}
 </div>

 <div>
   <div className="grid grid-cols-2 md:grid-cols-1 xl:grid-cols-2 gap-4 mt-4">
   <select value={selectedCategory} onChange={handleCategoryChange} className="px-4 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm">
   <option selected value=''>All Categories</option>
{ Categories?.map((Categories) =>   <option key={Categories.id} value={Categories.id}>{Categories.name}</option>) }
   </select>

   <select value={selectedManufacturer} onChange={handleManufacturerChange} className="px-4 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm">
   <option selected value="">All Manufacturers</option>
         { Companies?.map((Companies) =>   <option key={Companies.id} value={Companies.id}>{Companies.name}</option>) }

   </select>

   <select value={selectedOption} onChange={handleOptionChange} className="px-4 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm">
   <option>All Locations</option>
        <option>Current Location</option>
        <option>Enter Location Manually</option>
   </select>
   {showInputRadius && (
   <div className="flex flex-wrap px-4 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm">
   <lable className="">Distance Filter in Km </lable>
               <input
               type="number"
               placeholder="Enter Radius"
               value={radius}
               onChange={handleRadiusChange}
               className="w-full px-1 -mx-2 py-2 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm"
               />
   </div>
      )}

   {showInput && (
   <div className="flex flex-wrap px-4 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm">
   <lable className="">Enter Location </lable>
   <input
      type="text"
      value={query}
      onChange={handleSearchInput}
      placeholder="Enter location"
      className="w-full px-1 -mx-2 py-2 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm"
      list="locations"
      name="equipment_location"
      required={true}
      onSelect={handleLocationSelect}
    />
             <datalist id="locations">
      {results?.map((result) => (
        <option
          key={`${result.latitude},${result.longitude}`}
          value={result.name}
          data-longitude={result.longitude}
          data-latitude={result.latitude}
        >
          {result.name}
        </option>
      ))}
    </datalist>
   </div>
      )}
   <div className="flex flex-wrap px-4 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm">

   <lable className="">Minimum Price:-    </lable>
   <input
         type="number"
         value={dailyRentalFilter[0]}
         min={0}
         onChange={handleMinInputChange}
         className="w-full px-1 -mx-2 py-2 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm"
       />
   </div>

   <div className="flex flex-wrap px-4 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm">

   <lable className="">Maximum Price:-    </lable>
   <input
        type="number"
        value={dailyRentalFilter[1]}
        onChange={handleMaxInputChange}
        max={25000}
         className="w-full px-1 -mx-2 py-2 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm"
       />
   </div>
   </div>
 </div>
 <div className="relative mt-4 px-2 py-3">
 <Range
 values={dailyRentalFilter}
 step={1}
 min={0}
 max={25000}
 onChange={(newValues) => setDailyRentalFilter(newValues)}
 renderTrack={({ props, children }) => (
   <div
     {...props}
     className="w-full h-2 bg-gray-300 rounded-full cursor-pointer bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
   >
     {children}
   </div>
 )}
 renderThumb={({ index, props }) => (
   <div
     {...props}
     className={`h-4 w-4 bg-blue-500 rounded-full outline-none shadow-md cursor-pointer ${index === 0 ? 'ml-0' : 'ml-2'}`}
   />
 )}
/>

   </div>
 </div>

  
    <div className="relative flex flex-wrap mx-3">

    { 
       (filteredEquipments?.length!==0)? filteredEquipments?.slice(0, visibleItems)?.map((equipment) => <EquipmentCard equipment={equipment}/>):
       <div className={`mt-4 flex p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800`} role="alert">
    <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
    <span className="sr-only">Alert</span>
    <div>
    Nothing To Show Here!!!!
    </div>
  </div>
    }
 </div>
 {(totalResults>visibleItems)?
                    <div className='flex justify-center my-10'>
                      <button onClick={() => setVisibleItems(visibleItems + itemsPerLoad)}>Load More</button>
                    </div>:''}
    </div>
  )
}

export default Equipment
