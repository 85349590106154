import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { State } from "country-state-city";
import Sidebar from '../../components/Sidebar/Sidebar';



const Profile = () => {
  const indiaOption = { label: "India", value: "IN" };

    const [selectedCountry, setSelectedCountry] = useState(indiaOption);
  const authState = useSelector((state) => state.authReducer);
    const navigate = useNavigate();
  return (
    <>
<div className="flex bg-gray-100 dark:bg-gray-900">
<Sidebar/>
<div className="flex-grow bg-gray-100 dark:bg-gray-900">


          <div className="shadow sm:rounded-md sm:overflow-hidden ">
            <div className="bg-white py-6 px-4 space-y-6 sm:p-6 dark:bg-gray-800">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">Personal Information</h3>
              </div>

              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 dark:text-white">
                    First name
                  </label>
                  <input
                  disabled
                    type="text"
                    name="first-name"
                    id="first-name"
                    value={authState.user.data.first_name}
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 rounded-md shadow-sm py-2 px-3 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 dark:text-white">
                    Last name
                  </label>
                  <input
                  disabled
                  value={authState.user.data.last_name}
                  type="text"
                    name="last-name"
                    id="last-name"
                    autoComplete="family-name"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 rounded-md shadow-sm py-2 px-3  sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="email-address" className="block text-sm font-medium text-gray-700 dark:text-white">
                    Email address
                  </label>
                  <input
                    type="email"
                    disabled
                  value={authState.user.data.email}
                    name="email-address"
                    id="email-address"
                    autoComplete="email"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 rounded-md shadow-sm py-2 px-3  sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="phone_number" className="block text-sm font-medium text-gray-700 dark:text-white">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    disabled
                  value={authState.user.data.phone_number}
                    name="phone_number"
                    id="phone_number"
                    autoComplete="phone-number"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 rounded-md shadow-sm py-2 px-3  sm:text-sm"
                  />
                </div>

                <div className="col-span-6">
                  <label htmlFor="street-address" className="block text-sm font-medium text-gray-700 dark:text-white">
                    Address
                  </label>
                  <input
                    type="text"
                    disabled
                  value={authState.user.data.address}
                    name="street-address"
                    id="street-address"
                    autoComplete="street-address"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 rounded-md shadow-sm py-2 px-3  sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label htmlFor="region" className="block text-sm font-medium text-gray-700 dark:text-white">
                    State
                  </label>
                  <input
                    type="text"
                    name="region"
                    id="region"
                    disabled
                  value={State.getStateByCodeAndCountry(authState?.user?.data?.state,selectedCountry.value)?.name}
                    autoComplete="address-level1"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 rounded-md shadow-sm py-2 px-3  sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label htmlFor="city" className="block text-sm font-medium text-gray-700 dark:text-white">
                    City
                  </label>
                  <input
                    type="text"
                    name="city"
                    id="city"
                    disabled
                  value={authState.user.data.city}
                    autoComplete="address-level2"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 rounded-md shadow-sm py-2 px-3  sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700 dark:text-white">
                    ZIP / Postal code
                  </label>
                  <input
                    type="text"
                    name="postal-code"
                    disabled
                  value={authState.user.data.pin_code}
                    id="postal-code"
                    autoComplete="postal-code"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 rounded-md shadow-sm py-2 px-3  sm:text-sm"
                  />
                </div>
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 dark:bg-gray-700">
              <button
              onClick={() => navigate("/update-profile")}
                type="submit"
                className="w-full bg-green-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                Wanna Update Profile?
              </button>
            </div>
          </div>
          </div>
          </div>


   
   </>
  )
}

export default Profile
