import React, { Fragment, useState } from 'react'
import { Link } from "react-router-dom";
import { API } from '../../backend';
import { useSelector } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react'
import {CgProfile } from 'react-icons/cg'
import {RxCross2} from 'react-icons/rx'
import {AiOutlineMenu} from 'react-icons/ai'
import {BiNotepad} from 'react-icons/bi'
import * as Icon from 'react-bootstrap-icons';

import { Categories, Companies, Equipments, Password, Request, Tractor, TractorAdd, UsersList } from '../MyIcons';
const Sidebar = () => {

const menus = [
    { name: "My Profile", link: "/profile", icon: CgProfile },
    { name: "Bookings", link: "/booking-history", icon: BiNotepad },
    { name: "My Equipments", link: "/my-equipments", icon: Tractor },
    { name: "Add Equipments", link: "/addEquipment", icon: TractorAdd },
    { name: "Change Password", link: "/changepassword", icon: Password },
  ];
const menuAdmin = [
    { name: "Equipment Requests", link: "/equipment-requests", icon: Request },
    { name: "Users List", link: "/users-list", icon: UsersList },
    { name: "Equipments List", link: "/equipments-list", icon: Equipments },
    { name: "Categories List", link: "/categories-list", icon: Categories },
    { name: "Companies List", link: "/companies-list", icon: Companies },
    { name: "Booking List", link: "/booking-list", icon: BiNotepad },
  ];
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const open = false
    const authState = useSelector((state) => state.authReducer);
  const profile_picture='';
  if(authState.user.data.profile_picture){
    profile_picture=`${API}${authState.user.data.profile_picture}`;
  } 

    return (
        <>
    
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div>
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <RxCross2 className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <aside className="flex flex-col w-64 px-4 py-8 overflow-x-hidden overflow-y-auto bg-white border-r dark:bg-gray-900 dark:border-gray-700">

<div className="flex flex-col items-center mt-6 -mx-2">
  {profile_picture?<img className="object-cover w-24 h-24 mx-2 rounded-full" src={profile_picture} alt="Profile Picture"/>:
    <Icon.PersonCircle  className='object-cover w-24 h-24 mx-2 rounded-full'
    />
}
    <h4 className="mx-2 mt-2 font-medium text-gray-800 dark:text-gray-200">{authState.user.data.first_name} {authState.user.data.last_name}</h4>
    <p className="mx-2 mt-1 text-sm font-medium text-gray-600 dark:text-gray-400">{authState.user.data.email}</p>
</div>

<div className="flex flex-col justify-between flex-1 mt-6">
    <nav>
        <Link className="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700" to="/profile">
        <CgProfile className="w-5 h-5" width="24" height="24" href="/profile"/>

            <span className="mx-4 font-medium">My Profile</span>
        </Link>
        <Link className="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700" to="/booking-history">
        <BiNotepad className="w-5 h-5" width="24" height="24" href="/booking-history"/>

            <span className="mx-4 font-medium">Bookings</span>
        </Link>

        <Link className="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700" to="/my-equipments">
        <Tractor className="w-5 h-5" width="24" height="24" href="/my-equipments"/>

            <span className="mx-4 font-medium">My Equipment</span>
        </Link>
        <Link className="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700" to="/addEquipment">
        <TractorAdd className="w-5 h-5" width="24" height="24" href="/addEquipment"/>
            <span className="mx-4 font-medium">Add Equipment</span>
        </Link>

        <Link className="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700" to="/changepassword">
        <Password className="w-5 h-5" width="24" height="24" href="/changepassword"/>

            <span className="mx-4 font-medium">Change Password</span>
        </Link>

        {authState.user.data.is_admin?<>
        
            <Link to="/equipment-requests" className="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700">
          <Request className="w-5 h-5" width="24" height="24" href="/equipment-requests"/>
            
            <span className="mx-4 font-medium">Equipment Request</span>
        </Link> 

            <Link to="/users-list" className="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700">
          <UsersList className="w-5 h-5" width="24" height="24" href="/users-list"/>
            
            <span className="mx-4 font-medium">Users List</span>
        </Link> 
            <Link to="/equipments-list" className="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700">

    
          <Equipments className="w-5 h-5" width="24" height="24" href="/equipments-list"/>
            
            <span className="mx-4 font-medium">Equipments List</span>
        </Link> 
            <Link to="/categories-list" className="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700">

    
          <Categories className="w-5 h-5" width="24" height="24" href="/categories-list"/>
            
            <span className="mx-4 font-medium">Categories List</span>
        </Link> 

            <Link to="/companies-list" className="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700">

    
          <Companies className="w-5 h-5" width="24" height="24" href="/companies-list"/>
            
            <span className="mx-4 font-medium">Companies List</span>
        </Link> 
            <Link to="/booking-list" className="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700">

    
          <BiNotepad className="w-5 h-5" width="24" height="24" href="/booking-list"/>
            
            <span className="mx-4 font-medium">Booking List</span>
        </Link> 
        
        </>:''}
    </nav>
</div>
</aside>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14"></div>
          </Dialog>
        </Transition.Root>

        <aside className="hidden md:flex md:inset-mt-15 flex flex-col w-64 px-4 py-8 overflow-x-hidden overflow-y-auto bg-white border-r dark:bg-gray-900 dark:border-gray-700">

<div className="flex flex-col items-center mt-6 -mx-2">
{profile_picture?<img className="object-cover w-24 h-24 mx-2 rounded-full" src={profile_picture} alt="Profile Picture"/>:
    <Icon.PersonCircle  className='object-cover w-24 h-24 mx-2 rounded-full'
    />
}
    <h4 className="mx-2 mt-2 font-medium text-gray-800 dark:text-gray-200">{authState.user.data.first_name} {authState.user.data.last_name}</h4>
    <p className="mx-2 mt-1 text-sm font-medium text-gray-600 dark:text-gray-400">{authState.user.data.email}</p>
</div>

<div className="flex flex-col justify-between flex-1 mt-6">
    <nav>
        <Link className="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700" to="/profile">
        <CgProfile className="w-5 h-5" width="24" height="24" href="/profile"/>
            <span className="mx-4 font-medium">My Profile</span>
        </Link>

        <Link className="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700" to="/booking-history">
        <BiNotepad className="w-5 h-5" width="24" height="24" href="/booking-history"/>

            <span className="mx-4 font-medium">Bookings</span>
        </Link>

        <Link className="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700" to="/my-equipments">
        <Tractor className="w-5 h-5" width="24" height="24" href="/my-equipments"/>
            <span className="mx-4 font-medium">My Equipment</span>
        </Link>
        <Link className="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700" to="/addEquipment">
        <TractorAdd className="w-5 h-5" width="24" height="24" href="/addEquipment"/>


            <span className="mx-4 font-medium">Add Equipment</span>
        </Link>

        <Link className="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700" to="/changepassword">
        <Password className="w-5 h-5" width="24" height="24" href="/changepassword"/>
            <span className="mx-4 font-medium">Change Password</span>
        </Link>

        {authState.user.data.is_admin?<>
          <Link to="/equipment-requests" className="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700">
          <Request className="w-5 h-5" width="24" height="24" href="/equipment-requests"/>
            
            <span className="mx-4 font-medium">Equipment Request</span>
        </Link> 
            <Link to="/users-list" className="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700">
            <UsersList className="w-5 h-5" width="24" height="24" href="/users-list"/>
            
            <span className="mx-4 font-medium">Users List</span>
        </Link> 
            <Link to="/equipments-list" className="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700">

    
          <Equipments className="w-5 h-5" width="24" height="24" href="/equipments-list"/>
            
            <span className="mx-4 font-medium">Equipments List</span>
        </Link> 
            <Link to="/categories-list" className="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700">

    
          <Categories className="w-5 h-5" width="24" height="24" href="/categories-list"/>
            
            <span className="mx-4 font-medium">Categories List</span>
        </Link> 

            <Link to="/companies-list" className="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700">

    
          <Companies className="w-5 h-5" width="24" height="24" href="/companies-list"/>
            
            <span className="mx-4 font-medium">Companies List</span>
        </Link> 
            <Link to="/booking-list" className="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700">

    
          <BiNotepad className="w-5 h-5" width="24" height="24" href="/booking-list"/>
            
            <span className="mx-4 font-medium">Booking List</span>
        </Link> 
        
        </>:''}
    </nav>
</div>
</aside>

          <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-white border-r dark:bg-gray-900 dark:border-gray-700">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <AiOutlineMenu className=" h-6 w-6 dark:text-gray-400 font-medium hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg" aria-hidden="true" />
            </button>
          {menus?.map((menu, i) => (
            <Link
              to={menu?.link}
              key={i}
              className={` ${
                menu?.margin && "mt-5"

                
              } ml-1 gap-4 w-9 group dark:text-gray-400 flex items-center py-2 mt-5 text-sm  gap-3.5 font-medium p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg`}
            >
              <div>{React.createElement(menu?.icon, { size: "20" })}</div>
              <h2
                style={{
                  transitionDelay: `${i + 3}00ms`,
                }}
                className={`whitespace-pre duration-500 ${
                  !open && "opacity-0 translate-x-28 overflow-hidden"
                }`}
              >
                {menu?.name}
              </h2>
              <h2
                className={`${
                  open && "hidden"
                } absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
              >
                {menu?.name}
              </h2>
            </Link>
          ))}
          {authState.user.data.is_admin?
          menuAdmin?.map((menuAdmin, i) => (
            <Link
              to={menuAdmin?.link}
              key={i}
              className={` ${
                menuAdmin?.margin && "mt-5"

                
              } ml-1 gap-4 w-9 group dark:text-gray-400 flex items-center py-2 mt-5 text-sm  gap-3.5 font-medium p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg`}
            >
              <div>{React.createElement(menuAdmin?.icon, { size: "20" })}</div>
              <h2
                style={{
                  transitionDelay: `${i + 3}00ms`,
                }}
                className={`whitespace-pre duration-500 ${
                  !open && "opacity-0 translate-x-28 overflow-hidden"
                }`}
              >
                {menuAdmin?.name}
              </h2>
              <h2
                className={`${
                  open && "hidden"
                } absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
              >
                {menuAdmin?.name}
              </h2>
            </Link>
          )):''}
          </div>
  
            
          
          






</>
  )
}

export default Sidebar
