import React from 'react';

const Pagination = ({currentPage, onPageChange, totalPages}) => {
  // const totalPages = Math.ceil(totalItems / itemsPerPage);
  const pages = [...Array(totalPages).keys()]?.map(num => num + 1);

  return (
    <nav className="flex justify-center my-8 bg-gray-100 dark:bg-gray-900">
      <ul className="flex">
        <li>
          <button
            className={`${
              currentPage === 1
                ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                : 'bg-white hover:bg-gray-100 text-gray-600 hover:text-gray-800'
            } font-bold rounded-l-md px-3 py-2 mr-1 focus:outline-none`}
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            aria-label="Previous"
          >
            Previous
          </button>
        </li>
        {pages?.map((page, index) => (
          <li key={index}>
            <button
              className={`${
                currentPage === page
                  ? 'bg-[#64a55a] text-white'
                  : 'bg-white hover:bg-gray-100 text-gray-600 hover:text-gray-800'
              } font-bold px-3 py-2 mx-1 focus:outline-none`}
              onClick={() => onPageChange(page)}
            >
              {page}
            </button>
          </li>
        ))}
        <li>
          <button
            className={`${
              currentPage === totalPages
                ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                : 'bg-white hover:bg-gray-100 text-gray-600 hover:text-gray-800'
            } font-bold rounded-r-md px-3 py-2 ml-1 focus:outline-none`}
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            aria-label="Next"
          >
          Next
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;

