import './App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './pages/Home';
import { CheckCircleIcon, XIcon } from '@heroicons/react/solid'
import { BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import Categories from './pages/Categories';
import CategoryEquipments from './components/Category/CategoryEquipments';
import Equipment from './pages/Equipments';
import EquipmentDetail from './components/Equipment/EquipmentDetails';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import Profile from './pages/Profile/Profile';
import UpdateProfile from './pages/Profile/UpdateProfile';
import { useDispatch, useSelector } from 'react-redux';
import UsersList from './Admin/UsersList';
import EquipmentList from './Admin/EquipmentList';
import CategoryList from './Admin/CategoryList';
import AddUser from './Admin/AddUser';
import AddCategory from './Admin/AddCategory';
import AddEquipmentAdmin from './Admin/AddEquipmentAdmin';
import CompanyList from './Admin/CompanyList';
import BookingRequest from './pages/bookingRequest/BookingRequest';
import BookingHistory from './pages/bookingHistory/index';
import CancellationForm from './pages/cancellationForm';
import ContactUs from './pages/ContactUs/ContactUs';
import AddEquipment from './pages/AddEquipment';
import UserEquipment from './pages/UserEquipments';
import AboutUs from './pages/AboutUs';
import Cookies from 'js-cookie';
import { getLoginAction, getSaveProfileAction, getSaveTokenAction } from './redux/actions';
import { useEffect, useState } from 'react';
import { profile } from './auth/helper';
import EditEquipmentAdmin from './Admin/EditEquipment';
import EditCategoryAdmin from './Admin/EditCategory';
import EditUserAdmin from './Admin/EditUser';
import AddCompany from './Admin/AddCompany';
import EditCompanyAdmin from './Admin/EditCompany';
import ChangePassword from './pages/PasswordReset/ChangePassword';
import EquipmentRequest from './Admin/EquipmentRequest';
import ResetPasswordEmailSent from './pages/PasswordReset/ResetPasswordEmailSent';
import ResetPassword from './pages/PasswordReset/ResetPassword';
import EquipmentReviews from './components/Equipment/EquipmentReviews';
import BookingList from './Admin/BookingList';



function App() {
  const authState = useSelector((state) => state.authReducer);
  const tokenState = useSelector((state) => state.tokenReducer);

  const [alertC, showAlert] = useState({
    status:false,
    msg:"",
    type:""
})
  const dispatch = useDispatch();
  useEffect(() => {
    const access = Cookies.get("access-token");
    const refresh = Cookies.get("refresh-token");
    dispatch(
      getSaveTokenAction({
        accessToken: access,
        refreshToken: refresh
      })
    );
  }, [tokenState.token.accessToken]);

  const UserProfile = async () => {
    const access = Cookies.get("access-token");
    if (access) {
      const uuid = Cookies.get("uuid");
      dispatch(getLoginAction());
      const data  = await profile({uuid,access});
      dispatch(getSaveProfileAction(data))
      
    }}
    
    useEffect(() => {
      UserProfile();
    }, []);
    
    useEffect(() => {
      const successCallback = (position) => {
        Cookies.set("user_latitude", position.coords.latitude, {
          path: "/",
          expires: new Date().setDate(new Date().getDate() + 1)
        });
        Cookies.set("user_longitude", position.coords.longitude, {
          path: "/",
          expires: new Date().setDate(new Date().getDate() + 1)
        });
      };
      const errorCallback = (error) => {
        console.error(error);
        showAlert({status:true, msg:"Location access has been disabled. Some features may not work.", type:"red"})
      };
    
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    }, []);
    
    const is_admin = Cookies.get("is_admin");
    const access_token = Cookies.get("access-token");
   
    return (
      <> 
{alertC.status && 
  <div className={`rounded-md bg-${alertC.type}-50 p-4`}>
  <div className="flex">
    <div className="flex-shrink-0">
      <CheckCircleIcon className={`h-5 w-5 text-${alertC.type}-400`} aria-hidden="true" />
    </div>
    <div className="ml-3">
      <p className="text-sm font-medium text-green-800">{alertC.msg}</p>
    </div>
    <div className="ml-auto pl-3">
      <div className="-mx-1.5 -my-1.5">
        <button
          type="button"   
          onClick={()=> showAlert({status:false})}

          className={`inline-flex bg-${alertC.type}-50 rounded-md p-1.5 text-${alertC.type}-500 hover:bg-${alertC.type}-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-${alertC.type}-50 focus:ring-${alertC.type}-600`}
        >
          <span className="sr-only">Dismiss</span>
          <XIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>
</div>
}    <Header/>
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/signup" element={!access_token?<SignUp />: <Navigate to='/profile'/>} />
      <Route exact path="/login" element={!access_token?<Login />: <Navigate to='/profile'/>} />
      <Route exact path="/reset-password" element={<ResetPasswordEmailSent />} />
      <Route exact path="/user/reset/:user_id/:reset_token" element={<ResetPassword />} />
      <Route exact path="/contact" element={<ContactUs />} />
      <Route exact path="/categories" element={<Categories />} />
      <Route exact path="/equipments" element={<Equipment />} />
      <Route exact path="/category/:category_id/:category_name" element={<CategoryEquipments />} />
      <Route exact path="/equipment/:equipment_id/:equipment_title" element={<EquipmentDetail />} />
      <Route exact path="/equipment/reviews/:equipment_id/:equipment_title" element={<EquipmentReviews />} />
      <Route exact path="/profile" element={!access_token?<Navigate to='/login'/>: <Profile />} />
      <Route exact path="/dashboard" element={!access_token?<Navigate to='/login'/>: <Profile />} />
      <Route exact path="/update-profile" element={!access_token?<Navigate to='/login'/>:<UpdateProfile />} />
      <Route exact path="/my-equipments" element={!access_token?<Navigate to='/login'/>: <UserEquipment />} />
      <Route exact path="/addEquipment" element={!access_token?<Navigate to='/login'/>:<AddEquipment />} />
      <Route exact path="/users-list" element={!is_admin?<Navigate to='/profile'/>: <UsersList />} />
      <Route exact path="bookingRequest/:id" element={!access_token?<Navigate to='/login'/>:<BookingRequest />} />
      <Route exact path="booking-history" element={!access_token?<Navigate to='/login'/>:<BookingHistory />} />
      <Route exact path="changepassword" element={!access_token?<Navigate to='/login'/>:<ChangePassword />} />
      <Route exact path="/add-user" element={!is_admin?<Navigate to='/profile'/>: <AddUser />} />
      <Route exact path="/categories-list" element={!is_admin?<Navigate to='/profile'/>: <CategoryList />} />
      <Route exact path="/add-category" element={!is_admin?<Navigate to='/profile'/>: <AddCategory />} />
      <Route exact path="/add-Company" element={!is_admin?<Navigate to='/profile'/>: <AddCompany />} />
      <Route exact path="/equipments-list" element={!is_admin?<Navigate to='/profile'/>: <EquipmentList />} />
      <Route exact path="/add-equipment" element={!is_admin?<Navigate to='/profile'/>: <AddEquipmentAdmin />} />
      <Route exact path="/companies-list" element={!is_admin?<Navigate to='/profile'/>: <CompanyList />} />
      <Route exact path="/booking-list" element={!is_admin?<Navigate to='/profile'/>: <BookingList />} />
      <Route exact path="/equipment-update/:equipment_id/:equipment_title" element={!is_admin?<Navigate to='/profile'/>:<EditEquipmentAdmin />} />
      <Route exact path="/booking-update/:booking_id/" element={!is_admin?<Navigate to='/profile'/>:<EditEquipmentAdmin />} />
      <Route exact path="/user-update/:user_id/:uuid" element={!is_admin?<Navigate to='/profile'/>: <EditUserAdmin />} />
      <Route exact path="/category-update/:category_id/:category_name" element={!is_admin?<Navigate to='/profile'/>: <EditCategoryAdmin />} />
      <Route exact path="/company-update/:company_id/:company_name" element={!is_admin?<Navigate to='/profile'/>: <EditCompanyAdmin />} />
      <Route exact path="/equipment-requests" element={!is_admin?<Navigate to='/profile'/>: <EquipmentRequest />} />
      <Route exact path="/cancellation-form/:id" element={!access_token?<Navigate to='/login'/>:<CancellationForm />} />
      <Route exact path="/about" element={<AboutUs />} />
    </Routes>
            <Footer/>
    </>
    
  );
}

export default App;
