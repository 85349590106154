import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {  isMobile, isMatch } from "./Validation/Validators";
import { signup, verifyOTP } from '../auth/helper/index';

const SignUp = () => {
    const[signupFormData, setSignupFormData] = useState({
        "first_name":'',
        "last_name":'',
        "email":'',
        "phone_number":'',
        "pin_code":'',
        "password":'',
        "confirm_password":''
    });
    const {first_name, last_name, email, phone_number, pin_code, password, confirm_password} =signupFormData
    const [error, setError]= useState({
        status:false,
        msg:"",
        type:""
    })
    const [showOTP, setShowOTP]= useState(false);
    const [otp, setOtp]= useState('');

    const inputHandler = (event) => {
      setError({status:false});
     setSignupFormData({
        ...signupFormData,
        [event.target.name]:event.target.value
     })
    };

  async function submitHandler(event) {
    
    // const submitHandler = (event) =>{
        event.preventDefault();
        if (!isMatch(password,confirm_password)) {
            setError({status:true, msg:"Password do not match", type:"red"})
           
          }
        if(!isMobile(phone_number)){
            setError({status:true, msg:"Invalid Phone Number", type:"red"})
            
        }
        try {
            const data = await signup({
                first_name, 
                last_name, 
                email, 
                phone_number, 
                pin_code, 
                password, 
                confirm_password});
        
            if(data.success)
            {  
                setShowOTP(true);
                setError({status:true, msg:"OTP has been send to your Email. Please check your email and enter the OTP to verify your Identity", type:"green"})
        
    } 
    //for password and confirm password
            if(!data.success){
                setError({status:true, msg:`${data.message[0]}`, type:"red"})
               }
            else{
            }
        }
        catch(error) {
            console.log(error);
            if(error?.email){
            if(error?.email[0]==="user with this email address already exists." && error?.phone_number){
                if(!error?.phone_number.success){
                setError({status:true, msg:"User with this Email Already Exists and Invalid Phone Number", type:"red"})
                
            }
        else{
            setError({status:true, msg:"User with this Email and Phone Number Already Exists", type:"red"})
        }
        }
        // if(error?.email[0]==="user with this email address already exists."){
            else{
                setError({status:true, msg:"User with this Email Already Exists", type:"red"})
            
            }}
            else{
            if(error?.phone_number){
        if(!error?.phone_number.success){
            setError({status:true, msg:"Invalid Phone Number", type:"red"})
            
            }
        
    else{
        setError({status:true, msg:"User with this Phone Number Already Exists", type:"red"})
      
                        console.log(error);}
    }}
    }
        }        
  async function otpHandler(event) {
    
        event.preventDefault();
        try {
            const data = await verifyOTP({
                email, 
                otp});
        
            if(data.success)
            {  
                document.getElementById('otpform').reset();
                setError({status:true, msg:"Email Has been Verified Please move to login page", type:"green"})
    } 
            else{
            }
        }
        catch(error) {
            if(error?.success==="False")
            {
                setError({status:true, msg:`${error?.message}`, type:"red"})

            }
            
    }
        }        


  
  return (
    <>
    {(showOTP===false)?
        <section className="bg-white dark:bg-gray-900 flex flex-col">
        <div className="container my-4 flex-1 flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <h1 className="text-center text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                        Sign up 
                    </h1>
                    <form className="space-y-4 md:space-y-6" id='signupform' onSubmit={submitHandler} >
                        <div>
                            <label for="name" 
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First Name</label>
                            <input 
                            type="text" 
                            name="first_name"
                            className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                            placeholder="First Name*"
                            required={true}
                            value={signupFormData.first_name}
                          onChange={inputHandler}
                            />
                        </div>
                        <div>
                            <label for="name" 
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last Name</label>
                            <input 
                            type="text" 
                            name="last_name"
                            className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                            placeholder="Last Name"
                            value={signupFormData.last_name}
                          onChange={inputHandler}
                            />
                        </div>
                        <div>
                            <label for="email" 
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                            <input 
                            type="email" 
                            name="email"
                            required={true} 
                            className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                            placeholder="Email*"
                            value={signupFormData.email}
                          onChange={inputHandler}
                            />
                        </div>
                        <div>
                            <label for="tel" 
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone Number</label>
                            <input 
                            type="tel" 
                            name="phone_number"
                            className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                            placeholder="Phone Number*"
                            value={signupFormData.phone_number}
                            required={true}
                            onInvalid={(e) => e.target.setCustomValidity("Please enter a valid 10 digit phone number")}
                            onChange={(e) => {
                                e.target.setCustomValidity("");
                                inputHandler(e);
                            }}
                            pattern="[6789][0-9]{9}"
                            />
                        </div>
                        <div>
                            <label for="pin_code" 
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Pincode</label>
                            <input 
                            name="pin_code"
                            className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                            value={signupFormData.pin_code}
                            placeholder="Pincode*" 
                            onInvalid={(e) => e.target.setCustomValidity("Please enter a valid 6 digit pin code")}
                                  onChange={(e) => {
                                      e.target.setCustomValidity("");
                                      inputHandler(e);
                                  }}
                              required={true}
                              type="tel"
                              pattern="[1-9]{1}[0-9]{5}"
                            />
                        </div>
                        <div>
                            <label for="password" 
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                            <input 
                            type="password" 
                            name="password"
                            className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                            placeholder="••••••••" 
                            required={true}
                              autoComplete="on"
                            value={signupFormData.password}
                          onChange={inputHandler}
                            />
                        </div>
                        <div>
                            <label for="password" 
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm Password*</label>
                            <input 
                            type="password" 
                            required={true}
                              autoComplete="on"
                            name="confirm_password" 
                            placeholder="••••••••" 
                            value={signupFormData.confirm_password}
                              onChange={inputHandler}
                            className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                        </div>
                        {/* <div className="flex items-center justify-between">
                            <a href="#" className="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500">Forgot password?</a>
                        </div> */}
                        <div className="text-sm font-light text-gray-500 dark:text-gray-400">
                              By signing up, you agree to the &nbsp;
                              <Link className="font-medium text-blue-600 hover:underline dark:text-blue-500" to="#">
                                   Terms of Service 
                              </Link> and&nbsp;
                              <Link className="font-medium text-blue-600 hover:underline dark:text-blue-500" to="#">
                                  Privacy Policy
                              </Link>
                          </div>
                        <button 
                        type="submit" 
                        className="w-full text-white bg-green-600  hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-400 font-medium rounded-lg text-sm px-5 py-3 my-1 text-center">Create Account</button>
                        <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                        Already have an account?
                        <Link className="font-medium text-blue-600 hover:underline dark:text-blue-500" to="../login/">
                              Log in
                          </Link> 
                        </p>
                    </form>
                    {error?.status? <div className={`bg-${error?.type}-200 mt-4 flex p-4 mb-4 text-sm text-${error?.type}-800 border border-${error?.type}-300 rounded-lg bg-${error?.type}-50 dark:bg-gray-800 dark:text-${error?.type}-400 dark:border-${error?.type}-800`} role="alert">
        <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
        <span className="sr-only">Alert</span>
        <div>
        {error?.msg}
        </div>
      </div>
       :''}
                </div>
            </div>
        </div>
      </section>:


      <section className="bg-white dark:bg-gray-900 flex flex-col">
  <div className="container my-4 flex-1 flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-center text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                  Verify Email 
              </h1>
              <p className="text-center text-gray-900 dark:text-white">
                  The OTP has been sent to email:-{email} 
              </p>
              <form className="space-y-4 md:space-y-6" id='otpform' onSubmit={otpHandler} >
                  <div>
                      <label for="otp" 
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">OTP</label>
                      <input 
                      name="otp"
                      className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                      value={otp}
                      placeholder="OTP*" 

                            onChange={(e) => {
                                setError({status:false});
                                setOtp(e.target.value);
                            }}
                        required={true}
                        type="number"
                        pattern="[1-9]{1}[0-9]{5}"
                      />
                  </div>
                  <button 
                  type="submit" 
                  className="w-full text-white bg-green-600  hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-400 font-medium rounded-lg text-sm px-5 py-3 my-1 text-center">Verify Account</button>
               <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                        Already have an account?
                        <Link className="font-medium text-blue-600 hover:underline dark:text-blue-500" to="../login/">
                              Log in
                          </Link> 
                        </p>
              </form>
              {error?.status? <div className={`bg-${error?.type}-200 mt-4 flex p-4 mb-4 text-sm text-${error?.type}-800 border border-${error?.type}-300 rounded-lg bg-${error?.type}-50 dark:bg-gray-800 dark:text-${error?.type}-400 dark:border-${error?.type}-800`} role="alert">
  <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
  <span className="sr-only">Alert</span>
  <div>
  {error?.msg}
  </div>
</div>
 :''}
          </div>
      </div>
  </div>
</section>
    }
</>

  )
}

export default SignUp
