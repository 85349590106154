import React, { useState } from 'react'
import Sidebar from '../components/Sidebar/Sidebar';
import {  isMobile, isMatch,isEmpty, isEmail } from "../pages/Validation/Validators";
import { createUser } from './../auth/helper/index';
import { City, State } from "country-state-city";

const AddUser = () => {
const[addUserFormData, setAddUserFormData] = useState({
    "first_name":'',
    "last_name":'',
    "email":'',
    "phone_number":'',
    "address":'',
    "city":'',
    "state":'',
    "pin_code":'',
    "password":'',
    "confirm_password":'',
    "profile_picture":null
});
const {first_name, email, phone_number,address,city,state, pin_code, password, confirm_password} =addUserFormData
const [error, setError]= useState({
    status:false,
    msg:"",
    type:""
})

const inputHandler = (event) => {
  setError({status:false});
  console.log(addUserFormData)
 setAddUserFormData({
    ...addUserFormData,
    [event.target.name]:event.target.value
 })
};
const selectedCountry = { label: "India", value: "IN" };


const handleStateChange = (selectedOption) => {
  setError({status:false});
  setAddUserFormData(
    {...addUserFormData,
      state: selectedOption.target.value,
    city:""});
};
async function submitHandler(event) {   
    event.preventDefault();
    if (isEmpty(first_name) || 
    isEmpty(email) || 
    isEmpty(phone_number) || 
    isEmpty(address) || 
    isEmpty(state)|| 
    isEmpty(city) ||
    isEmpty(pin_code) || 
    isEmpty(password) ||
    isEmpty(confirm_password))
    {setError({status:true, msg:"Invalid Phone Number", type:"red"})
    }
    
    if (!isMatch(password,confirm_password)) {
        setError({status:true, msg:"Password do not match", type:"red"})
      }
    if(!isEmail(email)){
        setError({status:true, msg:"Invalid Email Address", type:"red"})
        
    }
    if(!isMobile(phone_number)){
        setError({status:true, msg:"Invalid Phone Number", type:"red"})
        
    }

    const userData = new FormData();
    userData.append('first_name', addUserFormData?.first_name);
    userData.append('last_name', addUserFormData?.last_name);
    userData.append('email', addUserFormData?.email);
    userData.append('phone_number', addUserFormData?.phone_number);
    userData.append('address', addUserFormData?.address);
    userData.append('city', addUserFormData?.city);
    userData.append('state', addUserFormData?.state);
    userData.append('pin_code', addUserFormData?.pin_code);
    userData.append('password', addUserFormData?.password);
    userData.append('confirm_password', addUserFormData?.confirm_password);
    (addUserFormData?.profile_picture) && userData.append('profile_picture', addUserFormData?.profile_picture);

    try {
      
      const data = await createUser(addUserFormData);
      if(data.success)
      {  
          setError({status:true, msg:"User Created Successfully", type:"green"})

} 
//for password and confirm password
      if(!data.success){
          setError({status:true, msg:`${data.message[0]}`, type:"red"})
          }
      else{
      }

       
    }
    catch(error){console.log(error);
      if(error?.email){
        if(error?.email[0]==="user with this email address already exists." && error?.phone_number){
            if(!error?.phone_number.success){
            setError({status:true, msg:"User with this Email Already Exists and Invalid Phone Number", type:"red"})
           
        }
    else{
        setError({status:true, msg:"User with this Email and Phone Number Already Exists", type:"red"})
            
    }
    }
    // if(error?.email[0]==="user with this email address already exists."){
        else{
            setError({status:true, msg:"User with this Email Already Exists", type:"red"})
            
        }}
        else{
        if(error?.phone_number){
    if(!error?.phone_number.success){
        setError({status:true, msg:"Invalid Phone Number", type:"red"})
            
        }
    
  else{
    setError({status:true, msg:"User with this Phone Number Already Exists", type:"red"})
            }
  }}
};
}   






  return (
<div className="flex bg-gray-100 dark:bg-gray-900">
<Sidebar/>
<div className="flex-grow bg-gray-100 dark:bg-gray-900">


                  <form id="userform" onSubmit={submitHandler}>
          <div className="shadow sm:rounded-md sm:overflow-hidden ">
            <div className="bg-white py-6 px-4 space-y-6 sm:p-6 dark:bg-gray-800">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">Add User</h3>
              </div>

              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 dark:text-white">
                    First name
                  </label>
                  <input
                    name="first_name"
                    placeholder="First Name*"
                    required={true}
                    value={addUserFormData?.first_name}
                    onChange={inputHandler}
                    type="text"
                    id="first-name"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
  
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 dark:text-white">
                    Last name
                  </label>
                  <input
                    name="last_name"
                    placeholder="Last Name"
                    value={addUserFormData?.last_name}
                    onChange={inputHandler} 
                    type="text"
                    id="last-name"
                    autoComplete="family-name"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
     
                <div className="col-span-6 sm:col-span-3">
                  <label className="block text-sm font-medium text-gray-700 dark:text-white">
                    Email address
                  </label>
                  <input
                    type="email"
                    placeholder="Email*"
                        required={true} 
                        value={addUserFormData?.email}
                        onChange={inputHandler}
                    name="email"
                    id="email-address"
                    autoComplete="email"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
  
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="phone_number" className="block text-sm font-medium text-gray-700 dark:text-white">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    placeholder="Phone Number*" 
                        required={true}
                        onInvalid={(e) => e.target.setCustomValidity("Please enter a valid 10 digit phone number")}
                        onChange={(e) => {
                            e.target.setCustomValidity("");
                            inputHandler(e);
                        }}
                        pattern="[6789][0-9]{9}"
                        value={addUserFormData?.phone_number}
                    name="phone_number"
                    id="phone_number"
                    autoComplete="phone-number"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
    
                <div className="col-span-6">
                  <label htmlFor="street-address" className="block text-sm font-medium text-gray-700 dark:text-white">
                    Address
                  </label>
                  <input
                    type="text"
                    onChange={inputHandler}
                    placeholder="Address"
                    value={addUserFormData?.address}
                    name="address"
                    id="street-address"
                    autoComplete="street-address"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label htmlFor="region" className="block text-sm font-medium text-gray-700 dark:text-white">
                    State
                  </label>
                  <select
                  required={true}
                  id="state"
                    onChange={handleStateChange}
                    name="state"
                    value={addUserFormData?.state}
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:focus:outline-none dark:focus:ring-indigo-500 dark:focus:border-indigo-500 sm:text-sm"
                  >
                    <option value="" selected>Select State</option>
                    {State.getStatesOfCountry(selectedCountry.value)?.map((state) => (
                    <option key={state.isoCode} value={state.isoCode}>{state.name}</option>))}
                  </select>
                </div>

                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label htmlFor="city" className="block text-sm font-medium text-gray-700 dark:text-white">
                    City
                  </label>
                  <select
                  required={true}
                  id="city"
                  isDisabled={!addUserFormData?.state}
                    onChange={inputHandler}
                    name="city"
                    value={addUserFormData?.city}
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:focus:outline-none dark:focus:ring-indigo-500 dark:focus:border-indigo-500 sm:text-sm"
                  >
                    <option selected>Select City</option>
                    {addUserFormData.state? City.getCitiesOfState(selectedCountry.value, addUserFormData.state)?.map((city) => (
                      <option key={city.name} value={city.name}>{city.name}</option>)): []}
                  </select>
                </div>
                
                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700 dark:text-white">
                    ZIP / Postal code
                  </label>
                  <input
                  name="pin_code"
                  placeholder="Pincode*" 
                  value={addUserFormData?.pin_code}
                  onInvalid={(e) => e.target.setCustomValidity("Please enter a valid 6 digit pin code")}
                        onChange={(e) => {
                            e.target.setCustomValidity("");
                            inputHandler(e);
                        }}
                    required={true}
                    type="tel"
                    pattern="[1-9]{1}[0-9]{5}"
                    id="postal-code"
                    autoComplete="postal-code"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
         
                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Password
                  </label>
                  <input
                  type="password"
                  name="password"
                  placeholder="Password*" 
                  required={true}
                  autoComplete="on"
                  value={addUserFormData?.password}
                  onChange={inputHandler}
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
  
                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Confirm Password
                  </label>
                  <input
                  type="password"
                  name="confirm_password"
                  placeholder="Confirm Password*" 
                  required={true}
                  autoComplete="on"
                  value={addUserFormData?.confirm_password}
                  onChange={inputHandler}
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                
                <div className="col-span-3 sm:col-span-3 lg:col-span-2">
                  <label className="block text-sm font-medium text-gray-700">Profile Picture</label>
                  <div className="mt-1 flex items-center">
                  
                    <input name="profile_picture"
                        id="default_size"
                      className="bg-white border border-gray-300 dark:border-gray-900 dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onChange={(e) => {
                        setAddUserFormData({ ...addUserFormData, profile_picture: e.target.files[0] });
                      }}                   
                        type="file" accept="image/*" />
                   {(addUserFormData.profile_picture)?
                  <span className="ml-5 inline-block bg-gray-100 rounded-lg overflow-hidden h-20 w-20">
                        <img
                        src={URL.createObjectURL(addUserFormData.profile_picture)}
                        alt="Preview"
                        className='h-full w-full'
                        />
                        </span>
                        :''
                        }
                  
                  </div>
                </div>
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 dark:bg-gray-700">
              <button
                type="submit"
                className="w-full bg-green-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                Create User
              </button>
            </div>
          </div>
          </form>
          {error?.status? <div className={`bg-${error?.type}-200 mt-4 flex p-4 mb-4 text-sm text-${error?.type}-800 border border-${error?.type}-300 rounded-lg bg-${error?.type}-50 dark:bg-gray-800 dark:text-${error?.type}-400 dark:border-${error?.type}-800`} role="alert">
  <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
  <div>
  {error?.msg}
  </div>
</div>
 :''}
          </div>
          </div>
  )
  }
export default AddUser
