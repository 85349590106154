import React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate} from 'react-router-dom'
import EquipmentCard from '.././components/Equipment/EquipmentCard';
import CategoryCard from '.././components/Category/CategoryCard';
import Slider from '.././components/Slider/Slider';
import { getEquipmentsAll } from '../core/helper/coreapicalls';
import { getCategories } from '../core/helper/coreapicalls';



const Home = () => {
  const navigate= useNavigate();
  const [Categories, setCategories] = useState([])
  const [Equipments, setEquipments] = useState([])

const loadEquipments = () => {
  
  getEquipmentsAll()
  .then((data) =>{ 
    
    let filtered = data.filter(
      (equipment) => ((equipment?.status !== "Pending") && (equipment?.status !== "Deleted"))
    );
    setEquipments(filtered);
;})
};

useEffect(() => {
loadEquipments();
},[]);

const loadCategories = () => {
  
  getCategories(1)
  .then((data) =>{ setCategories(data.results);})
};

useEffect(() => {
loadCategories();
},[]);


return (
  <>
<div className="bg-gray-100 dark:bg-gray-900">
  <Slider />
  <div className="flex justify-between items-center mt-4">
  <h1 className="text-2xl mx-2 font-bold mb-4 text-gray-900 tracking-tight dark:text-white">Featured Equipments</h1>
  <button onClick={ () => navigate('/equipments')} className="text-center py-2 rounded bg-green-600 text-white hover:bg-green-800 focus:outline-none font-bold -mt-2 px-4 mr-2">
    All Equipments
  </button>
</div>
 
  <div className="relative flex flex-wrap mx-4">

{ (Equipments?.length!==0)?Equipments?.map((Equipment) =>  <EquipmentCard equipment={Equipment}/>):
<div className={`mt-4 flex p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800`} role="alert">
    <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
    <span className="sr-only">Alert</span>
    <div>
    Nothing To Show Here!!!!
    </div>
  </div> }
</div>

<div className="flex justify-between items-center mt-4">
  <h1 className="text-2xl mx-2 font-bold mb-4 text-gray-900 tracking-tight dark:text-white">Featured Categories</h1>
  <button onClick={ () => navigate('/categories')} className="text-center py-2 rounded bg-green-600 text-white hover:bg-green-800 focus:outline-none font-bold -mt-2 px-4 mr-2">
    All Categories
  </button>
</div>
<div className="relative flex flex-wrap mx-3">
{ (Categories?.length!==0)?Categories?.map((Categories) =>  <CategoryCard category={Categories}/>):
<div className={`mt-4 flex p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800`} role="alert">
    <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
    <span className="sr-only">Alert</span>
    <div>
    Nothing To Show Here!!!!
    </div>
  </div> }
</div>
      {/* <div className="container mx-auto my-10">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="p-6 bg-white rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold text-gray-800 mb-2">
              Rent Our Equipment
            </h2>
            <p className="text-gray-700 mb-4">
              We have a variety of farming equipment available forrent, including tractors, plows, harvesters, and more. Our equipment is well-maintained and ready to help you get the job done right. Browse our selection and reserve your rental today!
</p>
<Link
           to="/equipments"
           className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md"
         >
Browse Equipments
</Link>
</div>
      <div className="p-6 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-gray-800 mb-2">
          About Us
        </h2>
        <p className="text-gray-700 mb-4">
          Farm Equipment Rentals has been serving the farming community for over 20 years. We understand the unique needs of farmers and are dedicated to providing the best equipment rental service possible. Learn more about us and our commitment to our customers.
        </p>
        <Link
          to="/about"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md"
        >
          Learn More
        </Link>
      </div>
    </div>
  </div> */}

</div>

  </>
  )
}

export default Home
