
import React, { useState, useEffect } from 'react'
import Sidebar from '../components/Sidebar/Sidebar';
import { getCategory, updateCategory } from '../core/helper/coreapicalls';
import Cookies from 'js-cookie';
import {IoMdSwap} from 'react-icons/io'

import { useParams } from 'react-router-dom';
const EditCategoryAdmin = () => {
    

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [newImage, setNewImage] = useState(null);
  const access_token = Cookies.get('access-token');
    const params = useParams();
    const category_id =params.category_id;
    const [error, setError]= useState({
        status:false,
        msg:"",
        type:""
    })
    const loadCategoryDetail = async () => {
        try {
            const data = await getCategory(category_id, access_token);
            setName(data.name);
            setDescription(data.description);
            setImage(data.image);
        } catch (error) {
          // handle error here
        }
      }
      
  useEffect(() => {
    loadCategoryDetail();
    },[]);


const submitHandler = (event) =>{
    event.preventDefault();
    const categoryData = new FormData();
    categoryData.append('name', name);
    categoryData.append('description', description);
    (newImage) && ( categoryData.append('image', newImage));

    updateCategory(categoryData, access_token, category_id)
    .then(data =>{
        if(data?.id){
            setError({status:true, msg:"Category has been Updated", type:"green"})
        }
    })
    .catch((e)=> console.log(e));
}   


  return (
    <>
   
   <div className="flex bg-gray-100 dark:bg-gray-900">
<Sidebar/>
<div className="flex-grow bg-gray-100 dark:bg-gray-900">
                  <form id="categoryform" onSubmit={submitHandler}>
          <div className="shadow sm:rounded-md sm:overflow-hidden ">
            <div className="bg-white py-6 px-4 space-y-6 sm:p-6 dark:bg-gray-800">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">Edit Category</h3>
              </div>

              <div className="grid grid-cols-6 gap-6">
            
              <div className="col-span-6">
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Name
                  </label>
                  <input
                    type="text"
                    name="name"
                        placeholder="Name*"
                        required={true}
                        value={name}
                        onChange={(event) =>{setError({status:false});
                          setName(event.target.value)}}
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
  
              <div className="col-span-6">
                  <label htmlFor="description" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Description
                  </label>
                  <textarea
                    type="text"
                    placeholder="Description"
                    required={true}
                    value={description}
                    onChange={(event) =>{setError({status:false}); 
                    setDescription(event.target.value)}}
                    name="description"
                    id="description"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
    
                <div className="col-span-3">
                  <label className="block text-sm font-medium text-gray-700">Image</label>
                  <div className="mt-1 flex items-center">
                    <input name="image"
                        id="default_size"
                      className="bg-white border border-gray-300 dark:border-gray-900 dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onChange={(event) =>{setNewImage(event.target.files[0])}}                  
                        type="file" accept="image/*" />
                        {(image)?
                  <span className="ml-5 inline-block bg-gray-100 rounded-lg overflow-hidden h-20 w-20">
                        <img
                        src={`${image}`}
                        alt="Preview"
                        className='h-full w-full'
                        />
                        </span>
                        :''
                        }
                        {(newImage!==null && newImage!==undefined &&newImage!=='')?
                        <>
                        <IoMdSwap className="ml-5 w-16 h-16" width="24" height="24"/>
                  <span className="ml-5 inline-block bg-gray-100 rounded-lg overflow-hidden h-20 w-20">
                        <img
                        src={URL.createObjectURL(newImage)}
                        alt="Preview"
                        className='h-full w-full'
                        />
                        </span>
                        </>
                        :''
                        }


                  </div>
                </div>

              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 dark:bg-gray-700">
              <button
                type="submit"
                className="w-full bg-green-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                Edit Category
              </button>
            </div>
          </div>
          </form>
          {error?.status? <div className={`bg-${error?.type}-200 mt-4 flex p-4 mb-4 text-sm text-${error?.type}-800 border border-${error?.type}-300 rounded-lg bg-${error?.type}-50 dark:bg-gray-800 dark:text-${error?.type}-400 dark:border-${error?.type}-800`} role="alert">
  <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
  <div>
  {error?.msg}
  </div>
</div>
 :''}
          </div>
          </div>




<div className="flex">
<Sidebar/>
      <div className="flex-grow">
      <div className="rounded  shadow p-2">
      <div className="rounded  shadow p-6">
    <h1 className="mb-8 text-3xl "></h1>
<form id="productform" onSubmit={submitHandler}>
       

 

      

      </form>
   </div>
   </div>
   </div>
   </div>
   </>
  )
  }
export default EditCategoryAdmin
