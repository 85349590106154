import React, { useState, useEffect } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { StarIcon } from '@heroicons/react/solid'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addEquipmentReview, getEquipment } from '../../core/helper/coreapicalls';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from "js-cookie";
import { API } from '../../backend';
const EquipmentReviews = () => {
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedRating, setSelectedRating]= useState(0)
    const[review, setReview] = useState('')

    const [errorRating, setErrorRating]= useState({
      status:false,
      msg:"",
      type:""
  })
  const access_token = Cookies.get("access-token");
      const [equipment, setEquipment] = useState([]);
    const params = useParams();
    const navigate = useNavigate();
      const eq_id =params.equipment_id;
  const loadEquipmentDetail = () => {
  
    getEquipment(eq_id)
    .then((data) => setEquipment(data))
  };
  useEffect(() => {
  loadEquipmentDetail();
  },[])

  
  const handleReviewInput = (event) =>{
    
      event.preventDefault();
      if(selectedRating===0){
        setErrorRating({status:true, msg:"Please Select the rating first", type:"red"})
  
      }
      const reviewData = new FormData();
      reviewData.append('rating', selectedRating);
      reviewData.append('reviews', review);
      reviewData.append('equipment', equipment.id);
      
      addEquipmentReview(reviewData, access_token)
      .then(data =>{
          if(data?.success){
              setErrorRating({status:true, msg:`${data?.message}`, type:"green"})
              setSelectedRating(0);
              setReview('');
              loadEquipmentDetail();

          }
      })
      .catch((e)=> console.log(e));
  }   
  
  const ratingList = equipment?.equipment_rating?.rating_list || [];
  const sortedData = [...ratingList].sort((a, b) => new Date(b.add_time) - new Date(a.add_time))?.slice(0, 5);
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  
      return (
  <div className="bg-gray-100 dark:bg-gray-900">
      <div className="flex mx-3">
<div className="mx-5 my-5 cursor-pointer border border-gray-200 shadow relative w-80 h-362px bg-white shadow-md rounded-3xl p-2 mx-1 my-3 cursor-pointer dark:bg-gray-800 dark:border-gray-700">
      <div className="rounded-2xl relative">
<Link to={`/equipment/${equipment?.eq_id}/${equipment?.title}`} >
        <img className="h-40 rounded-2xl w-full object-cover" src={equipment?.image_1} alt="Not Entered while creating"/>
       
    </Link>
      </div>
      <div className="mt-4 pl-2 mb-2 flex justify-between ">
        <div>
          <Link to={`/equipment/${equipment?.eq_id}/${equipment?.title}`} >
          <p className="truncate h-7 w-72 text-lg font-semibold text-gray-900 mb-0 tracking-tight dark:text-white">{equipment?.title}</p>
          <p className="truncate h-7 w-72 text-lg font-semibold text-gray-900 mb-0 tracking-tight dark:text-white">Manufacture: {equipment?.manufacturer?.name}</p>
          </Link>

          <p className="text-md text-gray-800 mt-0 font-bold dark:text-white">Daily Rental: Rs. {equipment?.daily_rental}</p>
          <p className="text-md text-gray-800 mt-0 font-bold dark:text-white">Hourly Rental: Rs.{equipment?.hourly_rental}</p>
        </div>
        <div className="flex flex-col-reverse mb-1 mr-4 group cursor-pointer">
        </div>
      </div>
    </div>
<div className="mx-5 my-5 border border-gray-200 shadow relative bg-white shadow-md rounded-3xl p-2 mx-1 my-3 cursor-pointer dark:bg-gray-800 dark:border-gray-700">
        <div className="lg:col-span-4">
          <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">Customer Reviews</h2>

          <div className="mt-3 flex items-center">
            <div>
              <div className="flex items-center">
                {[0, 1, 2, 3, 4].map((rating) => (
                  <StarIcon
                    key={rating}
                    className={classNames(
                      equipment?.equipment_rating?.average_rating > rating ? 'text-yellow-400' : 'text-gray-300',
                      'flex-shrink-0 h-5 w-5'
                    )}
                    aria-hidden="true"
                  />
                ))}
              </div>
              <p className="sr-only">{equipment?.equipment_rating?.average_rating} out of 5 stars</p>
            </div>
            <p className="ml-2 text-sm text-gray-900">Based on {equipment?.equipment_rating?.total_reviews} reviews</p>
          </div>

          <div className="mt-6">
            <h3 className="sr-only">Review data</h3>

            <dl className="space-y-3">
              {equipment?.equipment_rating?.counts?.map((count) => (
                <div key={count.rating} className="flex items-center text-sm">
                  <dt className="flex-1 flex items-center">
                    <p className="w-3 font-medium text-gray-900">
                      {count.rating}
                      <span className="sr-only"> star reviews</span>
                    </p>
                    <div aria-hidden="true" className="ml-1 flex-1 flex items-center">
                      <StarIcon
                        className={classNames(
                          count.count > 0 ? 'text-yellow-400' : 'text-gray-300',
                          'flex-shrink-0 h-5 w-5'
                        )}
                        aria-hidden="true"
                      />

                      <div className="ml-3 relative flex-1">
                        <div className="h-3 bg-gray-100 border border-gray-200 rounded-full" />
                        {count.count > 0 ? (
                          <div
                            className="absolute inset-y-0 bg-yellow-400 border border-yellow-400 rounded-full"
                            style={{ width: `calc(${count.count} / ${equipment?.equipment_rating?.total_reviews} * 100%)` }}
                          />
                        ) : null}
                      </div>
                    </div>
                  </dt>
                  <dd className="ml-3 w-10 text-right tabular-nums text-sm text-gray-900">
                    {Math.round((count.count / equipment?.equipment_rating?.total_reviews) * 100)}%
                  </dd>
                </div>
              ))}
            </dl>
          </div>

          <div className="mt-10">
            <h3 className="text-lg font-medium text-gray-900">Share your thoughts</h3>
            <p className="mt-1 text-sm text-gray-600">
              If you’ve used this product, share your thoughts with other customers
            </p>

            <button
              onClick={() => setIsFormOpen(true)}
              className="mt-6 inline-flex w-full bg-white border border-gray-300 rounded-md py-2 px-8 items-center justify-center text-sm font-medium text-gray-900 hover:bg-gray-50 sm:w-auto lg:w-full"
            >
              Write a review
            </button>
            {isFormOpen &&
    <form className="mt-6" onSubmit={handleReviewInput}>
      <label htmlFor="rating" className="block text-sm font-medium text-gray-700">
        Rating
      </label>
      <div className="mt-1 flex items-center">
        {[1, 2, 3, 4, 5].map((rating) => (
          <StarIcon
            key={rating}
            className={classNames(
              selectedRating >= rating ? 'text-yellow-400' : 'text-gray-300',
              'h-5 w-5 cursor-pointer'
            )}
            aria-hidden="true"
            required={true}
            onClick={() => {setErrorRating({status:false});
              setSelectedRating(rating);}}
          />
        ))}
      </div>
      <label htmlFor="review" className="block mt-4 text-sm font-medium text-gray-700">
        Review
      </label>
      <textarea
        id="review"
        name="review"
        required
        rows={3}
        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-yellow-400 focus:ring focus:ring-yellow-400 focus:ring-opacity-50"
        onChange={(event)=> setReview(event.target.value)}
      />
      <div className="mt-4">
        <button
          type="submit"
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400"
        >
          Submit
        </button>
        <button
          onClick={()=> {setSelectedRating(0);
            setReview('');
            setErrorRating({status:false});
            setIsFormOpen(false);}}
          className="ml-4 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
        >
          Cancel
        </button>
      </div>
    </form>
  }
       {errorRating?.status? <div className={`bg-${errorRating?.type}-200 mt-4 flex p-4 mb-4 text-sm text-${errorRating?.type}-800 border border-${errorRating?.type}-300 rounded-lg bg-${errorRating?.type}-50 dark:bg-gray-800 dark:text-${errorRating?.type}-400 dark:border-${errorRating?.type}-800`} role="alert">
     <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
     <div>
     {errorRating?.msg}
     </div>
   </div>
    :''}
          </div>
        </div>

        <div className="mx-auto py-8 px-4 sm:py-12 sm:px-6 lg:py-8 lg:px-8 ">
          
          <div className="lg:mt-0 lg:col-start-6 lg:col-span-7">
            <h3 className="sr-only">Recent reviews</h3>
  
            <div className="flow-root">
              <div className="-my-12 divide-y divide-gray-200">
                {sortedData?.map((review) => (
                  <div key={review.id} className="py-12">
                    <div className="flex items-center">
                      <img src={`${API}/${review?.user?.profile_picture}`} alt={`${review?.user?.first_name} ${review?.user?.last_name}.`} className="h-12 w-12 rounded-full" />
                      <div className="ml-4">
                        <h4 className="text-sm font-bold text-gray-900">{review?.user?.first_name} {review?.user?.last_name}</h4>
                        <div className="mt-1 flex items-center">
                          {[0, 1, 2, 3, 4].map((rating) => (
                            <StarIcon
                              key={rating}
                              className={classNames(
                                review.rating > rating ? 'text-yellow-400' : 'text-gray-300',
                                'h-5 w-5 flex-shrink-0'
                              )}
                              aria-hidden="true"
                            />
                          ))}
                        </div>
                        <p className="sr-only">{review.rating} out of 5 stars</p>
                      </div>
                    </div>
  
                    <div
                      className="mt-4 space-y-6 text-base italic text-gray-600"
                      dangerouslySetInnerHTML={{ __html: review.reviews }}
                    />
                  </div>
                ))}
              </div>
              {ratingList?.length >5 &&
              <button
                onClick={() => navigate(`/equipment/reviews/${equipment?.eq_id}/${equipment?.title}`)}
                className="mt-6 inline-flex w-full bg-white border border-gray-300 rounded-md py-2 px-8 items-center justify-center text-sm font-medium text-gray-900 hover:bg-gray-50 sm:w-auto lg:w-full"
              >
                See all {ratingList?.length} Reviews
              </button>}
            </div>
          </div>
        </div>
      </div>

    
      </div>
    </div>
  
  
    )
  }
export default EquipmentReviews
