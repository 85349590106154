import emailjs  from 'emailjs-com'
import React, { useState } from 'react'

const ContactUs = () => {
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm("krishisangam", "krishisangam.contactus", e.target , "R_zvdMJPwLfE23bOr")
    .then((result) => {
        setFirstName('');setEmail('');setMessage('');setSubject('');setLastName('');
    }, (error) => {
    });
}


  return (
    <>


<section className="bg-white dark:bg-gray-900 py-20 lg:py-[120px] overflow-hidden relative">
   <div className="container">
      <div className="flex flex-wrap lg:justify-between mx-4">
         <div className="w-full lg:w-1/2 xl:w-6/12 px-4">
            <div className="max-w-[570px] mb-12 lg:mb-0">

               <h2
                  className="
                  text-dark
                  dark:text-white
                  mb-6
                  uppercase
                  font-bold
                  text-[32px]
                  sm:text-[40px]
                  lg:text-[36px]
                  xl:text-[40px]
                  "
                  >
                  GET IN TOUCH WITH US
               </h2>
               <p className="text-base dark:text-white text-body-color leading-relaxed mb-9">
                  Please fill this form to get fast response for your query. After submitting the form please wait for few days our team will reach you out soon 
               </p>
               <div className="mb-9 dark:text-white">
                    <h3 className="text-lg font-semibold mb-4 ">ADDRESS</h3>
                    <p>New Boys Hostel, Room Number- 612-C</p>
                    <p>Teerthanker Mahaveer University, 244001</p>
                  </div>
                  <div className="mb-9 dark:text-white">
                    <h3 className="text-lg font-semibold mb-4">EMAIL</h3>
                    <a href="mailto:Jainharsh8506@gmail.com"><p>Jainharsh8506@gmail.com</p></a>
                  </div><div className='dark:text-white'>
                    <h3 className="text-lg font-semibold mb-4">PHONE</h3>
                    <p>850-683-3512</p>
                  </div>
            </div>
         </div>
         <div className="w-full lg:w-1/2 xl:w-5/12 px-4">
            <div className="bg-white dark:bg-gray-800 relative rounded-lg p-8 sm:p-12 shadow-lg">
               <form onSubmit={handleSubmit} id="contactForm" name="contactForm">
                  <div className="mb-6 dark:text-white">
      <label className="block uppercase tracking-wide text-white-700 text-xs font-bold mb-2" for="grid-first-name">
        First Name
      </label>
      <input className="text-gray-900
                        sm:text-sm 
                        dark:bg-gray-700 
                        dark:border-gray-600 
                        dark:placeholder-gray-400 
                        dark:text-white 
                        block
                        border 
                        border-grey-300 
                        w-full 
                        p-3 
                        rounded 
                        mb-4" 
                        id="grid-first-name" 
                        name='first_name'
                        type="text" 
                        onChange={(e) => setFirstName(e.target.value)} value={first_name}
                        placeholder="First Name"/>
                     
                  </div>
                  <div className="mb-6 dark:text-white">
      <label className="block uppercase tracking-wide text-white-700 text-xs font-bold mb-2" for="grid-last-name">
        Last Name
      </label>
      <input className="text-gray-900
                        sm:text-sm 
                        dark:bg-gray-700 
                        dark:border-gray-600 
                        dark:placeholder-gray-400 
                        dark:text-white 
                        block
                        border 
                        border-grey-300 
                        w-full 
                        p-3 
                        rounded 
                        mb-4" 
                        onChange={(e) => setLastName(e.target.value)} value={last_name} id="grid-last-name" type="text" name='last_name' placeholder="Last Name"/>
                  </div>
                  <div className="mb-6 dark:text-white">
      <label className="block uppercase tracking-wide text-white-700 text-xs font-bold mb-2" for="grid-password">
        E-mail
      </label>
      <input className="text-gray-900
                        sm:text-sm 
                        dark:bg-gray-700 
                        dark:border-gray-600 
                        dark:placeholder-gray-400 
                        dark:text-white 
                        block
                        border 
                        border-grey-300 
                        w-full 
                        p-3 
                        rounded 
                        mb-4"  onChange={(e) => setEmail(e.target.value)} value={email} name='email' id="email" type="email" placeholder="Email" />
    </div>
                  <div className="mb-6 dark:text-white">
    
    
      <label className="block uppercase tracking-wide text-white-700 text-xs font-bold mb-2" for="grid-subject">
        Subject
      </label>
      <input className="text-gray-900
                        sm:text-sm 
                        dark:bg-gray-700 
                        dark:border-gray-600 
                        dark:placeholder-gray-400 
                        dark:text-white 
                        block
                        border 
                        border-grey-300 
                        w-full 
                        p-3 
                        rounded 
                        mb-4"  name='subject' id="subject" onChange={(e) => setSubject(e.target.value)} value={subject} type="text" placeholder="Subject" />
 
  </div>
                  <div className="mb-6 dark:text-white">
                 
      <label className="block uppercase tracking-wide text-white-700 text-xs font-bold mb-2" for="grid-password">
        Message
      </label>
      <textarea className="text-gray-900
                        sm:text-sm 
                        dark:bg-gray-700 
                        dark:border-gray-600 
                        dark:placeholder-gray-400 
                        dark:text-white 
                        block
                        border 
                        border-grey-300 
                        w-full 
                        p-3 
                        rounded 
                        mb-4"  name='message' onChange={(e) => setMessage(e.target.value)} value={message} id="message" placeholder='Message'></textarea>

                     </div>
                  
                  <div className="md:w-1/3">
      <button className="w-full text-center py-3 rounded bg-green-600 text-white hover:bg-green-800 focus:outline-none my-1" type="submit">
        Send Message
      </button>
    </div>
               </form>
               
            </div>
         </div>
      </div>
   </div>
</section>




    </>
  )
}

export default ContactUs
