
import React, { useState, useEffect } from 'react'
import Sidebar from '../components/Sidebar/Sidebar';
import { addEquipment,getUsersAll,getCompaniesAll,getCategoriesAll } from '../core/helper/coreapicalls';
import Cookies from 'js-cookie';
import Datepicker from "react-tailwindcss-datepicker"; 
import mapboxgl from 'mapbox-gl';
import { MAP_BOX_API } from '../backend';


const AddEquipmentAdmin = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate()- 1);
  mapboxgl.accessToken = 'pk.eyJ1IjoiYmxvZ2JvdHRsZSIsImEiOiJjbGdvcDBmdGIwYTRjM2VtdTVlMXMwNW0yIn0.Y8CdbbiM9RiNFWYpUWM9iA';
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [selectedLocation, setSelectedLocation] = useState(null);
  const handleSearchInput = (e) => {
    const query = e.target.value;
    setQuery(query);
  };
  useEffect(() => {
    const performSearch = async () => {
      const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?country=IN&access_token=${mapboxgl.accessToken}`;
      const response = await fetch(url);
      const data = await response.json();
      const results = data.features?.map((feature) => ({
        name: feature.place_name,
        latitude: feature.center[1],
        longitude: feature.center[0],
      }));
      setResults(results);
    };

    performSearch();
  }, [query]);
  const handleLocationSelect = (event) => {
    setSelectedLocation(event.target.value);
    const selectedOption = document.querySelector(`option[value="${event.target.value}"]`);
    if(selectedOption){
      const newLatitude = selectedOption.dataset.latitude;
      const newLongitude = selectedOption.dataset.longitude;
      setLongitude(newLongitude);
      setLatitude(newLatitude);
      setAddEquipmentFormData({
        ...addEquipmentFormData,
        equipment_latitude: newLatitude,
        equipment_longitude: newLongitude,
      });
   }
  };

  
  
  const [value, setValue] = useState({ 
    startDate: new Date(), 
    endDate: new Date()
    }); 
const[addEquipmentFormData, setAddEquipmentFormData] = useState({
    "title":'',
    "owner":'',
    "manufacturer":'',
    "category":'',
    "equipment_latitude":latitude,
    "equipment_longitude":longitude,
    "equipment_location":'',
    "description":'',
    "daily_rental":'',
    "hourly_rental":'',
    "image_1":null,
    "image_2":null,
    "image_3":null,
    "image_4":null,
    "image_5":null,
    "manufacturing_year":'',
    "model":'',
    "condition":'',
    "horsepower":'',
    "width":'',
    "height":'',
    "is_available":false,
    "available_start_time":new Date(value.startDate),
    "available_end_time":new Date(value.endDate),
    "show_phone_number":false,
});

const [error, setError]= useState({
    status:false,
    msg:"",
    type:""
})
const [Companies, setCompanies] = useState([])

const loadCompanies = () => {

    getCompaniesAll()
    .then((data) => setCompanies(data))
  };
  
  useEffect(() => {
  loadCompanies();
  },[]);
  const [Categories, setCategories] = useState([])

  const loadCategories = () => {

      getCategoriesAll()
      .then((data) => setCategories(data))
    };
    
    useEffect(() => {
    loadCategories();
    },[]);
    const access_token= Cookies.get('access-token');
const [Users, setUsers] = useState([])
    const loadUsers = () => {
  
        getUsersAll(access_token)
        .then((data) => setUsers(data))
      };
      useEffect(() => {
        loadUsers();
        },[]);
const inputHandler = (event) => {
  setError({status:false});

 setAddEquipmentFormData({
    ...addEquipmentFormData,
    [event.target.name]:event.target.value
 })
};

const handleImageChange = (event) => {
  setError({status:false});

  const target = event.target;
  const value = target.files[0];
  const name = target.name;
  setAddEquipmentFormData({...addEquipmentFormData , [name]: value });
};

const submitHandler = (event) =>{
  const start_year = addEquipmentFormData?.available_start_time.getFullYear();
  const start_month = String(addEquipmentFormData?.available_start_time.getMonth() + 1).padStart(2, '0');
  const start_day = String(addEquipmentFormData?.available_start_time.getDate()).padStart(2, '0');
  const formattedStartDate = `${start_year}-${start_month}-${start_day}`;

  const end_year = addEquipmentFormData?.available_end_time.getFullYear();
  const end_month = String(addEquipmentFormData?.available_end_time.getMonth() + 1).padStart(2, '0');
  const end_day = String(addEquipmentFormData?.available_end_time.getDate()).padStart(2, '0');
  const formattedEndDate = `${end_year}-${end_month}-${end_day}`;

    event.preventDefault();
    const equipmentData = new FormData();
    equipmentData.append('title', addEquipmentFormData?.title);
    equipmentData.append('manufacturer', addEquipmentFormData?.manufacturer);
    equipmentData.append('owner', addEquipmentFormData?.owner);
    equipmentData.append('category', addEquipmentFormData?.category);
    equipmentData.append('equipment_latitude', addEquipmentFormData?.equipment_latitude);
    equipmentData.append('equipment_longitude', addEquipmentFormData?.equipment_longitude);
    equipmentData.append('equipment_location', addEquipmentFormData?.equipment_location);
    equipmentData.append('description', addEquipmentFormData?.description);
    equipmentData.append('daily_rental', addEquipmentFormData?.daily_rental);
    equipmentData.append('hourly_rental', addEquipmentFormData?.hourly_rental);
    equipmentData.append('available_start_time', formattedStartDate);
    equipmentData.append('available_end_time', formattedEndDate);
    equipmentData.append('model', addEquipmentFormData?.model);
    equipmentData.append('manufacturing_year', addEquipmentFormData?.manufacturing_year);
    equipmentData.append('condition', addEquipmentFormData?.condition);
    equipmentData.append('horsepower', addEquipmentFormData?.horsepower);
    equipmentData.append('width', addEquipmentFormData?.width);
    equipmentData.append('height', addEquipmentFormData?.height);
    equipmentData.append('is_available', addEquipmentFormData?.is_available);
    equipmentData.append('show_phone_number', addEquipmentFormData?.show_phone_number);
    (addEquipmentFormData?.image_1) &&( equipmentData.append('image_1', addEquipmentFormData?.image_1) );
    (addEquipmentFormData?.image_2) &&( equipmentData.append('image_2', addEquipmentFormData?.image_2) );
    (addEquipmentFormData?.image_3) &&( equipmentData.append('image_3', addEquipmentFormData?.image_3) );
    (addEquipmentFormData?.image_4) &&( equipmentData.append('image_4', addEquipmentFormData?.image_4) );
    (addEquipmentFormData?.image_5) &&( equipmentData.append('image_5', addEquipmentFormData?.image_5) );


    addEquipment(equipmentData, access_token)
    .then(data =>{
        if(data?.id){
            setError({status:true, msg:"Equipment has been Created", type:"green"})
   
        }
    })
    .catch((e)=> console.log(e));
}   
const handleSelect = (newValue) => {
  setValue(newValue); 
  setAddEquipmentFormData(prevState => ({
    ...prevState,
    available_start_time: new Date(newValue.startDate),
    available_end_time: new Date(newValue.endDate),
  }));
  } 
  

  
  return (
<div className="flex bg-gray-100 dark:bg-gray-900">
<Sidebar/>
<div className="flex-grow bg-gray-100 dark:bg-gray-900">

<form id="productform" onSubmit={submitHandler}>
          <div className="shadow sm:rounded-md sm:overflow-hidden ">
            <div className="bg-white py-6 px-4 space-y-6 sm:p-6 dark:bg-gray-800">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">Add New Equipment</h3>
              </div>

              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Title*
                  </label>
                  <input
                  required={true}
                    type="text"
                    name="title"
                    placeholder="Title*"
                    id="title"
                    onChange={inputHandler} 
                    value={addEquipmentFormData?.title}
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="email-address" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Owner
                  </label>
                  <select
                  required={true}
                  id="owner"
                    onChange={inputHandler}
                    name="owner"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:focus:outline-none dark:focus:ring-indigo-500 dark:focus:border-indigo-500 sm:text-sm"
                  >
                    <option selected>Choose a Owner*</option>
                    { Users?.map((User) =>   <option key={User.uuid} value={User.id}>{User.email}</option>) }                  </select>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="manufacturer" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Manufacturer
                  </label>
                  <select
                  required={true}
                  id="manufacturer"
                    onChange={inputHandler}
                    name="manufacturer"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:focus:outline-none dark:focus:ring-indigo-500 dark:focus:border-indigo-500 sm:text-sm"
                  >
                    <option selected>Choose a Manufacturer</option>
                  { Companies?.map((Companies) =>   <option key={Companies.id} value={Companies.id}>{Companies.name}</option>) }
                  </select>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="category" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Category
                  </label>
                  <select
                  required={true}
                  id="category"
                    onChange={inputHandler}
                    name="category"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:focus:outline-none dark:focus:ring-indigo-500 dark:focus:border-indigo-500 sm:text-sm"
                  >
                    <option selected>Choose a Category</option>
                    { Categories?.map((Categories) =>   <option key={Categories.id} value={Categories.id}>{Categories.name}</option>) }
                  </select>
                </div>

                <div className="col-span-6">
                  <label htmlFor="description" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Description
                  </label>
                  <textarea
                    type="text"
                    placeholder="Description"
                    required={true}
                    onChange={inputHandler}
                    value={addEquipmentFormData?.description}
                    name="description"
                    id="description"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="number" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Daily Rental
                  </label>
                  <input
                  value={addEquipmentFormData?.daily_rental}
                  type="number"
                  onChange={inputHandler}
                  placeholder="Daily Rental*"
                  required={true}
                  name="daily_rental"
                    id="daily_rental"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="number" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Hourly Rental
                  </label>
                  <input
                    type="number"
                    placeholder="Hourly Rental*"
                    required={true} 
                    value={addEquipmentFormData?.hourly_rental}
                    name="hourly_rental"
                    id="hourly_rental"
                    onChange={inputHandler}
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                
                <div className="col-span-3">
                  <label className="block text-sm font-medium text-gray-700">Image 1</label>
                  <div className="mt-1 flex items-center">
                    <input name="image_1"
                        id="default_size"
                      className="bg-white border border-gray-300 dark:border-gray-900 dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onChange={handleImageChange}                   
                        type="file" accept="image/*" />
                         {(addEquipmentFormData.image_1)?
                  <span className="ml-5 inline-block bg-gray-100 rounded-lg overflow-hidden h-20 w-20">
                        <img
                        src={URL.createObjectURL(addEquipmentFormData.image_1)}
                        alt="Preview"
                        className='h-full w-full'
                        />
                        </span>
                        :''
                        }
                  </div>
                </div>

                <div className="col-span-3">
                  <label className="block text-sm font-medium text-gray-700">Image 2</label>
                  <div className="mt-1 flex items-center">
                    <input name="image_2"
                        id="default_size"
                      className=" bg-white border border-gray-300 dark:border-gray-900 dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onChange={handleImageChange}                   
                        type="file" accept="image/*" />
                            {(addEquipmentFormData.image_2)?
                  <span className="ml-5 inline-block bg-gray-100 rounded-lg overflow-hidden h-20 w-20">
                        <img
                        src={URL.createObjectURL(addEquipmentFormData.image_2)}
                        alt="Preview"
                        className='h-full w-full'
                        />
                        </span>
                        :''
                        }
                  </div>
                </div>

                <div className="col-span-3">
                  <label className="block text-sm font-medium text-gray-700">Image 3</label>
                  <div className="mt-1 flex items-center">
                 
                    <input name="image_3"
                        id="default_size"
                      className=" bg-white border border-gray-300 dark:border-gray-900 dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onChange={handleImageChange}                   
                        type="file" accept="image/*" />
                            {(addEquipmentFormData.image_3)?
                  <span className="ml-5 inline-block bg-gray-100 rounded-lg overflow-hidden h-20 w-20">
                        <img
                        src={URL.createObjectURL(addEquipmentFormData.image_3)}
                        alt="Preview"
                        className='h-full w-full'
                        />
                        </span>
                        :''
                        }
                  </div>
                </div>

                <div className="col-span-3">
                  <label className="block text-sm font-medium text-gray-700">Image 4</label>
                  <div className="mt-1 flex items-center">
                    
                    <input name="image_4"
                        id="default_size"
                      className=" bg-white border border-gray-300 dark:border-gray-900 dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onChange={handleImageChange}                   
                        type="file" accept="image/*" />
                            {(addEquipmentFormData.image_4)?
                  <span className="ml-5 inline-block bg-gray-100 rounded-lg overflow-hidden h-20 w-20">
                        <img
                        src={URL.createObjectURL(addEquipmentFormData.image_4)}
                        alt="Preview"
                        className='h-full w-full'
                        />
                        </span>
                        :''
                        }
                  </div>
                </div>

                <div className="col-span-6">
                  <label className="block text-sm font-medium text-gray-700">Image 5</label>
                  <div className="mt-1 flex items-center">
                
                    <input name="image_5"
                        id="default_size"
                      className=" bg-white border border-gray-300 dark:border-gray-900 dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onChange={handleImageChange}                   
                        type="file" accept="image/*" />
                            {(addEquipmentFormData.image_5)?
                  <span className="ml-5 inline-block bg-gray-100 rounded-lg overflow-hidden h-20 w-20">
                        <img
                        src={URL.createObjectURL(addEquipmentFormData.image_5)}
                        alt="Preview"
                        className='h-full w-full'
                        />
                        </span>
                        :''
                        }
                  </div>
                </div>
                
                <div className="col-span-6 sm:col-span-6">
                  <label htmlFor="city" className="block text-sm font-medium text-gray-700 dark:text-white">
                    Select Available dates
                  </label>
                  <Datepicker 
                    value={value}
                    separator={"to"}
                    showShortcuts={true}
                    showFooter={true}
                    useRange={false}
                    minDate={yesterday}
                    primaryColor={"green"} 
                    displayFormat={"YYYY-MM-DD"} 
                    onChange={handleSelect}
/>
                </div>
                
                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label htmlFor="text" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Equipment Location
                  </label>
                  <input
      type="text"
      value={query}
      onChange={(e)=>{handleSearchInput(e);
      inputHandler(e);}}
      className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:focus:outline-none dark:focus:ring-indigo-500 dark:focus:border-indigo-500 sm:text-sm"
      list="locations"
      name="equipment_location"
      required={true}
      onSelect={handleLocationSelect}
    />
    <datalist id="locations">
      {results?.map((result) => (
        <option
          key={`${result.latitude},${result.longitude}`}
          value={result.name}
          data-longitude={result.longitude}
          data-latitude={result.latitude}
        >
          {result.name}
        </option>
      ))}
    </datalist>
    {selectedLocation && <p>Selected location: {selectedLocation}</p>}
                </div>
        
                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label htmlFor="number" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Manufacturing Year
                  </label>
                  <input
                  required={true}
                  onChange={inputHandler}
                    type="number"
                    name="manufacturing_year" 
                    placeholder="Manufacturing Year*"
             value={addEquipmentFormData?.manufacturing_year}
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label htmlFor="text" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Model
                  </label>
                  <input
                  required={true}
                  onChange={inputHandler}
                    type="text"
                    placeholder="Model*"
                    name="model" 
             value={addEquipmentFormData?.model}
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
    
                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label htmlFor="condition" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Condition
                  </label>
                  <select
                  required={true}
                  id="condition"
                    onChange={inputHandler}
                    name="condition"
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:focus:outline-none dark:focus:ring-indigo-500 dark:focus:border-indigo-500 sm:text-sm"
                  >
                      <option selected>Choose Condition</option>
                      <option value="New">New</option>
                      <option value="Used">Used</option>
                  </select>       
                </div>
                         
                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label htmlFor="number" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Horsepower
                  </label>
                  <input
                  required={true}
                  onChange={inputHandler}
                    type="number"
                    placeholder="Horsepower*"
                        name="horsepower" 
             value={addEquipmentFormData?.horsepower}
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label htmlFor="number" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Width
                  </label>
                  <input
                  required={true}
                  onChange={inputHandler}
                    type="number"
                    placeholder="Width*"
                    name="width" 
             value={addEquipmentFormData?.width}
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                
                <div className="col-span-6">
                  <label htmlFor="number" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Height
                  </label>
                  <input
                  required={true}
                  onChange={inputHandler}
                    type="number"
                    placeholder="Height*"
                        name="height" 
             value={addEquipmentFormData?.height}
                    className="mt-1 block w-full border border-gray-300 dark:border-gray-900 bg-white dark:bg-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>


                <div className="col-span-6 sm:col-span-3">
                  <div className="flex items-start">
                  <input
                  name="is_available"
                        type="checkbox"
                        onChange={(e) => {
                          setAddEquipmentFormData({ ...addEquipmentFormData, is_available: e.target.checked });
                        }}
                         checked={addEquipmentFormData?.is_available}
                    className="h-4 w-4 flex border-gray-300 items-center dark:border-gray-900 rounded py-2 px-3 text-indigo-600 focus:ring-indigo-500"
                  />  
                   <div className="ml-3 text-sm">
                        <label htmlFor="is_available" className="font-medium text-gray-700 dark:text-white">
                        Is Available
                        </label>
                        <p className="text-gray-500 dark:text-gray-400">Make it visible and available for booking.</p>
                      </div>
                </div>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <div className="flex items-start">
                  <input
                  name="show_phone_number" 
                        type="checkbox"
                        onChange={(e) => {
                          setAddEquipmentFormData({ ...addEquipmentFormData, show_phone_number: e.target.checked });
                        }}
                         checked={addEquipmentFormData?.show_phone_number}
                    className="h-4 w-4 border-gray-300 flex items-center dark:border-gray-900 rounded py-2 px-3 text-indigo-600 focus:ring-indigo-500"
                  />  
                   <div className="ml-3 text-sm">
                        <label htmlFor="show_phone_number" className="font-medium text-gray-700 dark:text-white">
                        Show Phone Number
                        </label>
                        <p className="text-gray-500 dark:text-gray-400">If checked then your phone number will be visible in booking details to user</p>
                      </div>
                </div>
                </div>

              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 dark:bg-gray-700">
              <button
                type="submit"
                className="w-full bg-green-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                Add Equipment
              </button>
            </div>
          </div>
          </form>
          {error?.status? <div className={`bg-${error?.type}-200 mt-4 flex p-4 mb-4 text-sm text-${error?.type}-800 border border-${error?.type}-300 rounded-lg bg-${error?.type}-50 dark:bg-gray-800 dark:text-${error?.type}-400 dark:border-${error?.type}-800`} role="alert">
  <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
  <span className="sr-only">Alert</span>
  <div>
  {error?.msg}
      </div>
      </div>
 :''}
</div>
   </div>
  )
  }
export default AddEquipmentAdmin
